import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Box, IconButton, Typography, useTheme, Theme, SxProps } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import _ from 'lodash';
import { Configuration } from 'configuration';

import {
  ADMIN_PERSONALINFO_SELECT_TYPE,
  PAYMENT_MEDIUM,
  UI_STYLES,
  PAYMENT_STATUS_OPTIONS,
  PAYMENT,
  ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST,
} from '../../constants/constants';
import en from '../../translations/en';
import { CheckMarkIcon } from '../../assets/images';

import { IAdminFlownSuspension, IAssoSubsidAdminEmployeeDetailRoot } from '../../interfaces';

import {
  validFlownSuspensionParams,
  isEmptyField,
  constructAdminFlownSuspensionParams,
  isValid,
  transformInitAssoSubsidAdminEmployeeContext,
  getInitEmployeeDependents,
  getAdminFirstLevelToggleList,
  getAssoSubsidEligibleCompanyList,
  isEmptyOrUndefined,
  checkValidDateRange,
} from '../../helpers';

import { AssoSubsidAdminEmployeeAction, AssoSubsidAdminEmployeeState, ConcessionInProfileAction } from '../../context';

import { useAppSelector } from '../../app/hooks';

import { selectUser } from '../../slice/userSlice';
import { selectConfiguration } from '../../slice/configurationSlice';

import {
  getAssoSubsidEmployee,
  getAssoSubsidNotes,
  updateAssoSubsidEmployee,
  updateAssoSubsidDependentsInfo,
} from '../../services/admin/employee';
import { showErrorAlert } from '../../services/api';

import {
  AdminInformationBar,
  AdminToggleButtonGroup,
  AdminEmployeeList,
} from '../../containers/AdminMaintainEmployeeDetail';
import { SnackbarComponent } from '../../containers';
import { ScrollableView, AutoCompleteBox, CancelDialog, ReduxFormSelectInput } from '../../components';

const BoldButtonTypography = ({ customStyles, textLabel }: { customStyles?: SxProps<Theme>; textLabel: string }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="body_2_bold"
      sx={{
        width: '120px',
        height: '50px',
        lineHeight: '50px',
        background: theme.color.utility.link.option_3,
        borderRadius: 1,
        color: theme.palette.bgColor.main,
        textAlign: 'center',
        ...customStyles,
      }}
    >
      {textLabel}
    </Typography>
  );
};

const AdminAssoSubsidEmployee = () => {
  const screenHeight = use100vh();
  const theme = useTheme();
  const scrollViewRef = useRef<HTMLDivElement>(null);

  const { adminRoles, role } = useAppSelector(selectUser) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const adminRole = adminRoles.find((item) => item.id === role.id);

  const companyOptions = getAssoSubsidEligibleCompanyList(adminRole?.company || [], configurations.companies);

  const [adminMaintainId, setAdminMaintainId] = useState<string>(
    ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.personalInformation,
  );
  const [ernValue, setErnValue] = useState<string>('');

  const [searchResult, setSearchResult] = useState<any[] | null>(null);
  const [isOnSelect, setIsOnSelect] = useState<boolean>(false);
  const [previewProfile, setPreviewProfile] = useState(null);
  const [searchTips, setSearchTips] = useState<string>('');

  const [isShowEditButton, setIsShowEditButton] = useState<boolean>(true);
  const [isShowCancelPopup, setIsShowCancelPopup] = useState<boolean>(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);

  const {
    employeeDetail,
    isEditingAdminProfile,
    editEmployeeDetail,
    selectedCompanyCode,
    originalHousekeepingListData,
    editDependentsDetail,
    originalDependentsHousekeepingListData,
    isEditingDependentProfile,
    editDependentId,
    isPassportInfoMissing,
  } = useContext(AssoSubsidAdminEmployeeState) || {};

  const { employeeId, revision } = employeeDetail?.profileDetail || {};

  const {
    setEmployeeNotes,
    setEmployeeDependent,
    setIsEditingAdminProfile,
    setEditEmployeeDetail,
    setEditEmployeeError,
    clearEmployeeDetail,
    updatePersonalInfoTabData,
    updateNominationHistoryTabData,
    updateQuotaHistoryTabData,
    setSelectedCompanyCode,
    setHousekeepingList,
    setOriginalHousekeepingListData,
    setDependentsHousekeepingList,
    setOriginalDependentsHousekeepingListData,
    setEditDependentsDetail,
    setIsEditingDependentProfile,
    setConcessions,
    setConcessionName,
    setFocusConcession,
    setEditDependentId,
    setIsPassportInfoMissing,
    setIsExpiryDateEdited,
    setIsIssuedCountryEdited,
    setIsNationalityInPassportEdited,
    setFocusNote,
  } = useContext(AssoSubsidAdminEmployeeAction) || {};

  const {
    setCurrentContextLocation,
    setEmployeeProfileDetailForConcession,
    setConcessionYearDropdownData,
    setIsTriggerRefreshConcessionYearDropdownData,
  } = useContext(ConcessionInProfileAction) || {};

  const {
    assoSubsidMaintainEmployeeDetails: assoSubsidMaintainEmployeeDetailsLbl,
    assoSubsidEmployeeProfile: assoSubsidEmployeeProfileLbl,
  } = en.admin.feature;
  const { editProfilePopup } = en.admin.feature.personalInformation.profileDetails;
  const isEmptyEditEmployeeDetail = editEmployeeDetail === null;
  const isEmptyEditDependentsDetail = editDependentsDetail === null;

  const firstToggleBarTabList = getAdminFirstLevelToggleList();

  const paperStyles = {
    width: '240px',
    position: 'absolute',
    left: '-16px',
    mt: 0.5,
  };

  const textFieldStyle = {
    height: '50px',
    borderRadius: 0.5,
    background: 'white',
    border: `1px solid ${theme.color.secondary.slate.option_3}`,
  };
  const getSelectedBarTabComponent = (id: string) => {
    const selectedTab = firstToggleBarTabList.find((item) => item.id === id);
    return selectedTab?.component;
  };

  const handleFetchEmployee = async () => {
    const userId = ernValue;
    const companyCode = selectedCompanyCode;

    if (userId) {
      if (userId.length < 5) {
        setSearchResult(null);
        setSearchTips(assoSubsidMaintainEmployeeDetailsLbl.incompleteErn);
        return;
      }
    }

    if (userId && companyCode) {
      const employeeList = await getAssoSubsidEmployee({ userId, hiringCompany: companyCode });
      if (employeeList) {
        clearEmployeeDetail();
        setAdminMaintainId(ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.personalInformation);
        setSearchResult(employeeList);
        setEditDependentsDetail(null);
        setEditDependentId('');
        resetEditToViewMode();

        if (employeeList.length === 1) {
          setHousekeepingList(employeeList[0].housekeepingList);
          setOriginalHousekeepingListData(employeeList[0].housekeepingList);
          setDependentsHousekeepingList(employeeList[0].dependentHousekeepingList);
          setOriginalDependentsHousekeepingListData(employeeList[0].dependentHousekeepingList);
        }
      }
    }
  };

  const fetchNotes = async () => {
    // clear previous list first
    setEmployeeNotes([]);

    const notesData = await getAssoSubsidNotes({
      userId: employeeId as string,
      profileRevision: revision as number,
    });
    setEmployeeNotes(notesData || []);
  };

  const resetEditToViewMode = () => {
    setIsEditingAdminProfile(false);
    setEditEmployeeDetail(null);
    setEditEmployeeError(null);
    setEditDependentsDetail(null);
    setIsEditingDependentProfile(false);
    setIsPassportInfoMissing(false);
    setIsExpiryDateEdited(false);
    setIsIssuedCountryEdited(false);
    setIsNationalityInPassportEdited(false);
    setFocusNote(null);
  };

  const updateEmployeeAction = async () => {
    if (adminMaintainId === ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.dependents) {
      const requestParams = {
        userId: employeeId,
        profileRevision: revision,
        dependentId: editDependentId,
        nominationFrom: editDependentsDetail?.nominationFrom || '',
        nominationTo: editDependentsDetail?.nominationTo || '',
        nominationType: editDependentsDetail?.nominationType || '',
        isDeactivateProfile: editDependentsDetail?.isDeactivateProfileConfirmButtonClicked,
        removePendingDeactivation: editDependentsDetail?.removePendingDeactivation,
        dependentInfo: {
          firstName: editDependentsDetail?.firstName,
          lastName: editDependentsDetail?.surName,
          middleName: editDependentsDetail?.middleName,
          gender: editDependentsDetail?.gender,
          title: editDependentsDetail?.title,
          relationshipCode: editDependentsDetail?.relationship,
        },
        passport: {
          passportNumber: editDependentsDetail?.passportNumber,
          passportExpirationDate: editDependentsDetail?.passportExpiryDate,
          passportNationality: editDependentsDetail?.nationalityInPassport?.code,
          passportCountry: editDependentsDetail?.issuedCountry?.code,
          passportFirstName: editDependentsDetail?.passportFirstName,
          passportLastName: editDependentsDetail?.passportSurname,
        },
      };

      if (isPassportInfoMissing) {
        // if some mandatory fields missing, occur error dialog
        showErrorAlert({
          title: editProfilePopup.missingInformation,
          message: editProfilePopup.fillFields,
          noErrorAlert: true,
          showErrorIcon: false,
        });
        return;
      }

      const result = await updateAssoSubsidDependentsInfo(requestParams);
      // use backend latest data to reset context employee data
      if (result) {
        setAdminMaintainId(ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.dependents);
        setSearchResult([result]);
        const { dependentHousekeepingList } = result;
        setDependentsHousekeepingList(dependentHousekeepingList);
        setOriginalHousekeepingListData(dependentHousekeepingList);
        resetEditToViewMode();
        setIsOpenSnackbar(true);

        setTimeout(() => {
          setIsOpenSnackbar(false);
        }, 3000);
      }
      return;
    }

    const {
      profile,
      benefit,
      employment,
      passport,
      payment,
      flownSuspension,
      editFlownSuspensionList,
      removePendingDeactivation,
      isDeactivateProfileConfirmButtonClicked,
    } = editEmployeeDetail || {};
    const { paymentConcept, paymentMedium, paymentStatus } = payment || {};
    const { yearOfServiceByAdmin } = benefit || {};
    const { employeeCategory, resignationCode, terminationDate } = employment || {};
    const { nationality, issuedCountry, countryOfResidential } = passport || {};
    const { flownSuspensionStartDate, flownSuspensionEndDate } = flownSuspension || {};

    // flown suspension request params checking
    const { errorObject: flownSuspensionErrorMessage } =
      validFlownSuspensionParams(
        flownSuspensionStartDate,
        flownSuspensionEndDate,
        ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspension,
      ) || {};

    // condition 1: edit terminationDate has value
    // condition 2: edit terminationDate is undefined + has view mode's terminationDate
    // + resignationCode is "--" (pass "")
    const checkResignationCodeIsEmpty =
      (terminationDate && isEmptyField(resignationCode)) ||
      (terminationDate === undefined &&
        employeeDetail?.employmentDetail?.terminationDate &&
        isEmptyField(resignationCode));

    // condition 1: edit resignationCode has value and is not "--"
    // condition 2: edit resignationCode is undefined + has terminationActionReason
    // + terminationDate is ""
    const checkServiceEndDateIsEmpty =
      (terminationDate === '' && !isEmptyField(resignationCode)) ||
      (resignationCode === undefined &&
        employeeDetail?.employmentDetail?.terminationActionReason &&
        terminationDate === '');

    // check employment service start date, end date if is valid date range.
    const checkServiceDateIsValidDateRange =
      terminationDate &&
      employeeDetail?.employmentDetail?.lastHireDate &&
      !checkValidDateRange(employeeDetail?.employmentDetail?.lastHireDate, terminationDate);

    // check all mandatory fields if missing + invalid date range
    // profile: firstName,lastName,dateOfBirth
    // employment: directAppraiser, resignationCode, serviceEndDate
    const checkMandatoryFieldIsMissing =
      profile?.firstName === '' ||
      profile?.lastName === '' ||
      profile?.dateOfBirth === '' ||
      checkResignationCodeIsEmpty ||
      checkServiceEndDateIsEmpty ||
      checkServiceDateIsValidDateRange;

    if (checkMandatoryFieldIsMissing) {
      // if some mandatory fields missing, occur error dialog
      showErrorAlert({
        title: editProfilePopup.missingInformation,
        message: editProfilePopup.fillFields,
        noErrorAlert: true,
        showErrorIcon: false,
      });
      return;
    }

    // construct error Object
    let adminEditErrorObject = {};

    if (flownSuspensionErrorMessage) {
      adminEditErrorObject = {
        ...adminEditErrorObject,
        flownSuspension: flownSuspensionErrorMessage,
      };
    }

    if (flownSuspensionErrorMessage) {
      // has error
      showErrorAlert({
        title: editProfilePopup.missingInformation,
        message: editProfilePopup.fillFields,
        noErrorAlert: true,
        showErrorIcon: false,
      });
      setEditEmployeeError(adminEditErrorObject);

      return;
    } else {
      setEditEmployeeError(null);
    }

    // add flown suspension params
    const addFlownSuspensionParams =
      constructAdminFlownSuspensionParams(
        flownSuspensionStartDate,
        flownSuspensionEndDate,
        ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspension,
      ).suspensionObject || {};

    // edit flown suspension params
    const editFlownSuspensionParams = editFlownSuspensionList
      ?.filter((item: IAdminFlownSuspension) => item.setFlownSuspensionStartDate && !item.isDeleted)
      .map((item: IAdminFlownSuspension) => {
        return {
          flownSuspensionId: item.flownSuspensionId,
          flownSuspensionStartDate: item.flownSuspensionStartDate,
          flownSuspensionEndDate: item.flownSuspensionEndDate,
          setFlownSuspensionStartDate: item.setFlownSuspensionStartDate,
          setFlownSuspensionEndDate: item.setFlownSuspensionEndDate,
        };
      });

    // delete flown suspension params
    const deleteFlownSuspensionParams = editFlownSuspensionList
      ?.filter((item: IAdminFlownSuspension) => item.isDeleted)
      .map((item: IAdminFlownSuspension) => item.flownSuspensionId);

    const requestParams = {
      userId: employeeId,
      profileRevision: revision,
      ...(profile && { profile }),
      ...(employment && {
        employment: {
          ...employment,
          ...(employeeCategory && {
            employeeCategory: isEmptyField(employeeCategory) ? '' : employeeCategory,
          }),
          ...(resignationCode && {
            resignationCode: isEmptyField(resignationCode) ? '' : resignationCode,
          }),
        },
      }),
      ...(passport && {
        passport: {
          ...passport,
          ...(!isEmptyOrUndefined(nationality) && {
            nationality: nationality?.code,
          }),
          ...(!isEmptyOrUndefined(issuedCountry) && {
            issuedCountry: issuedCountry?.code,
          }),
          ...(!isEmptyOrUndefined(countryOfResidential) && {
            countryOfResidential: countryOfResidential?.code,
          }),
        },
      }),
      ...(paymentConcept && { paymentConcept }),
      ...(paymentMedium && {
        paymentMedium: isEmptyField(paymentMedium) ? null : paymentMedium,
      }), // payment medium is "--", pass null
      ...(paymentMedium == PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT && {
        directDebitStatus: paymentStatus
          ? // use edit data first
            PAYMENT_STATUS_OPTIONS.find((item) => item.label === paymentStatus)?.value
          : // use BE data if not edit
            employeeDetail?.profileDetail?.directDebitStatus ||
            // default "null"
            PAYMENT.paymentStatusValue.empty,
      }), // pass status only if payment medium is direct debit
      ...(!isValid(addFlownSuspensionParams) && {
        addFlownSuspensionInfo: addFlownSuspensionParams,
      }),
      ...(!_.isEmpty(editFlownSuspensionParams) && {
        editFlownSuspensionList: editFlownSuspensionParams,
      }),
      ...(!_.isEmpty(deleteFlownSuspensionParams) && {
        deleteFlownSuspensionIds: deleteFlownSuspensionParams,
      }),
      yearOfServiceByAdmin,
      isDeactivateProfile: isDeactivateProfileConfirmButtonClicked
        ? isDeactivateProfileConfirmButtonClicked
        : undefined,
      removePendingDeactivation: removePendingDeactivation ? removePendingDeactivation : undefined,
      type: isDeactivateProfileConfirmButtonClicked ? 'deactivateEmployee' : undefined,
    };

    // Below result including whole `Profile` data and `flownSuspensionDetails` data
    const result = await updateAssoSubsidEmployee(requestParams);

    // use backend latest data to reset context employee data
    if (result) {
      // reset latest housekeepingList data
      const { housekeepingList } = result;
      setHousekeepingList(housekeepingList);
      setOriginalHousekeepingListData(housekeepingList);

      // result is an object type, need to convert to array type
      setSearchResult([result]);

      resetEditToViewMode();
      setIsOpenSnackbar(true);

      setTimeout(() => {
        setIsOpenSnackbar(false);
      }, 3000);
    }
  };

  const initEmployeeDetail = (transformedEmployee: IAssoSubsidAdminEmployeeDetailRoot) => {
    const { dependents, nominationHistory, quotaHistory, ...restOfPersonalInformation } = transformedEmployee || {};
    const resetProfileDetail = restOfPersonalInformation?.profileDetail;

    // reset location context for `ConcessionInProfileContext` used
    setCurrentContextLocation('');

    // Clean up Year dropdown data
    setConcessionYearDropdownData({
      dropdownOptions: [],
      selectedOptionItem: null,
    });

    // reset flag
    setIsTriggerRefreshConcessionYearDropdownData(false);

    if (resetProfileDetail?.isAccessible) {
      // initialize few Employee profile & personalInfo data for Concession tab Context used
      setEmployeeProfileDetailForConcession({
        employeeId: resetProfileDetail?.employeeId,
        revision: resetProfileDetail?.revision,
        hiringCompany: resetProfileDetail?.hiringCompany,
        firstName: resetProfileDetail?.firstName,
        lastName: resetProfileDetail?.lastName,
        middleName: resetProfileDetail?.middleName,
        age: restOfPersonalInformation?.benefitDetail?.age,
      });

      // initialize Personal Information Tab whole data
      updatePersonalInfoTabData(restOfPersonalInformation);

      // initialize Dependents Tab whole data
      const initializedDependents = getInitEmployeeDependents(dependents);
      setEmployeeDependent(initializedDependents);

      // initialize Nomination history Tab whole data
      updateNominationHistoryTabData(nominationHistory);

      // initialize Quota history Tab whole data
      updateQuotaHistoryTabData(quotaHistory);
    }
  };

  const isEnableSearchAction = useMemo(() => {
    return ernValue.length >= 5 && selectedCompanyCode;
  }, [ernValue, selectedCompanyCode]);

  useEffect(() => {
    clearEmployeeDetail();
    return () => {
      resetEditToViewMode();
      setSelectedCompanyCode('');
    };
  }, []);

  useEffect(() => {
    if (companyOptions?.length === 1) {
      setSelectedCompanyCode(companyOptions[0].code);
    }
  }, [companyOptions]);

  const beforeUnloadHandler = (event: any) => {
    // event.returnValue cannot empty string
    const message = 'confirm exit';
    event.returnValue = message;
    return message;
  };

  useEffect(() => {
    const setupBeforeUnloadListener = () => {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    };

    const removeBeforeUnloadListener = () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };

    if (isEditingAdminProfile) {
      // only when editing will trigger beforeunload
      setupBeforeUnloadListener();
    } else {
      removeBeforeUnloadListener();
    }

    return () => {
      removeBeforeUnloadListener();
    };
  }, [isEditingAdminProfile]);
  // TODO may affect the [ETP-5383, ETP-5448] edit button control
  // Default to show edit button when user is in Personal Information Tab
  useEffect(() => {
    setIsShowEditButton(
      adminMaintainId === ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.personalInformation || editDependentId !== '',
    );
  }, [adminMaintainId, editDependentId]);

  useEffect(() => {
    if (searchResult) {
      if (searchResult?.length > 0) {
        setSearchTips('');
        const tempPreviewProfile = searchResult?.find((item) => item.profile.isAccessible);
        setPreviewProfile(tempPreviewProfile);
        if (searchResult?.length === 1) {
          // Directly display the profile detail if user searches for an ERN & Company there is single revision of the profile returned
          const employee = _.head(searchResult);

          // Since the `employee` is pure data source from API response, need to transform it to context structure data
          const transformedData = transformInitAssoSubsidAdminEmployeeContext(employee);
          initEmployeeDetail(transformedData);
        }
      } else {
        setSearchTips(`${en.booking.travelType.noErnResult} ${en.booking.travelType.tryAgain}`);
      }
    }
  }, [searchResult]);

  useEffect(() => {
    // after inactiveNomination api call, reset context data
    if ((employeeDetail?.dependents || [])?.length > 0) {
      initEmployeeDetail(employeeDetail);
    }
  }, [employeeDetail?.dependents]);

  return (
    <Box
      className="admin_asso_subsid_employee"
      component={ScrollableView}
      sx={{
        pt: 2,
        pb: 10,
        background: theme.palette.primary.light,
        height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${UI_STYLES.indicatorBarHeight} )`,
      }}
      ref={scrollViewRef}
    >
      {/* Page title & Search form */}
      <Box className="admin_search_header" sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 2 }}>
        <Typography variant="large_title_1_bold" color={theme.color.secondary.dark_grey.option_1}>
          {assoSubsidEmployeeProfileLbl}
        </Typography>
        <Box
          className="admin_search_box"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 5,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box className="admin_search_input_box" sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box className="admin_search_company_box" sx={{ mr: 2, width: '480px', height: '50px' }}>
              <ReduxFormSelectInput
                input={{
                  value: selectedCompanyCode,
                  onChange: (input: Configuration.CodeLabel) => {
                    setSelectedCompanyCode(input.code);
                  },
                }}
                title={assoSubsidMaintainEmployeeDetailsLbl.companyLbl}
                options={companyOptions}
                autocomplete={{
                  customOpen: true,
                }}
                customInputStyle={{
                  mt: 0,
                }}
              />
            </Box>

            <Box className="admin_search_ern_box" sx={{ mr: 2, width: '150px' }}>
              <AutoCompleteBox
                label={assoSubsidMaintainEmployeeDetailsLbl.ern}
                options={[]}
                value={ernValue}
                inputValue={ernValue}
                noOptionsText={en.common.noMatchResult}
                onChange={(newValue: { code: string } | null): void => {
                  const upperValue = newValue?.code ? newValue.code.toUpperCase() : '';
                  setErnValue(upperValue);
                }}
                onInputChange={(newInputValue) => {
                  setErnValue(newInputValue.toUpperCase());
                }}
                onKeyDown={() => {
                  if (!isOnSelect) {
                    ernValue && handleFetchEmployee();
                  }
                }}
                onHighlightChange={(event) => {
                  event && setIsOnSelect(true);
                }}
                onClose={() => {
                  setIsOnSelect(false);
                }}
                dropDownListPop={() => null}
                renderOption={undefined}
                filterOptions={(array) =>
                  array.filter((item: { code: string; label: string }) => {
                    return item.label?.includes(ernValue);
                  })
                }
                customStyles={{
                  pl: 0,
                  '.MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: theme.color.secondary.grey.option_3,
                    },
                  },
                }}
                paperStyles={paperStyles}
                textFieldStyle={textFieldStyle}
              />
            </Box>

            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                if (!isEnableSearchAction) return;
                handleFetchEmployee();
              }}
            >
              <BoldButtonTypography
                textLabel={en.common.search}
                customStyles={{
                  cursor: isEnableSearchAction ? 'pointer' : 'default',
                  background: isEnableSearchAction
                    ? theme.palette.borderColor.light
                    : theme.color.utility.unselectable.option_3,
                }}
              />
            </IconButton>
          </Box>

          {employeeDetail?.profileDetail && isShowEditButton ? (
            isEditingAdminProfile || isEditingDependentProfile ? (
              <Box>
                <IconButton
                  sx={{ p: 0, mr: 2 }}
                  onClick={() => {
                    if (editEmployeeDetail || editDependentsDetail) {
                      setIsShowCancelPopup(true);
                    } else {
                      setIsShowCancelPopup(false);
                      resetEditToViewMode();
                    }
                  }}
                >
                  <BoldButtonTypography
                    customStyles={{
                      border: `2px solid ${theme.color.utility.link.option_3}`,
                      background: theme.palette.bgColor.main,
                      color: theme.color.utility.link.option_3,
                    }}
                    textLabel={en.common.cancel}
                  />
                </IconButton>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => {
                    updateEmployeeAction();
                  }}
                  disabled={isEmptyEditEmployeeDetail && isEmptyEditDependentsDetail}
                >
                  <BoldButtonTypography
                    customStyles={{
                      background:
                        isEmptyEditEmployeeDetail && isEmptyEditDependentsDetail
                          ? theme.color.utility.unselectable.option_7
                          : theme.color.utility.link.option_3,
                      color:
                        isEmptyEditEmployeeDetail && isEmptyEditDependentsDetail
                          ? theme.palette.text.disabled
                          : theme.palette.bgColor.main,
                    }}
                    textLabel={en.common.save}
                  />
                </IconButton>
              </Box>
            ) : (
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  adminMaintainId === ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.personalInformation
                    ? setIsEditingAdminProfile(true)
                    : setIsEditingDependentProfile(true);
                }}
              >
                <BoldButtonTypography
                  customStyles={{
                    background: theme.palette.bgColor.main,
                    border: `2px solid ${theme.color.utility.link.option_3}`,
                    color: theme.color.utility.link.option_3,
                  }}
                  textLabel={en.common.edit}
                />
              </IconButton>
            )
          ) : null}
        </Box>
      </Box>

      {/* Page content as below */}
      {employeeDetail?.profileDetail ? (
        <>
          <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
            <AdminInformationBar />
          </Box>
          <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
            <AdminToggleButtonGroup
              list={firstToggleBarTabList}
              onClick={(e) => {
                setAdminMaintainId(e as string);
                // TODO: extra the logic into select function(no useState)
                if (e === ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.note) fetchNotes();

                const isAccessIntoConcessionPage = e === ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.concession;

                // [ETP-5619] just mark indicator when access to the Concession page
                setCurrentContextLocation(
                  isAccessIntoConcessionPage ? ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.concession : '',
                );

                if (isAccessIntoConcessionPage) {
                  // Clean up the selected Concession detail context data
                  setFocusConcession(null);
                  setConcessions(null);
                  setConcessionName(null);

                  // trigger to refresh Concession context data
                  setConcessionYearDropdownData({
                    dropdownOptions: [],
                    selectedOptionItem: null,
                  });

                  // trigger to refresh Concession API call with context data
                  setIsTriggerRefreshConcessionYearDropdownData(true);
                }
              }}
              defaultValue={ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.personalInformation}
              isDisabled={isEditingAdminProfile || isEditingDependentProfile}
            />
          </Box>
          <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 2 }}>
            {/* show tab match with component */}
            {getSelectedBarTabComponent(adminMaintainId)}
          </Box>
        </>
      ) : searchResult && searchResult?.length > 0 ? (
        <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
          {previewProfile && <AdminInformationBar employee={previewProfile} />}
          <AdminEmployeeList
            dataList={searchResult}
            handleRowDataOnClick={({ employee }) => {
              const transformedData = transformInitAssoSubsidAdminEmployeeContext(employee);
              initEmployeeDetail(transformedData);
              setHousekeepingList(employee.housekeepingList);
              setOriginalHousekeepingListData(employee.housekeepingList);
              setDependentsHousekeepingList(employee.dependentHousekeepingList);
              setOriginalDependentsHousekeepingListData(employee.dependentHousekeepingList);
              setEditDependentId('');
            }}
          />
        </Box>
      ) : (
        searchTips && (
          <Typography variant="body_2_regular" sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
            {searchTips}
          </Typography>
        )
      )}

      <CancelDialog
        open={isShowCancelPopup}
        handleConfirmAction={() => {
          setIsShowCancelPopup(false);
        }}
        handleCloseDialog={() => {
          setIsShowCancelPopup(false);
          resetEditToViewMode();
          setHousekeepingList(originalHousekeepingListData);
          setDependentsHousekeepingList(originalDependentsHousekeepingListData);
        }}
        showCancelIcon={false}
        confirmToClose={false}
        dialogContent={{
          title: editProfilePopup.title,
          message: editProfilePopup.body,
          noTitle: editProfilePopup.withoutSaving,
          yesTitle: editProfilePopup.stayOnThePage,
        }}
      />

      <SnackbarComponent
        open={isOpenSnackbar}
        toastNotification={editProfilePopup.changesSaved}
        IconComponent={<CheckMarkIcon />}
        handleClose={() => {}}
      />
    </Box>
  );
};

export default AdminAssoSubsidEmployee;
