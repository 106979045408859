import { Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Configuration } from 'configuration';
import en from '../translations/en';
import {
  BENEFICIARY_TYPE_CODE,
  COMPANY_CODE,
  NOMINATION_OAL_ELIGIBILITY_BENEFICIARY_TYPE_CODE,
  USER_ROLE,
  USER_PROFILE_TYPE,
  ASSO_SUBSID_ELIGIBILITY_TAG,
  NOMINATION_TYPE,
  PROFILE_TYPE_FOR_SPLITTING_COPYWRITING,
  DATE_FORMAT,
} from '../constants/constants';
import { USER_PROFILE_PATHS, NOMINATION_PATHS } from '../constants/paths';
import { Employee, RetireeSpecialProfile, INominationPeriod } from 'employee';

import { INominationViewModeItem, INominationDetails } from '../interfaces';
import { INominatedPaxList } from '../context/ConcessionInProfileContext';

import {
  setRole,
  setAdminRoles,
  setAllowDelegation,
  setDelegatedBy,
  getConcessionThunk,
  getConcessionAdminThunk,
  getConcessionDelegationThunk,
} from '../slice/userSlice';

import { getDisplayVal, isRetireeWidow } from './booking';
import {
  isRetireeSpecialProfile,
  isRetiree,
  isWidow,
  getDisplayName,
  formatDateAsString,
  compareDate,
  getLocalDate,
} from '../helpers';

import { getRole } from '../services/user';

async function retrieveUserModeStatus(userId: string, dispatch: any, isChangeRole?: boolean) {
  if (!isChangeRole) {
    const { user } = (await getRole()) || {};

    const { role, adminRoles, allowDelegation, delegatedBy } = user || {};
    const { type: roleType, id: roleId } = role || {};

    dispatch(setRole({ ...role }));
    dispatch(setAllowDelegation(allowDelegation));
    dispatch(setDelegatedBy(delegatedBy));

    dispatch(setAdminRoles(adminRoles));
    if (roleType === USER_ROLE.admin) {
      dispatch(getConcessionAdminThunk());
    } else if (roleType === USER_ROLE.delegation) {
      dispatch(getConcessionDelegationThunk(roleId));
    } else {
      dispatch(getConcessionThunk(userId));
    }
  }
}

const handleBackToUserProfile = (navigate: ReturnType<typeof useNavigate>) => {
  /* Handle below pages go back to Profile menu page:
    - payment method
    - payment history
  */
  if (location.pathname === USER_PROFILE_PATHS.menu) {
    // handle Profile menu page go back to profile page (mobile view)
    navigate(USER_PROFILE_PATHS.root);
  } else {
    navigate(USER_PROFILE_PATHS.menu);
  }
};

const flownSuspensionPopup = (
  startDate: string | Date,
  endDate: string | Date,
  content: string,
  fontWeight: number,
  link?: string,
) => {
  const theme = useTheme();
  const replaceStartDateContent = content.split(en.userProfile.flownSuspension.startDate);
  const replaceEndDateContent = replaceStartDateContent[1].split(en.userProfile.flownSuspension.endDate);
  const replaceLinkContent = replaceEndDateContent[1].split(link || '');
  const replaceEndDateContentTemp = (
    <Typography
      variant={link ? 'body_1_medium' : 'body_2_regular'}
      sx={{
        color: theme.color.secondary.dark_grey.option_1,
        display: 'inline',
      }}
    >
      {replaceStartDateContent[0]}
      <Typography
        variant={link ? 'body_1_medium' : 'body_2_regular'}
        sx={{
          color: theme.color.secondary.dark_grey.option_1,
          fontWeight: fontWeight,
          display: 'inline',
        }}
      >
        {getDisplayVal(startDate)}
      </Typography>
      {replaceEndDateContent[0]}
      <Typography
        variant={link ? 'body_1_medium' : 'body_2_regular'}
        sx={{
          color: theme.color.secondary.dark_grey.option_1,
          fontWeight: fontWeight,
          display: 'inline',
        }}
      >
        {getDisplayVal(endDate)}
      </Typography>
      {link ? (
        <>
          {replaceLinkContent[0]}
          <Typography
            variant={link ? 'body_1_medium' : 'body_2_regular'}
            sx={{
              display: 'inline',
              cursor: 'pointer',
              color: theme.color.utility.link.option_3,
            }}
            onClick={() => {
              // click the linkage to redirect to other website
              if (window.config?.flownSuspensionMoreDetailLink)
                window.open(window.config.flownSuspensionMoreDetailLink);
            }}
          >
            {en.userProfile.flownSuspension.link}
          </Typography>
          {replaceLinkContent[1]}
        </>
      ) : (
        replaceEndDateContent[1]
      )}
    </Typography>
  );
  return replaceEndDateContentTemp;
};

const nominationFlowLbl = en.userProfile.nomination.nominationFlow.common;
const getEligibleCarrierJoinedText = (eligibleCarrier: string[] | undefined) => {
  return eligibleCarrier
    ?.map(
      (carrier: string) =>
        nominationFlowLbl.eligibilityCarrier?.[carrier as keyof typeof nominationFlowLbl.eligibilityCarrier],
    )
    .join(', ');
};

const handleEligibleCarrier = (oalEligibilityOptions: Configuration.OALEligibilityLabel | undefined) => {
  const eligibilityCarrierLbl = nominationFlowLbl.eligibilityCarrier;

  const eligibleCarrier: string[] = [];

  // ['cx', 'oneworld', 'zed']
  const eligibleCarrierKeys = Object.keys(eligibilityCarrierLbl);

  // [(cx)true/false, (oneworld)true/false, (zed)true/false]
  const eligibilityCarrierBooleanArray = [
    oalEligibilityOptions?.cx,
    oalEligibilityOptions?.oneworld,
    oalEligibilityOptions?.zed,
  ];

  eligibleCarrierKeys.forEach((value: string, index: number) => {
    // map eligibleCarrierKeys & eligibilityCarrierBooleanArray
    if (eligibilityCarrierBooleanArray[index]) {
      // if true, push eligibleCarrierKeys's value('cx','oneworld','zed') to eligibleCarrier[]
      eligibleCarrier.push(value);
    }
  });

  return eligibleCarrier;
};

const handleNominatedEligibleCarrier = (
  configurations: Configuration.ConfigurationsClient,
  nominationList: INominationViewModeItem[],
  isAssoSubsid: boolean,
) => {
  const isSpouseOrCompanion = (beneficiaryTypeCode: string) =>
    NOMINATION_OAL_ELIGIBILITY_BENEFICIARY_TYPE_CODE.includes(beneficiaryTypeCode);
  const isChildrenDependent = (beneficiaryTypeCode: string) => beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.children;

  return nominationList.map((item: INominationViewModeItem) => {
    let eligibleCarrier: string[] = [];

    if (isAssoSubsid) {
      // Asso/subsi users only entitle to "CX" travel
      eligibleCarrier = ASSO_SUBSID_ELIGIBILITY_TAG;
    } else {
      const matchedActiveNominationRecord = getSingleActiveNominationRecord(item.nominationDetails);
      const oalEligibilityOptions = configurations.airlineEligibility.find(
        (oalEligibility: Configuration.OALEligibilityLabel) =>
          item.beneficiaryTypeCode === oalEligibility.beneficiaryTypeCode &&
          (isSpouseOrCompanion(item.beneficiaryTypeCode)
            ? matchedActiveNominationRecord?.isAssignedOAL === oalEligibility.withOALEligibility // spo or frd use "isAssignedOAL" <-> "withOALEligibility"
            : isChildrenDependent(item.beneficiaryTypeCode) // chd use "false" data (TODO: check nominationType:"CHILDREN_UNDER_24" in future story)
            ? oalEligibility.withOALEligibility === false
            : true), // other type not check withOALEligibility flag
      );

      eligibleCarrier = handleEligibleCarrier(oalEligibilityOptions);
    }

    return {
      ...item,
      eligibleCarrier,
    };
  });
};

const filterCompanionData = (list: INominationViewModeItem[]) => {
  if (!list) return [];

  return list.filter((item: INominationViewModeItem) => item.beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion);
};

const validateForRegisterMaxNumberOfTravelCompanion = <T,>(travelCompanionList: T[]): boolean => {
  const registerMaxNumberOfTravelCompanion = window.config.registerMaxNumberOfTravelCompanion;
  // bypass checking if Jenkins env variable undefined
  if (!registerMaxNumberOfTravelCompanion) return true;

  return travelCompanionList.length < registerMaxNumberOfTravelCompanion;
};

const getRetireeSwitchProfileLabel = (profile: Employee.Profile) => {
  if (!profile || (profile && !isRetireeSpecialProfile(profile) && !isRetiree(profile))) return '';

  const retireeSpecialProfileLbl = en.userProfile.retireeSpecialProfile;

  /* 
    If current profile is special profile,
    the label should be for Retiree normal profile,
    else the label as Retiree special profile.
  */
  const switchProfileLabel = isRetireeSpecialProfile(profile)
    ? retireeSpecialProfileLbl.retireeProfileTitle
    : retireeSpecialProfileLbl.switchProfileDialog.specialProfileLabel;

  return switchProfileLabel;
};

const getRetireeOriginEmployeeIdByRetireeSpecialProfile = (profile: Employee.Profile) => {
  if (profile?.employeeId) {
    return profile.employeeId.split('_')[0];
  }
};

const trimRetireeSpecialEmployeeId = (employeeId: string) => {
  return employeeId.replace(`_${USER_PROFILE_TYPE.retireeSpecialProfile}`, '');
};

const handleRetireeSwitchEmployeeId = (
  profile: Employee.Profile,
  retireeSpecialProfile: RetireeSpecialProfile | undefined,
) => {
  let employeeId = '';
  if (isRetiree(profile) && retireeSpecialProfile) {
    employeeId = retireeSpecialProfile.employeeId;
  } else if (isRetireeSpecialProfile(profile)) {
    employeeId = getRetireeOriginEmployeeIdByRetireeSpecialProfile(profile) || '';
  }

  return employeeId;
};

const isAssoSubsid = (profile?: Employee.Profile | null) => {
  return !!profile?.upn && profile.hiringCompany !== COMPANY_CODE.CX;
};

const injectNominationYearInText = ({
  targetText,
  nominationPeriodRecord,
}: {
  targetText: string;
  nominationPeriodRecord?: INominationPeriod | null;
}) => {
  if (!targetText || !nominationPeriodRecord?.nominationYearFrom) return '';

  /* 
    Text display logic:
    If backend return nominationYearFrom(2024) + nominationYearTo(2026), frontend display "2024-2026",
    if the nominationYearTo is (2099), then display "2024-2099";

    If backend return nominationYearFrom(2024) only, frontend display "2024" as text.
  */
  const yearValue =
    nominationPeriodRecord?.nominationYearTo &&
    nominationPeriodRecord?.nominationYearFrom !== nominationPeriodRecord?.nominationYearTo
      ? `${nominationPeriodRecord?.nominationYearFrom}-${nominationPeriodRecord?.nominationYearTo}`
      : nominationPeriodRecord?.nominationYearFrom;

  return targetText.replace('{YEAR}', `${yearValue}`);
};

const checkIsNextYearNominationFlow = () => location.pathname === NOMINATION_PATHS.nextYearNominationFlow;

const getSingleActiveNominationRecord = (nominationDetails?: INominationDetails[]) => {
  const matched = nominationDetails?.find((nominationDetailItem: INominationDetails) => nominationDetailItem.isActive);
  return matched;
};

// [ETP-5769] add: to filter out nomination type maxNumberOfPerson is 0
const getEligibleNominationTypeList = (nominationViewModeData: any) =>
  Object.values(NOMINATION_TYPE).filter(
    (nominationType: string) => nominationViewModeData?.[nominationType]?.maxNumberOfPerson > 0,
  );

// For ESS Concession detail page currently
const getEssConcessionPageNominatedPaxList = (tmpPaxList: INominatedPaxList[] = []): INominatedPaxList[] => {
  return tmpPaxList.map((item: INominatedPaxList) => {
    return {
      ...item,
      label: getDisplayName({
        title: item?.title,
        firstName: item?.firstName,
        lastName: item?.lastName,
      }),
    };
  });
};

// For ESS Concession detail page currently
const getEssConcessionPageDefaultEmployeePaxData = (
  profile: Employee.Profile | null,
  tmpPaxList = [] as INominatedPaxList[],
): INominatedPaxList | null => {
  if (!profile || tmpPaxList.length < 1) return null;

  // for Widow role business logic, please refer to previous story [ETP-4829]
  if (isWidow(profile)) {
    return tmpPaxList[0];
  } else {
    const employeePax = tmpPaxList.find(
      (item: INominatedPaxList) => item.beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.employee,
    );
    return employeePax || null;
  }
};

const getLabelKeyByProfileType = (profile: Employee.Profile | null) => {
  let typeLabel = PROFILE_TYPE_FOR_SPLITTING_COPYWRITING.employee;
  if (isAssoSubsid(profile)) {
    typeLabel = PROFILE_TYPE_FOR_SPLITTING_COPYWRITING.assoSubsid;
  } else if (profile && (isRetireeSpecialProfile(profile) || isRetireeWidow(profile))) {
    typeLabel = PROFILE_TYPE_FOR_SPLITTING_COPYWRITING.retiree;
  }

  return typeLabel;
};

// etp-5972 add: calc user’s travel end date
// 1. only in lt concession
// 2. if Retiree Special profile, check normalRetirementTravelEndDate
// 3. not Retiree Special profile, check terminationDate/travelEndDate/normalRetirementTravelEndDate -> furthest date
const calculateLTMaxDepartureDate = (profile: Employee.Profile | null, calcAdvancedDay: Date) => {
  let ltDepartureDate = '';
  const { normalRetirementTravelEndDate = '', terminationDate = '', travelEndDate = '' } = profile || {};

  if (profile && isRetireeSpecialProfile(profile)) {
    ltDepartureDate = normalRetirementTravelEndDate || '';
  } else {
    ltDepartureDate = _.max([normalRetirementTravelEndDate, terminationDate, travelEndDate]) || '';
  }

  const formatAdvancedDay = formatDateAsString(calcAdvancedDay, DATE_FORMAT.date);

  // [ETP6099] check if ltDepartureDate is before today, return advanced booking day directly
  if (compareDate(getLocalDate(), ltDepartureDate) > 0) {
    return formatAdvancedDay;
  }

  // if both travelEndDate & terminationDate & normalRetirementTravelEndDate is ““/null/undefined → use calcAdvancedDay
  // get ltDepartureDate and compare with formatAdvancedDay, return the min value
  return _.min([ltDepartureDate, formatAdvancedDay].filter((item) => item)) || '';
};

// [ETP-6033] retrieve the nomination period record by yearType
const getNominationPeriodRecordByYearType = (profile: Employee.Profile | null, yearType: string) => {
  if (!profile || !yearType) return null;

  // the period record must be included nominationYearFrom, yearType, isEnabled fields.
  return (
    profile?.nominationPeriods?.find(
      (item) => item.yearType === yearType && item.nominationYearFrom && item.isEnabled !== undefined,
    ) || null
  );
};

export {
  isAssoSubsid,
  retrieveUserModeStatus,
  handleBackToUserProfile,
  flownSuspensionPopup,
  getEligibleCarrierJoinedText,
  handleEligibleCarrier,
  handleNominatedEligibleCarrier,
  filterCompanionData,
  validateForRegisterMaxNumberOfTravelCompanion,
  getRetireeSwitchProfileLabel,
  getRetireeOriginEmployeeIdByRetireeSpecialProfile,
  trimRetireeSpecialEmployeeId,
  handleRetireeSwitchEmployeeId,
  injectNominationYearInText,
  checkIsNextYearNominationFlow,
  getSingleActiveNominationRecord,
  getEligibleNominationTypeList,
  getEssConcessionPageNominatedPaxList,
  getEssConcessionPageDefaultEmployeePaxData,
  getLabelKeyByProfileType,
  calculateLTMaxDepartureDate,
  getNominationPeriodRecordByYearType,
};
