import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Typography, IconButton, useTheme, Checkbox, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field, FormErrors, change } from 'redux-form';

import en from '../../../translations/en';
import { AlertIcon, BackIcon, CheckBoxCheckIcon, CheckBoxIcon } from '../../../assets/images';
import { FORM } from '../../../constants/constants';

import { ICompanionPassportDetails } from '../../../interfaces';

import { findDropdownOptionClient, getDisplayVal, validateRequiredFields } from '../../../helpers';

import { RootState } from '../../../app/store';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import { selectApp, setSnackBarData } from '../../../slice/appSlice';
import { selectAuth } from '../../../slice/authSlice';
import { selectUser, setEditContactPassportDetails } from '../../../slice/userSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { editUserPassportDetail } from '../../../services/user';
import {
  CancelDialog,
  CountrySelectInput,
  Footer,
  FormButton,
  PassportExpiryDatePicker,
  ReduxFormTextInput,
} from '../../../components';
import { ParagraphMasterContainer } from '../../Common';

export const validatePassport = (
  values: ICompanionPassportDetails | null,
): FormErrors<ICompanionPassportDetails | null> => {
  const errors: FormErrors<ICompanionPassportDetails | null> = {};

  if (values) {
    validateRequiredFields(
      values,
      ['passportNumber', 'passportExpirationDate', 'passportNationality', 'passportCountry'],
      errors,
    );
  }

  return errors;
};

// userProfile-> edit passport: only for retiree/widow/asso-subsid
const EditPassportDetail = (props: any) => {
  const {
    passportFormValues,
  }: {
    passportFormValues: ICompanionPassportDetails;
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { ern: ernFromLogin } = useAppSelector(selectAuth) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { profile, passports, editContactPassportDetails } = useAppSelector(selectUser) || {};

  const [isPassportNameConfirmed, setIsPassportNameConfirmed] = useState<boolean>(false);
  const [isPassportInfoConsent, setIsPassportInfoConsent] = useState<boolean>(false);
  const [showEditPassportInfo, setShowEditPassportInfo] = useState<boolean>(false);
  const [isOpenCancelEditPassportDialog, setIsOpenCancelEditPassportDialog] = useState<boolean>(false);
  const {
    dependentId,
    passportNumber,
    passportExpirationDate,
    passportNationality,
    passportLastName,
    passportFirstName,
    passportCountry,
  } = passports?.[0] || {};

  const {
    passportNumber: passportNumberLbl,
    passportName: passportFirstNameLbl,
    passportSurname: passportLastNameLbl,
    passportExpiryDate: passportExpiryDateLbl,
    passportNationality: passportNationalityLbl,
    passportIssuedCountry: passportIssueCountryLbl,
    editPassportDetails: passportDetailsLbl,
    editSuccessPassportInformationMessage,
  } = en.userProfile.personal.passportInfo;

  const cancelEditCompanionPassportLbl = en.userProfile.nomination.companionRegistration.common.editCompanionPassport;

  useEffect(() => {
    dispatch(
      change(FORM.editPassportDetail, 'passportFirstName', passportFirstName || profile?.personalInfo?.firstName),
    );
    dispatch(change(FORM.editPassportDetail, 'passportLastName', passportLastName || profile?.personalInfo?.lastName));
    dispatch(change(FORM.editPassportDetail, 'passportNumber', passportNumber));
    dispatch(change(FORM.editPassportDetail, 'passportExpirationDate', passportExpirationDate));
    dispatch(
      change(
        FORM.editPassportDetail,
        'passportNationality',
        findDropdownOptionClient(configurations?.countries, passportNationality)?.label,
      ),
    );
    dispatch(
      change(
        FORM.editPassportDetail,
        'passportCountry',
        findDropdownOptionClient(configurations?.countries, passportCountry || '')?.label,
      ),
    );
  }, [passports]);

  const basePassport = {
    passportNumber: passportFormValues?.passportNumber?.trim(),
    passportFirstName: passportFormValues?.passportFirstName?.trim(),
    passportLastName: passportFormValues?.passportLastName?.trim(),
    passportExpirationDate: passportFormValues?.passportExpirationDate?.trim(),
  };

  // checked if all the passport mandatory fields are filled
  const isPassportInputValid = !!(
    Object.values(basePassport).every(Boolean) &&
    passportFormValues?.passportNationality &&
    passportFormValues?.passportCountry &&
    isPassportInfoConsent
  );

  useEffect(() => {
    // etp-6017 add: retiree/widow/ Subs/asso users click "update passport details" -> "confirm" update passport details
    // call "editPassportDetail" api, and reset "isConfirmPassportDetails" to false to reset flag.
    if (editContactPassportDetails?.isConfirmPassportDetails) {
      editPassportDetailsAction();
      dispatch(
        setEditContactPassportDetails({
          ...editContactPassportDetails,
          isConfirmPassportDetails: false,
        }),
      );
    }
  }, [editContactPassportDetails]);

  const onSavePassportDetails = () => {
    if (!showEditPassportInfo && isPassportNameConfirmed) {
      setShowEditPassportInfo(true);
    } else {
      editPassportDetailsAction();
    }
  };

  const editPassportDetailsAction = async () => {
    const result = await editUserPassportDetail({
      userId: ernFromLogin,
      dependentId,
      passportNumber: passportFormValues?.passportNumber || passportNumber || '',
      passportExpirationDate: passportFormValues?.passportExpirationDate || passportExpirationDate || '',
      passportNationality: passportFormValues?.passportNationality?.code || passportNationality,
      passportCountry: passportFormValues?.passportCountry?.code || passportCountry || '',
    });

    if (result) {
      dispatch(
        setSnackBarData({
          message: editSuccessPassportInformationMessage,
          isShow: true,
          delay: 500,
          customStyles: { mb: isDesktop ? 1 : 2 },
        }),
      );

      navigate(-1);
    }
  };

  const handlePassportInfoConsent = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPassportInfoConsent(event.target.checked);
  };

  const handlePassportNameConfirmed = (event: ChangeEvent<HTMLInputElement>) => {
    setIsPassportNameConfirmed(event.target.checked);
  };

  return (
    <Box
      className="edit_passport_container"
      sx={{
        ...(isDesktop && {
          width: '488px',
        }),
      }}
    >
      {!isDesktop && (
        <IconButton onClick={() => navigate(-1)} sx={{ py: 1.625, pl: 0 }}>
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}

      <Typography
        variant="headline_medium"
        sx={{
          mt: 2.125,
          mb: 2,
          color: theme.color.secondary.dark_grey.option_1,
        }}
      >
        {passportDetailsLbl}
      </Typography>

      <Paper sx={{ padding: '8px 16px' }}>
        <Typography variant="caption" color="textSecondary">
          {passportFirstNameLbl}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {getDisplayVal(passportFirstName || profile?.personalInfo?.firstName)}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {passportLastNameLbl}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {getDisplayVal(passportLastName || profile?.personalInfo?.lastName)}
        </Typography>
      </Paper>

      {showEditPassportInfo ? (
        <>
          <Paper sx={{ padding: '8px 16px', mt: 2.125 }}>
            <Field
              component={ReduxFormTextInput}
              name="passportNumber"
              isNumOrAlphaOrSpace
              title={passportNumberLbl}
              isAlphaOrSpace
              isUpperCase
            />

            <PassportExpiryDatePicker name="passportExpirationDate" title={passportExpiryDateLbl} />

            <CountrySelectInput name="passportNationality" title={passportNationalityLbl} />

            <CountrySelectInput name="passportCountry" title={passportIssueCountryLbl} />
          </Paper>

          <Paper sx={{ padding: '4px', mt: 2.125 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ alignSelf: 'flex-start' }}>
                <Checkbox
                  sx={{
                    '&.MuiCheckbox-root': {
                      color: theme.status.selected,
                    },
                  }}
                  checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                  icon={<CheckBoxIcon stroke={theme.status.selected} />}
                  color="info"
                  checked={isPassportInfoConsent}
                  onChange={handlePassportInfoConsent}
                />
              </Box>

              <Typography variant="body_2_regular">{en.userProfile.personal.passportInfo.consentMessage}</Typography>
            </Box>
          </Paper>
        </>
      ) : (
        <Paper sx={{ padding: '4px', mt: 2.125 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ alignSelf: 'flex-start', mt: 1.4 }}>
              <Checkbox
                sx={{
                  '&.MuiCheckbox-root': {
                    color: theme.status.selected,
                  },
                }}
                checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                icon={<CheckBoxIcon stroke={theme.status.selected} />}
                color="info"
                checked={isPassportNameConfirmed}
                onChange={handlePassportNameConfirmed}
              />
            </Box>

            <Box>
              <ParagraphMasterContainer
                content={en.userProfile.personal.passportInfo.confirmPassportNameContent}
                paragraphTypoSxProps={{
                  color: theme.color.secondary.dark_grey.option_1,
                }}
                multiParagraphContainerSxProps={{ p: 0 }}
                paragraphVariant="body_2_regular"
              />
            </Box>
          </Box>
        </Paper>
      )}

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              ...{ minWidth: 0 },
            }}
            onClick={() => {
              setIsOpenCancelEditPassportDialog(true);
            }}
          >
            {en.common.cancel}
          </FormButton>
        }
        primaryBtn={{
          isFullWidth: false,
          text: en.common.confirm,
          customOnClick: onSavePassportDetails,
          customButtonStyles: {
            mr: 0,
            width: '128px',
            '&.Mui-disabled': {
              color: theme.palette.primary.light,
            },
          },
          status: !(isPassportInputValid || (!showEditPassportInfo && isPassportNameConfirmed)) || false,
        }}
        customStyles={{
          zIndex: 1,
          position: 'fixed',
          left: 0,
          bottom: 0,
          px: 2,
          ...(isDesktop && {
            px: 22,
            borderRadius: '24px 24px 0px 0px',
            boxShadow: theme.boxShadow.important,
          }),
        }}
      />

      <CancelDialog
        open={isOpenCancelEditPassportDialog}
        handleConfirmAction={() => {
          setIsOpenCancelEditPassportDialog(false);
          navigate(-1);
        }}
        handleCloseDialog={() => {
          setIsOpenCancelEditPassportDialog(false);
        }}
        confirmToClose={false}
        IconComponent={<AlertIcon />}
        dialogContent={{
          title: cancelEditCompanionPassportLbl.cancelEditCompanionPassportDialog.title,
          message: cancelEditCompanionPassportLbl.cancelEditCompanionPassportDialog.description,
          yesTitle: en.common.yesConfirm,
          noTitle: en.common.back,
        }}
      />
    </Box>
  );
};

const form = reduxForm({
  form: FORM.editPassportDetail,
  validate: validatePassport,
  initialValues: {},
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EditPassportDetail);

export default connect((state: RootState) => ({
  passportFormValues: state.form.EditPassportDetailForm?.values,
}))(form);
