import React, { useState, useContext } from 'react';
import { Collapse, Typography, useTheme, Box, SxProps, Theme } from '@mui/material';
import { reset } from 'redux-form';

import { NominatedIcon } from '../../../assets/images';
import en from '../../../translations/en';
import { INFINITE_DATE, FORM, LABEL_CATEGORY } from '../../../constants/constants';

import { IDependent, INominationDetails } from '../../../interfaces';
import {
  compareDate,
  findDropdownOptionClient,
  getDisplayName,
  getDisplayVal,
  formatDMYDateWithSpace,
  getDisplayLabel,
} from '../../../helpers';

import { NominationAction, NominationState } from '../../../context';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

type TExpandedDataItem = string | boolean;

interface IExpandedData {
  leftSideLabel?: TExpandedDataItem;
  rightSideLabel?: TExpandedDataItem;
  leftSideValue?: TExpandedDataItem | TExpandedDataItem[];
  rightSideValue?: TExpandedDataItem;
  isAllowEdit?: boolean;
  originDependentData?: IDependent;
  title?: string;
  isAllowInactivate?: boolean;
  expandIndex?: number;
}

interface IFieldRowData {
  label: TExpandedDataItem;
  value: TExpandedDataItem;
  fieldCustomStyles?: SxProps<Theme>;
}

const dependentLbl = en.userProfile.dependent;

const RenderFieldRow = ({
  fieldRowData,
  isAllowInactivate,
  originDependentData,
}: {
  fieldRowData: IFieldRowData[];
  isAllowInactivate?: boolean;
  originDependentData?: IDependent;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { setCompanionDependentStatusFlow } = useContext(NominationAction) || {};
  const { companionDependentStatusFlow } = useContext(NominationState) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};

  return (
    <>
      {fieldRowData.map((field, index) => {
        return (
          field.label && (
            <Box className="field_row_item" key={index} sx={{ width: '50%', ...field.fieldCustomStyles }}>
              <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
                {field.label}
              </Typography>
              {field.label === dependentLbl.dependentStatus && isAllowInactivate && (
                <Typography
                  variant="body_2_bold"
                  sx={{
                    color: theme.color.utility.link.option_3,
                    mt: -1.8,
                    cursor: 'pointer',
                    textAlign: 'end',
                    ml: 'auto',
                  }}
                  onClick={() => {
                    dispatch(reset(FORM.companionDependentStatus));
                    setCompanionDependentStatusFlow({
                      ...companionDependentStatusFlow,
                      editDependentStatusData: {
                        ...companionDependentStatusFlow.editDependentStatusData,
                        isInactivatedMode: true,
                        dependentId: originDependentData?.dependentId,
                        relationship: originDependentData?.relationship,
                        displayName: `${originDependentData?.title} ${originDependentData?.firstName} ${originDependentData?.lastName}`,
                        dateOfBirth: originDependentData?.dateOfBirth,
                        nominationDetails:
                          originDependentData?.nominationDetails && originDependentData.nominationDetails.length > 0
                            ? originDependentData?.nominationDetails
                                .slice()
                                .sort((a: INominationDetails, b: INominationDetails) => {
                                  return compareDate(a?.nominationFrom || '', b?.nominationFrom || '');
                                })
                                .map((details) => {
                                  return `${getDisplayVal(formatDMYDateWithSpace(details.nominationFrom))} - ${
                                    compareDate(getDisplayVal(details.nominationTo), INFINITE_DATE) > 0
                                      ? en.userProfile.delegation.noDueDate
                                      : getDisplayVal(formatDMYDateWithSpace(details.nominationTo))
                                  } (${getDisplayLabel(
                                    configurations.labels,
                                    LABEL_CATEGORY.nomineeType,
                                    details.nominationType,
                                  )})`;
                                })
                            : en.common.empty,
                      },
                    });
                  }}
                >
                  {en.userProfile.nomination.inactivatedDependentStatusDetails.edit}
                </Typography>
              )}

              {/*
               * the field.value change to string or array<string>
               */}
              {([] as Array<TExpandedDataItem>)
                .concat(field.value)
                .map((item: TExpandedDataItem, itemIndex: number) => (
                  <Typography variant="body_1_medium" key={itemIndex}>
                    {item}
                  </Typography>
                ))}
            </Box>
          )
        );
      })}
    </>
  );
};

const RenderHorizontalView = ({
  title,
  leftSideValue,
  leftSideLabel,
  rightSideValue,
  rightSideLabel,
  isAllowEdit = false,
  isAllowInactivate = false,
  originDependentData,
  expandIndex,
}: IExpandedData) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { setCompanionRegistrationFlow, setDefaultDependentDetailsCardExpand } = useContext(NominationAction) || {};
  const { companionRegistrationFlow } = useContext(NominationState) || {};
  if (title) {
    return (
      <Box className="dependent_details_card_title" sx={{ display: 'flex' }}>
        <Typography
          variant="body_2_bold"
          sx={{
            color: theme.color.secondary.dark_grey.option_1,
            mb: 1.75,
            mt: 2,
          }}
        >
          {title}
        </Typography>

        {isAllowEdit && (
          <Typography
            variant="body_2_bold"
            sx={{
              color: theme.color.utility.link.option_3,
              mt: 2,
              cursor: 'pointer',
              textAlign: 'end',
              ml: 'auto',
            }}
            onClick={() => {
              // reset form first
              dispatch(reset(FORM.companionPassport));

              setDefaultDependentDetailsCardExpand(expandIndex);

              // mapping the nationality, country with configuration codelabel data, to set an object with `code` and `label`.
              const mappedPassportNationality = findDropdownOptionClient(
                configurations.countries,
                originDependentData?.passportNationality,
              );
              const mappedPassportCountry = findDropdownOptionClient(
                configurations.countries,
                originDependentData?.passportCountry,
              );

              // save passport data to context
              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                editPassport: {
                  ...companionRegistrationFlow.editPassport,
                  isEditMode: true,
                  beneficiaryTypeCode: originDependentData?.beneficiaryTypeCode,
                  dependentId: originDependentData?.dependentId,
                  initFormData: {
                    passportNumber: originDependentData?.passportNumber,
                    passportFirstName: originDependentData?.firstName,
                    passportLastName: originDependentData?.lastName,
                    passportExpirationDate: originDependentData?.passportExpirationDate,
                    passportNationality: mappedPassportNationality,
                    passportCountry: mappedPassportCountry,
                  },
                },
              });
            }}
          >
            {en.common.edit}
          </Typography>
        )}
      </Box>
    );
  }

  if (!leftSideLabel && !rightSideLabel) {
    return <></>;
  }

  const transformedFieldRowData: IFieldRowData[] = [
    {
      label: leftSideLabel || '',
      value: getDisplayVal(leftSideValue),
      fieldCustomStyles: {
        minWidth: '130px',
        width: rightSideLabel ? '50%' : '100%',
      },
    },
    {
      label: rightSideLabel || '',
      value: getDisplayVal(rightSideValue),
      fieldCustomStyles: {
        minWidth: '130px',
        pl: 1,
      },
    },
  ];

  return (
    <Box
      className="dependent_details_card_horizontal_view"
      sx={{
        display: 'flex',
        mb: 2,
      }}
    >
      <RenderFieldRow
        fieldRowData={transformedFieldRowData}
        isAllowInactivate={isAllowInactivate}
        originDependentData={originDependentData}
      />
    </Box>
  );
};

const DependentDetailsCard = ({
  data,
  isNominatedList,
  expandIndex,
  isDefaultExpand,
}: {
  data: IDependent;
  isNominatedList: boolean;
  expandIndex: number;
  isDefaultExpand: boolean;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};

  const [isExpand, setIsExpand] = useState(isDefaultExpand);

  const passportFieldLabel = dependentLbl.lastNameAsInPassport;
  const {
    title,
    firstName,
    lastName,
    relationship,
    dateOfBirth,
    isActive,
    isAllowEdit,
    isAllowInactivate,
    beneficiaryType,
    nominationDetails,
    passportNumber,
    passportCountry,
    passportNationality,
    passportExpirationDate,
    passportName: { firstName: passportFirstName, lastName: passportLastName },
  } = data;

  const expandedData: IExpandedData[] = [
    {
      title: dependentLbl.dependentDetails,
      isAllowEdit: false,
    },
    {
      leftSideLabel: dependentLbl.relationship,
      leftSideValue: relationship,
      rightSideLabel: dependentLbl.dateOfBirth,
      rightSideValue: formatDMYDateWithSpace(dateOfBirth),
    },
    {
      leftSideLabel: dependentLbl.dependentStatus,
      leftSideValue: isActive ? dependentLbl.active : dependentLbl.inActive,
      rightSideLabel: false,
      rightSideValue: false,
      isAllowInactivate,
    },
    {
      leftSideLabel: dependentLbl.nominationDateAndType,
      leftSideValue: isNominatedList
        ? nominationDetails
            .slice()
            .sort((a: INominationDetails, b: INominationDetails) => {
              return compareDate(a?.nominationFrom || '', b?.nominationFrom || '');
            })
            .map((details) => {
              return `${getDisplayVal(formatDMYDateWithSpace(details.nominationFrom))} - ${
                compareDate(getDisplayVal(details.nominationTo), INFINITE_DATE) > 0
                  ? en.userProfile.delegation.noDueDate
                  : getDisplayVal(formatDMYDateWithSpace(details.nominationTo))
              } (${getDisplayLabel(configurations.labels, LABEL_CATEGORY.nomineeType, details.nominationType)})`;
            })
        : '',
      rightSideLabel: false,
      rightSideValue: false,
    },
    {
      title: dependentLbl.passportDetails,
      isAllowEdit: isAllowEdit || false,
    },
    {
      leftSideLabel: dependentLbl.firstOtherNameAsInPassport,
      leftSideValue: passportFirstName || firstName,
      // only display in desktop view
      rightSideLabel: isDesktop ? passportFieldLabel : false,
      rightSideValue: isDesktop ? passportLastName || lastName : false,
    },
    // only display in mobile view
    {
      leftSideLabel: !isDesktop ? passportFieldLabel : false,
      leftSideValue: !isDesktop ? passportLastName || lastName : false,
      rightSideLabel: false,
      rightSideValue: false,
    },
    {
      leftSideLabel: dependentLbl.passportNumber,
      leftSideValue: passportNumber,
      rightSideLabel: dependentLbl.passportExpiryDate,
      rightSideValue: formatDMYDateWithSpace(passportExpirationDate),
    },
    {
      leftSideLabel: dependentLbl.nationalityInPassport,
      leftSideValue: findDropdownOptionClient(configurations?.countries, passportNationality || '')?.label,
      rightSideLabel: dependentLbl.issuedCountry,
      rightSideValue: findDropdownOptionClient(configurations?.countries, passportCountry || '')?.label,
    },
  ];

  const defaultFieldRowData: IFieldRowData[] = [
    {
      label: dependentLbl.name,
      value: getDisplayName({
        title: title,
        firstName: firstName,
        lastName: lastName,
      }),
    },
    {
      label: dependentLbl.type,
      value: getDisplayVal(beneficiaryType),
      fieldCustomStyles: {
        pl: 1,
      },
    },
  ];

  return (
    <Box
      className="dependent_details_card"
      sx={{
        boxShadow: theme.palette.boxShadow.dark,
        borderRadius: 1,
        p: 2,
        mt: 1,
        bgcolor: isNominatedList ? theme.palette.bgColor.main : theme.color.secondary.light_slate.option_6,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <RenderFieldRow fieldRowData={defaultFieldRowData} />

        {isNominatedList ? (
          <NominatedIcon width={20} height={20} fill={theme.color.secondary.dark_grey.option_3} />
        ) : (
          <Box sx={{ width: '20px' }} />
        )}
      </Box>
      <Collapse in={isExpand} collapsedSize={`${17 * 2}px`}>
        <Typography
          variant="body_2_bold"
          sx={{
            color: theme.color.utility.link.option_3,
            mt: 2,
            cursor: 'pointer',
            textAlign: 'end',
          }}
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? en.common.viewLess : data.isAllowEdit ? en.common.viewMoreAndEdit : en.common.viewMore}
        </Typography>

        {isExpand &&
          expandedData?.map((item: IExpandedData, index: number) => {
            return <RenderHorizontalView key={index} {...item} expandIndex={expandIndex} originDependentData={data} />;
          })}
      </Collapse>
    </Box>
  );
};

export default DependentDetailsCard;
