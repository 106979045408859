import { Box } from '@mui/material';
import { controlBreakpointDisplayByLoginRole } from '../../helpers';

const DesktopView = (props: any) => {
  const { isAdmin = false, ...restProps } = props;
  const breakpointDisplayProps = controlBreakpointDisplayByLoginRole({ isAdmin, isShowMobileView: false });
  return <Box display={breakpointDisplayProps} {...restProps} />;
};

export default DesktopView;
