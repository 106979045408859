import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Grid, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEmpty, isUndefined } from 'lodash';

import en from '../../translations/en';
import { SAVE_DATA_TO, UI_METRICS_IN_PX, USER_ROLE } from '../../constants/constants';
import { BOOKING_PATHS, ROOT_PATHS } from '../../constants/paths';

import { OVERLAY_VARIANT } from '../../containers/BookingFlow/Common/BookingFlowOverlay';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getProfileThunk,
  resetPaymentHistoryPage,
  selectUser,
  setIsShowSelectProfileDialog,
} from '../../slice/userSlice';
import { selectAuth } from '../../slice/authSlice';
import { reset as resetBookingUserSlice } from '../../slice/bookingUserSlice';
import { reset as resetBookingFilterSlice } from '../../slice/bookingUserSlice';
import { reset as resetBookingSlice, selectBooking } from '../../slice/bookingSlice';
import { getAirportsThunk, getConfigurationsThunk, selectConfiguration } from '../../slice/configurationSlice';
import { selectApp, setErrorAlert, initialState as appSliceInitialState } from '../../slice/appSlice';
import { setIsOpenFlownSuspensionDialog } from '../../slice/flownSuspensionSlice';

import { isRetiree, isRetireeSpecialProfile, retrieveUserModeStatus } from '../../helpers';
import { TravelTypeListLtAction } from '../../context';

import { ScrollableView, MobileView, DesktopView, BottomNavBar, Div100vhView } from '../../components';
import {
  DateSelection,
  BookingFlowContainer,
  SearchBar,
  ConcessionSelectionContainer,
  LocationSelection,
  UpComingBookingLayout,
  AdminFeatureLayout,
  DesktopHeader,
  MobileHeader,
  MobileSearchCriteriaBox,
} from '../../containers';

const Home = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const authState = useAppSelector(selectAuth) || {};
  const { ern } = authState;
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { viewBookingApplicationId, isCppBookingCreated, bookingStep } = useAppSelector(selectBooking) || {};
  const { role, adminRoles, profile, isShowSelectProfileDialog, isShowProfileIndicator } =
    useAppSelector(selectUser) || {};
  const { airports, configurations } = useAppSelector(selectConfiguration) || {};
  const { setIsShowDefaultTravelTypeOption } = useContext(TravelTypeListLtAction);

  const [showFlowView, setShowFlowView] = useState(false);

  const [isReachScrollOffset, setIsReachScrollOffset] = useState(false);

  const [curShowPop, setCurShowPop] = useState<string>('');

  const scrollViewRef = useRef<HTMLDivElement>(null);

  const { isShowFlownSuspensionAlert, retireeSpecialProfile } = profile || {};
  const { type: roleType } = role || {};

  const toggleSticky = useCallback(
    (scrollTop) => {
      if (scrollTop >= UI_METRICS_IN_PX.mobileHeaderHeight) {
        setIsReachScrollOffset(true);
      } else {
        setIsReachScrollOffset(false);
      }
    },
    [isReachScrollOffset],
  );

  const { isKeepSearchCriteria, performShowPopup } = (location.state as {
    isKeepSearchCriteria?: boolean;
    performShowPopup?: boolean;
  }) || { isKeepSearchCriteria: false, performShowPopup: false };

  const isConfigurationRefreshOnReload = window.config?.isConfigurationRefreshOnReload;

  const isSpecialRetireeProfile = !!(profile && isRetireeSpecialProfile(profile));
  const isRetireeProfileWithRetireeSpecial = !!(profile && isRetiree(profile) && retireeSpecialProfile);

  useEffect(() => {
    sessionStorage.removeItem('searchResultCache');
  }, []);

  useEffect(() => {
    // homePageClearSliceData({
    //   dispatch,
    //   bookingState,
    //   authState,
    //   configurationState,
    //   isKeepSearchCriteria,
    //   setIsShowDefaultTravelTypeOption,
    // });

    // (1) Clear slice data
    if ((!isKeepSearchCriteria && !viewBookingApplicationId && !bookingStep) || isCppBookingCreated) {
      // Clear booking Slice data, including the CPP
      dispatch(resetBookingSlice());
    }
    dispatch(resetBookingUserSlice());
    dispatch(resetPaymentHistoryPage());
    dispatch(resetBookingFilterSlice());

    // (2) calling APIs
    if (isConfigurationRefreshOnReload) {
      // (2.1) get the airport and configuration on every entry to home screen
      dispatch(getAirportsThunk());
      dispatch(getConfigurationsThunk());
    } else {
      if (isEmpty(airports) || isEmpty(configurations)) {
        // (2.2) get the airport and configuration only when airport and configuration is empty
        dispatch(getAirportsThunk());
        dispatch(getConfigurationsThunk());
      }
    }
    dispatch(getProfileThunk(ern));

    // (3) Retrieve user mode
    retrieveUserModeStatus(ern, dispatch);

    // (4) clear the selected concession selection option
    setIsShowDefaultTravelTypeOption?.(false);

    // Reset isQuitCppFlow whatever reason
    // dispatch(setIsQuitCppFlow(false));
  }, [ern]);

  const resetRoute = () => {
    navigate(ROOT_PATHS.landing, { replace: true });
  };

  useEffect(() => {
    const isNotShowFlownSuspensionPopup = roleType === USER_ROLE.admin || roleType === USER_ROLE.delegation || false;
    if (isShowFlownSuspensionAlert && !isNotShowFlownSuspensionPopup) {
      dispatch(setIsOpenFlownSuspensionDialog(true));
    } else {
      dispatch(setIsOpenFlownSuspensionDialog(false));
    }
  }, [isShowFlownSuspensionAlert, role]);

  useEffect(() => {
    // (1) reset route
    resetRoute();

    // (2) add scroll event listener
    if (!showFlowView) {
      // (a) reset flag
      setIsReachScrollOffset(false);

      // (b) add event listener
      const { current } = scrollViewRef || {};

      const handleScroll = () => {
        if (current) {
          toggleSticky(current.scrollTop);
        }
      };

      if (current) {
        current.addEventListener('scroll', handleScroll);
      }

      return () => {
        current && current.removeEventListener('scroll', handleScroll);
      };
    }
  }, [showFlowView]);

  useEffect(() => {
    isDesktop && setShowFlowView(false);
    resetRoute();
  }, [isDesktop]);

  useEffect(() => {
    // if login user has 2 profiles(retiree + special), need to popup to select retiree or special
    // condition:
    // 1. retireeSpecialProfile?.employeeId -> check if has special profile
    // 2. isUndefined(isShowSelectProfileDialog) -> not select before
    if (retireeSpecialProfile?.employeeId && isUndefined(isShowSelectProfileDialog)) {
      dispatch(setIsShowSelectProfileDialog(true));
    }
  }, [retireeSpecialProfile]);

  useEffect(() => {
    if (isShowProfileIndicator) {
      dispatch(setErrorAlert(appSliceInitialState.errorAlert));
    }
  }, [isShowProfileIndicator]);

  //  Booking Flow Screen
  if (showFlowView) {
    return (
      <BookingFlowContainer
        variant={OVERLAY_VARIANT.SEARCH_CRITERIA}
        routePages={[
          {
            component: LocationSelection,
            title: en.home.whereAreYouFlyingTo,
          },
          {
            component: DateSelection,
            title: en.home.whenWillYouBeThere,
          },
          {
            component: ConcessionSelectionContainer,
            title: en.home.howTravel,
          },
        ]}
        handleClose={() => {
          setShowFlowView(false);
          resetRoute();
        }}
      />
    );
  }

  const isAdminRole = role.type === USER_ROLE.admin;
  const isSelfDelegationRole = [USER_ROLE.self, USER_ROLE.delegation]?.includes(role.type);

  //  Home Screen
  return (
    <Div100vhView
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setCurShowPop('');
      }}
    >
      <Box component={ScrollableView} ref={scrollViewRef}>
        <Grid container>
          <MobileView component={Grid} xs={12} isAdmin={isAdminRole}>
            <MobileHeader
              {...{
                isReachScrollOffset,
                isRetireeProfileWithRetireeSpecial,
                isSpecialRetireeProfile,
              }}
            />
            <MobileSearchCriteriaBox
              saveTo={SAVE_DATA_TO.redux}
              handleSearchClick={() => navigate(BOOKING_PATHS.booking)}
              customStyles={{
                mt: 1.5,
                mb: 4,
                mx: 2,
              }}
            />
          </MobileView>

          <DesktopView sx={{ width: 1 }} isAdmin={isAdminRole}>
            <DesktopHeader
              {...{
                isRetireeProfileWithRetireeSpecial,
                isSpecialRetireeProfile,
              }}
            />
            {isAdminRole ? (
              adminRoles.find((item) => item.id === role.id)?.access?.createBooking && (
                <SearchBar curShowPop={curShowPop} setCurShowPop={setCurShowPop} performShowPopup={performShowPopup} />
              )
            ) : (
              <SearchBar curShowPop={curShowPop} setCurShowPop={setCurShowPop} performShowPopup={performShowPopup} />
            )}
          </DesktopView>

          {isSelfDelegationRole && <UpComingBookingLayout scrollViewRef={scrollViewRef} />}
        </Grid>
        {isDesktop && isAdminRole && <AdminFeatureLayout />}
      </Box>
      {!isDesktop && !showFlowView && <BottomNavBar />}
    </Div100vhView>
  );
};

export default Home;
