import { useState } from 'react';
import { Box, Typography, TextField, Button, useTheme } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { FONT_WEIGHT } from '../../../constants/font';
import { AdminCloseIcon } from '../../../assets/images';

import { openUpNomination } from '../../../services/admin/employee';

import {
  getAdminCurrentContext,
  checkIsAssoSubsidAdminPage,
  getAdminCurrentProfileContextData,
  getAdminModulePopupContainerBreakpointStyle,
  getAdminModulePopupContentBreakpointStyle
} from '../../../helpers';

import { IAssoSubsidAdminEmployeeDetailRoot } from '../../../interfaces';

import { CancelDialog } from '../../../components';
import { AdminCheckbox } from '../..';

const OpenUpNominationPopup = ({
  setIsPopupOpenUpNomination,
  data,
}: {
  setIsPopupOpenUpNomination: (value: boolean) => void;
  data: {
    dependentName: string;
    relationShip: string;
    dependentId: string;
    nominationFrom: string;
    nominationTo: string;
    nominationType: string;
  };
}) => {
  const theme = useTheme();
  const BOX_WIDTH = '464px';

  const adminCurrentContext = getAdminCurrentContext();
  const isAssoSubsidAdminPage = checkIsAssoSubsidAdminPage();
  const adminProfileData = getAdminCurrentProfileContextData();
  const { employeeId: userId, revision: profileRevision } = adminProfileData;

  const { employeeDetail } = adminCurrentContext.currentContextState || {};
  const { setEmployeeDetail } = adminCurrentContext.currentContextAction || {};

  const { openUpNomination: openUpNominationLbl } = en.admin.feature.dependent;
  const [inputRemarks, setInputRemarks] = useState('');
  const [verifiedQuotaUsage, setVerifiedQuotaUsage] = useState<boolean | undefined>(undefined);
  const [isShowConfirmPopup, setIsShowConfirmPopup] = useState<boolean>(false);

  const isEnableConfirmButton = _.isBoolean(verifiedQuotaUsage);

  const boxLayoutStyles = {
    py: 3,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${theme.inactiveNomination.border}`,
  };

  const textFieldStyles = {
    width: BOX_WIDTH,
    '.MuiOutlinedInput-root': {
      height: '50px',
      borderRadius: 0.5,
      border: `1px solid ${theme.inactiveNomination.textBorder}`,
      background: theme.palette.primary.light,
      color: theme.color.secondary.dark_grey.option_3,
      px: 1.125,
      '.MuiInputBase-inputMultiline': {
        fontWeight: FONT_WEIGHT.medium,
        fontSize: theme.typography.body_2_medium.fontSize,
        color: theme.color.secondary.grey.option_5,
      },
    },
    '& .Mui-disabled': {
      background: theme.color.utility.unselectable.option_7,
    },
    'label, fieldset': { display: 'none' },
  };

  // call inactive nomination api
  const handleInactiveNominationAction = async () => {
    const result = await openUpNomination({
      isAssoSubsidAdminPage,
      userId,
      profileRevision,
      dependentId: data.dependentId,
      nominationFrom: data.nominationFrom,
      nominationTo: data.nominationTo,
      nominationType: data.nominationType,
      adminVerifiedQuotaUsage: !!verifiedQuotaUsage,
      remarks: inputRemarks,
    });

    if (result) {
      // Asso/Subsid Admin Maintain Employee Details page
      if (isAssoSubsidAdminPage) {
        setEmployeeDetail({
          ...employeeDetail,
          dependents: result.nominationList as IAssoSubsidAdminEmployeeDetailRoot['dependents'],
          nominationHistory: result.nominationHistory as IAssoSubsidAdminEmployeeDetailRoot['nominationHistory'],
        });
      } else {
        // CX Admin Maintain Employee Details page
        setEmployeeDetail({
          ...employeeDetail,
          dependents: result.nominationList,
          // outstandingBookings: result.outstandingBookings, // TODO: future for frontend display
          nominationHistory: result.nominationHistory,
        });
      }

      setIsShowConfirmPopup(false);
      setIsPopupOpenUpNomination(false);
    }
  };

  // handle checkbox change action
  const handleCheckboxOnChangeAction = (updatedValue: boolean) => {
    setVerifiedQuotaUsage(updatedValue === verifiedQuotaUsage ? undefined : updatedValue);
  };

  const nominationItems = [
    {
      className: 'open_up_nomination_dependent_name',
      label: openUpNominationLbl.dependantName,
      value: data.dependentName,
      type: 'input',
    },
    {
      className: 'open_up_nomination_relationship',
      label: openUpNominationLbl.relationship,
      value: data.relationShip,
      type: 'input',
    },
    {
      className: 'open_up_nomination_verified_quota_usage',
      label: openUpNominationLbl.verifiedQuotaUsage,
      type: 'checkbox',
    },
  ];

  const NominationRightLayout = (type: string, value?: string) => {
    switch (type) {
      case 'input':
        return <TextField disabled multiline value={value} sx={textFieldStyles} />;
      case 'checkbox':
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: BOX_WIDTH, mt: -1.25 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: -1 }}>
              <AdminCheckbox
                checked={verifiedQuotaUsage === true}
                onChange={() => {
                  handleCheckboxOnChangeAction(true);
                }}
              />
              <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_3}>
                {en.common.yes}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', ml: -1, mb: -1 }}>
              <AdminCheckbox
                checked={verifiedQuotaUsage === false}
                onChange={() => {
                  handleCheckboxOnChangeAction(false);
                }}
              />
              <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_3}>
                {en.common.no}
              </Typography>
            </Box>
          </Box>
        );
    }
  };

  return (
    <Box
      className="open_up_nomination_popup"
      sx={{
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'fixed',
        zIndex: 800,
        background: theme.backgroundColor.popup,
        // [ETP-6035]
        ...getAdminModulePopupContainerBreakpointStyle(theme),
      }}
    >
      <Box
        className="open_up_nomination_popup_content"
        sx={{
          width: '1024px',
          height: '618px',
          background: 'white',
          borderRadius: '16px',
          mx: 'auto',
          mt: 7,
          p: 5,
          color: theme.color.secondary.dark_grey.option_3,
          display: 'flex',
          flexDirection: 'column',
          // [ETP-6035]
          ...getAdminModulePopupContentBreakpointStyle(theme),
        }}
      >
        <Box
          className="open_up_nomination_popup_title"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            mb: 1.125,
            height: '36px',
          }}
        >
          <Typography variant="title_1_bold">{openUpNominationLbl.title}</Typography>

          <AdminCloseIcon
            style={{ cursor: 'pointer', height: '32px', width: '32px' }}
            onClick={() => setIsPopupOpenUpNomination(false)}
          />
        </Box>

        {nominationItems.map((item) => {
          return (
            <Box key={item.className} className={item.className} sx={boxLayoutStyles}>
              <Typography variant="headline_medium" sx={{ float: 'left' }}>
                {item.label}
              </Typography>

              {NominationRightLayout(item.type, item.value)}
            </Box>
          );
        })}

        <Box
          className="open_up_nomination_add_remarks"
          sx={{
            pt: 3,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            height: '106px',
          }}
        >
          <Typography variant="headline_medium" sx={{ float: 'left' }}>
            {openUpNominationLbl.remarks}
          </Typography>
          <TextField
            label="Multiline"
            multiline
            rows={2}
            value={inputRemarks}
            placeholder={openUpNominationLbl.addRemarks}
            inputProps={{ maxLength: 200 }}
            InputLabelProps={{ shrink: true }}
            sx={{
              float: 'right',
              width: '464px',
              '.MuiOutlinedInput-root': {
                height: '64px',
                borderRadius: 0.5,
                border: inputRemarks
                  ? `1px solid ${theme.color.secondary.dark_grey.option_3}`
                  : `1px solid ${theme.color.secondary.slate.option_3}`,
                background: theme.palette.primary.light,
                color: theme.color.secondary.dark_grey.option_1,
                py: 1.875,
                px: 1.125,
                '.MuiInputBase-inputMultiline': {
                  fontWeight: FONT_WEIGHT.medium,
                  color: theme.color.secondary.dark_grey.option_1,
                  fontSize: theme.typography.body_2_medium.fontSize,
                },
              },
              'label, fieldset': { display: 'none' },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInputRemarks(event.target.value);
            }}
          />
          <Typography
            variant="navigation_regular"
            sx={{
              mt: 0.5,
              width: '100%',
              textAlign: 'right',
              color: theme.color.secondary.grey.option_3,
              lineHeight: '22px',
            }}
          >
            {inputRemarks.length}
            {openUpNominationLbl.remarksLimitCharacters}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'right', mt: '40px' }}>
          <Button
            sx={{
              width: '200px',
              height: '48px',
              color: theme.color.utility.link.option_3,
              border: `2px solid ${theme.color.utility.link.option_3}`,
              '&: hover': {
                background: 'none',
                border: `2px solid ${theme.color.utility.link.option_3}`,
              },
            }}
            variant="outlined"
            onClick={() => setIsPopupOpenUpNomination(false)}
          >
            <Typography variant="body_1_bold" sx={{ lineHeight: 'initial' }}>
              {en.common.cancel}
            </Typography>
          </Button>
          <Button
            sx={{
              ml: 2,
              width: '200px',
              height: '48px',
              color: 'white',
              background: isEnableConfirmButton ? theme.status.selected : theme.inactiveNomination.background,
              '&: hover': {
                background: isEnableConfirmButton ? theme.status.selected : theme.inactiveNomination.background,
              },
            }}
            variant="text"
            onClick={() => {
              isEnableConfirmButton && setIsShowConfirmPopup(true);
            }}
          >
            <Typography variant="body_1_bold" sx={{ lineHeight: 'initial' }}>
              {en.common.confirm}
            </Typography>
          </Button>
        </Box>
      </Box>

      <CancelDialog
        open={isShowConfirmPopup}
        handleConfirmAction={handleInactiveNominationAction}
        handleCloseDialog={() => {
          setIsShowConfirmPopup(false);
        }}
        showCancelIcon={false}
        dialogContent={{
          title: openUpNominationLbl.confirmPopupTitle,
          message: openUpNominationLbl.confirmPopupMessage,
          noTitle: en.common.cancel,
          yesTitle: en.common.yesConfirm,
        }}
      />
    </Box>
  );
};

export default OpenUpNominationPopup;
