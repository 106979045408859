import { useContext, useState, useMemo } from 'react';
import { Box, useTheme, Typography, Checkbox, IconButton } from '@mui/material';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { Configuration } from 'configuration';

import en from '../../../../translations/en';
import { CheckBoxCheckIcon, CheckBoxIcon, BackIcon } from '../../../../assets/images';
import {
  FORM,
  INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
  DEPENDENT_STATUS_LIST,
} from '../../../../constants/constants';
import { FONT_WEIGHT } from '../../../../constants/font';

import { formatDMYDateWithSpace } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { NominationState, NominationAction } from '../../../../context';
import { selectApp } from '../../../../slice/appSlice';

import { ReduxFormSelectInput } from '../../../../components';
import TextInput from '../../../../components/InputSelect/TextInput';

const CompanionDependentStatusContainer = () => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const [activeOrInactive, setActiveOrInactive] = useState<string>(DEPENDENT_STATUS_LIST.active);

  const { setCompanionDependentStatusFlow } = useContext(NominationAction) || {};
  const { companionDependentStatusFlow } = useContext(NominationState) || {};

  const { editDependentStatusData } = companionDependentStatusFlow || {};

  const dependentLbl = en.userProfile.dependent;

  type objType = keyof typeof DEPENDENT_STATUS_LIST;

  interface IDependentStatusExpandedData {
    type: string;
    value?: string;
  }
  const expandedData: IDependentStatusExpandedData[] = [
    {
      type: dependentLbl.name,
      value: companionDependentStatusFlow.editDependentStatusData.displayName,
    },
    {
      type: dependentLbl.dependentDetails,
      value: '',
    },
    {
      type: dependentLbl.relationship,
      value: companionDependentStatusFlow.editDependentStatusData.relationship,
    },
    {
      type: dependentLbl.dateOfBirth,
      value: formatDMYDateWithSpace(companionDependentStatusFlow.editDependentStatusData.dateOfBirth),
    },
    {
      type: dependentLbl.dependentStatus,
      value: '',
    },
    {
      type: dependentLbl.nominationDateAndType,
      value: companionDependentStatusFlow.editDependentStatusData.nominationDetails,
    },
  ];

  const textInputStyle = {
    width: '80%',
    mt: '16px',
    '& label.Mui-focused': {
      color: theme.color.utility.link.option_3,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px,solid,${theme.color.utility.link.option_3}`,
      },
    },
  };

  const dependentStatusOptions = useMemo(() => {
    const dependentStatusOptions: Configuration.CodeLabel[] = [];
    for (const index in DEPENDENT_STATUS_LIST) {
      dependentStatusOptions.push({
        label: DEPENDENT_STATUS_LIST[index as objType],
        code: DEPENDENT_STATUS_LIST[index as objType],
        category: index,
      });
    }
    return dependentStatusOptions.reverse();
  }, [DEPENDENT_STATUS_LIST]);

  return (
    <Box className="companion_dependent_status_container">
      <Box sx={{ px: 0, textAlign: 'left' }}>
        {!isDesktop && (
          <IconButton
            onClick={() => {
              setCompanionDependentStatusFlow({
                ...companionDependentStatusFlow,
                editDependentStatusData: INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
              });
            }}
            sx={{ py: 1.625, pl: 0 }}
          >
            <BackIcon fill={theme.color.utility.link.option_3} />
          </IconButton>
        )}
        <Typography
          variant="navigation_regular"
          sx={{
            mt: 0.5,
            width: '100%',
            textAlign: 'left',
            fontSize: '18px',
            color: theme.color.secondary.dark_grey.option_1,
            fontWeight: FONT_WEIGHT.medium,
          }}
        >
          {en.userProfile.nomination.inactivatedDependentStatusDetails.title}
        </Typography>
        <Box
          sx={{
            mt: 3,
            pt: isDesktop ? 1 : 0.5,
            background: 'white',
            pl: 2,
            borderRadius: '10px',
            boxShadow: '5px 5px 10px #0000001A',
            pb: 2,
          }}
        >
          {expandedData.map((item: IDependentStatusExpandedData, index: number) => {
            return (
              <>
                {item.type !== dependentLbl.dependentDetails && item.type !== dependentLbl.dependentStatus && (
                  <Box key={index} sx={{ mt: 1.5 }}>
                    <Typography
                      variant="navigation_regular"
                      sx={{
                        mt: 0.5,
                        width: '100%',
                        textAlign: 'left',
                        fontSize: '12px',
                        color: theme.color.secondary.grey.option_3,
                        fontWeight: FONT_WEIGHT.regular,
                      }}
                    >
                      {item.type}
                    </Typography>
                    <Typography
                      variant="navigation_regular"
                      sx={{
                        width: '100%',
                        textAlign: 'left',
                        fontSize: '16px',
                        color: theme.color.secondary.dark_grey.option_3,
                        lineHeight: '24px',
                        fontWeight: FONT_WEIGHT.medium,
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                )}
                {item.type === dependentLbl.dependentStatus && (
                  <Box key={index} sx={{ mt: 1.5 }}>
                    <ReduxFormSelectInput
                      input={{
                        value: activeOrInactive,
                        onChange: (input: Configuration.CodeLabel) => {
                          setActiveOrInactive(input.code);
                          if (input.code === DEPENDENT_STATUS_LIST.active) {
                            setCompanionDependentStatusFlow({
                              editDependentStatusData: {
                                ...companionDependentStatusFlow.editDependentStatusData,
                                reason: '',
                                isAgreeToInactivatedTheDependent: false,
                              },
                            });
                          }
                        },
                      }}
                      title={dependentLbl.dependentStatus}
                      options={dependentStatusOptions}
                      autocomplete={{
                        customOpen: true,
                      }}
                      customInputStyle={{
                        width: '90%',
                        mt: 0,
                        mr: 2,
                      }}
                    />
                  </Box>
                )}
                {item.type === dependentLbl.dependentDetails && (
                  <Box key={index} sx={{ mt: 1.5 }}>
                    <Typography
                      variant="navigation_regular"
                      sx={{
                        mt: 0.5,
                        width: '100%',
                        textAlign: 'left',
                        fontSize: '14px',
                        color: theme.color.secondary.dark_grey.option_3,
                        fontWeight: FONT_WEIGHT.bold,
                      }}
                    >
                      {item.type}
                    </Typography>
                  </Box>
                )}
              </>
            );
          })}
        </Box>
        {activeOrInactive === DEPENDENT_STATUS_LIST.inactive && (
          <Box>
            <Box
              className="field_row_item"
              sx={{
                mt: 3,
                pt: 1,
                background: 'white',
                pl: 2,
                borderRadius: '10px',
                boxShadow: '5px 5px 10px #0000001A',
                pb: 2,
              }}
            >
              <Typography
                variant="navigation_regular"
                sx={{
                  mt: 0.5,
                  pt: isDesktop ? 2 : 1,
                  width: '100%',
                  textAlign: 'left',
                  fontSize: '16px',
                  color: theme.color.secondary.dark_grey.option_1,
                  fontWeight: FONT_WEIGHT.regular,
                }}
              >
                {en.userProfile.nomination.inactivatedDependentStatusDetails.inactivatedLine1}
              </Typography>
              {isDesktop && (
                <Box>
                  <Typography
                    variant="navigation_regular"
                    sx={{
                      mt: 4,
                      width: '100%',
                      textAlign: 'left',
                      fontSize: '16px',
                      color: theme.color.secondary.dark_grey.option_1,
                      fontWeight: FONT_WEIGHT.regular,
                      pr: 0,
                    }}
                  >
                    {en.userProfile.nomination.inactivatedDependentStatusDetails.inactivatedLine2}
                  </Typography>
                  <Typography
                    variant="navigation_regular"
                    sx={{
                      mt: 0.5,
                      width: '100%',
                      textAlign: 'left',
                      fontSize: '16px',
                      color: theme.color.secondary.dark_grey.option_1,
                      fontWeight: FONT_WEIGHT.regular,
                      pr: 0,
                    }}
                  >
                    {en.userProfile.nomination.inactivatedDependentStatusDetails.inactivatedLine3}
                  </Typography>
                </Box>
              )}
              {!isDesktop && (
                <Box>
                  <Typography
                    variant="navigation_regular"
                    sx={{
                      mt: 3.5,
                      width: '100%',
                      textAlign: 'left',
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: theme.color.secondary.dark_grey.option_1,
                      fontWeight: FONT_WEIGHT.regular,
                      pr: 2,
                    }}
                  >
                    {en.userProfile.nomination.inactivatedDependentStatusDetails.inactivatedLine2}{' '}
                    {en.userProfile.nomination.inactivatedDependentStatusDetails.inactivatedLine3}
                  </Typography>
                </Box>
              )}
              <TextInput
                value={editDependentStatusData?.reason}
                placeholder={en.userProfile.nomination.inactivatedDependentStatusDetails.textInputArea}
                sx={textInputStyle}
                InputProps={{
                  style: {
                    borderRadius: 4,
                    height: '72px',
                    width: isDesktop ? '456px' : '120%',
                  },
                }}
                inputProps={{ maxLength: 50 }}
                onChange={(event: any) => {
                  setCompanionDependentStatusFlow({
                    editDependentStatusData: {
                      ...companionDependentStatusFlow.editDependentStatusData,
                      reason: event.target.value,
                    },
                  });
                }}
              />
              <Typography
                variant="navigation_regular"
                sx={{
                  mt: 0.5,
                  width: '100%',
                  textAlign: 'right',
                  color: theme.color.secondary.grey.option_3,
                  pr: '16px',
                }}
              >
                {editDependentStatusData?.reason?.length || 0}
                {en.admin.notes.inActivateDependentReasonLimitCharacters}
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 3,
                pt: 2,
                background: 'white',
                borderRadius: '10px',
                boxShadow: '5px 5px 10px #0000001A',
                pb: 2,
                pl: 1,
                pr: 1.5,
              }}
            >
              <Checkbox
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: '#2196F3',
                  },
                }}
                checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                icon={<CheckBoxIcon stroke={theme.status.selected} />}
                checked={companionDependentStatusFlow.editDependentStatusData.isAgreeToInactivatedTheDependent}
                onChange={() => {
                  setCompanionDependentStatusFlow({
                    editDependentStatusData: {
                      ...companionDependentStatusFlow.editDependentStatusData,
                      isAgreeToInactivatedTheDependent:
                        !companionDependentStatusFlow.editDependentStatusData.isAgreeToInactivatedTheDependent,
                    },
                  });
                }}
              />
              <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_3}>
                {en.userProfile.nomination.inactivatedDependentStatusDetails.inactivatedDependentStatus}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const currentForm = FORM.companionDependentStatus;

const editCompanionDependentStatusForm = reduxForm({
  form: currentForm,
  initialValues: {},
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CompanionDependentStatusContainer);

export const ConnectedCompanionDependentStatusForm = connect()(editCompanionDependentStatusForm);
