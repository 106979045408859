import React, { useState } from 'react';
import { Box, Checkbox, Dialog, DialogActions, DialogContent, Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { CheckBoxCheckIcon, CheckBoxIcon } from '../../../assets/images';

import { isAssoSubsid } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectUser, setEditContactPassportDetails } from '../../../slice/userSlice';

import { FormButton } from '../../../components';

// edit contact/passport confirm popup dialog
// edit contact: retiree/widow user can access
// edit passport: retiree/widow/subs/asso users can access
const EditContactPassportDetailsDialog = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [isAgreeToUpdateInformationChecked, setIsAgreeToUpdateInformationChecked] = useState<boolean>(false);
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile, editContactPassportDetails } = useAppSelector(selectUser) || {};
  const { isOpenContactDetails, isOpenPassportDetails } = editContactPassportDetails || {};

  const isEditContactDetails = !!isOpenContactDetails;

  const { updateContactDetailDialogTitle, updateContactNoticeMessage } = en.userProfile.personal.contactInfo;
  const { updatePassportDetailDialogTitle, retireeUpdatePassportNoticeMessage, assoSubsidUpdatePassportNoticeMessage } =
    en.userProfile.personal.passportInfo;

  const messageContents = {
    title: isEditContactDetails ? updateContactDetailDialogTitle : updatePassportDetailDialogTitle,
    notices: isEditContactDetails
      ? updateContactNoticeMessage
      : isAssoSubsid(profile) // edit passport: asso-subsid/retiree has different copywriting
      ? assoSubsidUpdatePassportNoticeMessage
      : retireeUpdatePassportNoticeMessage,
  };

  const handleCloseDialogAction = () => {
    // close dialog
    dispatch(
      setEditContactPassportDetails({
        ...editContactPassportDetails,
        ...(isEditContactDetails
          ? {
              isOpenContactDetails: false,
            }
          : {
              isOpenPassportDetails: false,
            }),
      }),
    );
    setIsAgreeToUpdateInformationChecked(false);
  };

  const handleConfirmDialogAction = () => {
    // click "confirm" action
    dispatch(
      setEditContactPassportDetails({
        ...editContactPassportDetails,
        ...(isEditContactDetails
          ? {
              isOpenContactDetails: false,
              isConfirmContactDetails: true, // flag to open "update contact details" dialog
            }
          : {
              isOpenPassportDetails: false,
              isConfirmPassportDetails: true, // flag to open "update passport details" dialog
            }),
      }),
    );
    setIsAgreeToUpdateInformationChecked(false);
  };

  return (
    <Dialog
      className="edit_contact_passport_dialog_container"
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'baseline',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: isDesktop ? '478px' : '343px',
            borderRadius: 2,
            m: 0,
            px: isDesktop ? 4.5 : 3,
            py: 2,
            top: '22%',
            overflowX: 'hidden',
            boxShadow: theme.boxShadow.important,
          },
        },
      }}
      open={!!isOpenContactDetails || !!isOpenPassportDetails}
    >
      <Box
        className="edit_contact_passport_message_container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="title_1_bold"
          sx={{
            px: 0,
            py: 2,
            color: theme.palette.text.secondary,
          }}
        >
          {messageContents.title}
        </Typography>
        <DialogContent sx={{ px: 0, pt: 1, pb: 2 }}>
          <Typography
            className="edit_contact_passport_disclaimer_label"
            variant="body_1_regular"
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            <Box
              className="edit_contact_passport_disclaimer_container"
              sx={{ my: 1, display: 'flex', flexDirection: 'row' }}
            >
              <Box sx={{ mt: -1, ml: -1 }}>
                <Checkbox
                  sx={{
                    '&.MuiCheckbox-root': {
                      color: theme.status.selected,
                    },
                  }}
                  checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                  icon={<CheckBoxIcon stroke={theme.status.selected} />}
                  color="info"
                  checked={isAgreeToUpdateInformationChecked}
                  onChange={() => setIsAgreeToUpdateInformationChecked(!isAgreeToUpdateInformationChecked)}
                />
              </Box>

              <Typography
                variant="body_2_regular"
                sx={{
                  color: theme.color.secondary.dark_grey.option_1,
                }}
              >
                {messageContents.notices}
              </Typography>
            </Box>
          </Typography>
        </DialogContent>
      </Box>

      <DialogActions
        className="edit_contact_passport_action_container"
        sx={{
          p: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormButton
          theme={theme}
          colour="outline"
          variant="contained"
          color="success"
          size="large"
          customStyles={{
            borderRadius: 8,
            width: isDesktop ? '195px' : '140px',
            height: '44px',
            '&:hover': {
              backgroundColor: 'white',
              boxShadow: 'none',
            },
          }}
          onClick={handleCloseDialogAction}
          sx={{
            ml: 0,
          }}
        >
          {en.common.cancel}
        </FormButton>

        <FormButton
          theme={theme}
          colour="green"
          variant="contained"
          color="success"
          size="large"
          customStyles={{
            borderRadius: 8,
            width: isDesktop ? '195px' : '140px',
            height: '44px',
          }}
          onClick={handleConfirmDialogAction}
          disabled={!isAgreeToUpdateInformationChecked}
          sx={{
            ml: 0,
          }}
        >
          {en.common.confirm}
        </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditContactPassportDetailsDialog;
