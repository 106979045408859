import { useState, useCallback, useMemo, useEffect } from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';

import en from '../../../translations/en';
import { BackIcon } from '../../../assets/images';
import { FORM, REGEX } from '../../../constants/constants';

import { IInformationListItem } from '../../../containers/UserProfile/Common/InformationList';

import { getDisplayVal, isRetireeWidow, validateRegex } from '../../../helpers';

import { RootState } from '../../../app/store';
import { selectApp, setSnackBarData } from '../../../slice/appSlice';
import { selectAuth } from '../../../slice/authSlice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectUser, setEditContactPassportDetails } from '../../../slice/userSlice';
import { editContactDetail } from '../../../services/user';

import { ReduxFormTextInput, Footer } from '../../../components';
import { ParagraphMasterContainer } from '../../../containers';

interface IContactInformation {
  personalEmail: string | null;
  mobilePhone: string | null;
}

const EditContactDetail = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { ern: ernFromLogin } = useAppSelector(selectAuth) || {};
  const { profile, editContactPassportDetails } = useAppSelector(selectUser) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { personalEmail, businessEmail, mobilePhone, workPhone } = profile?.personalInfo || {};
  const {
    companyEmail: companyEmailLbl,
    personalEmail: personalEmailLbl,
    officePhoneNumber: officePhoneNumberLbl,
    mobilePhoneNumber: mobilePhoneNumberLbl,
    contactDetails: contactDetailsLbl,
    editSuccessContactInformationMessage,
    multipleMobilePhoneNumberEditMessage,
  } = en.userProfile.personal.contactInfo;

  const [contactInformation, setContactInformation] = useState<IContactInformation>({
    personalEmail: personalEmail as string | null,
    mobilePhone: mobilePhone as string | null,
  });

  // check edit fields: only for retiree/widow, but not for retiree special profile
  // case 1.retiree/widow: display 2 fields: personalEmail+mobilePhone
  // case 2.employee: display 4 fields:  businessEmail+personalEmail+workPhone+mobilePhone
  // case 3.retiree special profile cannot edit, won't enter this page.
  const isRetireeWidowProfile = profile && isRetireeWidow(profile);

  const contactDetailList: IInformationListItem[] = useMemo(
    () => [
      ...(!isRetireeWidowProfile
        ? [
            {
              key: companyEmailLbl,
              value: getDisplayVal(businessEmail),
              isReadOnly: true,
            },
          ]
        : []),
      {
        key: personalEmailLbl,
        value: contactInformation.personalEmail,
        isValidEmail: true,
      },
      ...(!isRetireeWidowProfile
        ? [
            {
              key: officePhoneNumberLbl,
              value: getDisplayVal(workPhone),
              isReadOnly: true,
            },
          ]
        : []),

      {
        key: mobilePhoneNumberLbl,
        value: contactInformation.mobilePhone,
        isPhoneNum: true,
      },
    ],
    [businessEmail, contactInformation, workPhone],
  );

  const handleInputChange = useCallback((key: string, input: string | null) => {
    setContactInformation((prevState) => ({
      ...prevState,
      [key === personalEmailLbl ? 'personalEmail' : 'mobilePhone']: input,
    }));
  }, []);

  useEffect(() => {
    // etp-6013 add: retiree/widow users click "update contact details" -> "confirm" update contact details
    // call "editContactDetail" api, and reset "isConfirmContactDetails" to false to reset flag.
    if (editContactPassportDetails?.isConfirmContactDetails) {
      editContactDetailsAction();
      dispatch(
        setEditContactPassportDetails({
          ...editContactPassportDetails,
          isConfirmContactDetails: false,
        }),
      );
    }
  }, [editContactPassportDetails]);

  const onSaveContactDetails = () => {
    if (isRetireeWidowProfile) {
      // retiree/widow profile click "save", will popup confirm dialog
      dispatch(
        setEditContactPassportDetails({
          ...editContactPassportDetails,
          isOpenContactDetails: true,
        }),
      );
    } else {
      editContactDetailsAction();
    }
  };

  const editContactDetailsAction = useCallback(async () => {
    const personalInfo = {
      personalEmail: contactInformation.personalEmail,
      mobilePhone: contactInformation.mobilePhone,
    };

    const result = await editContactDetail(ernFromLogin, personalInfo);

    if (result) {
      dispatch(
        setSnackBarData({
          message: editSuccessContactInformationMessage,
          isShow: true,
          delay: 500,
          customStyles: { mb: isDesktop ? 1 : 2 },
        }),
      );

      navigate(-1);
    }
  }, [contactInformation]);

  const isFooterValid =
    !_.isEmpty(contactInformation.personalEmail) &&
    !_.isEmpty(contactInformation.mobilePhone) &&
    !!validateRegex(contactInformation.personalEmail as string, REGEX.email);

  return (
    <Box sx={{ ...(isDesktop && { width: '488px' }) }}>
      {!isDesktop && (
        <IconButton onClick={() => navigate(-1)} sx={{ py: 1.625, pl: 0 }}>
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}
      <Typography
        variant="headline_medium"
        sx={{
          mt: 2.125,
          mb: 2,
          color: theme.color.secondary.dark_grey.option_1,
        }}
      >
        {contactDetailsLbl}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {contactDetailList.map(({ key, value, isValidEmail, isReadOnly, isPhoneNum }) => (
          <div key={key}>
            <ReduxFormTextInput
              input={{
                value: (isValidEmail || isPhoneNum) && value === en.common.empty ? null : value,
                onChange: (input: string | null) => handleInputChange(key, input),
              }}
              title={key}
              isPhoneNum={isPhoneNum}
              isReadOnly={isReadOnly}
              meta={{
                touched: isValidEmail && !validateRegex(value as string, REGEX.email),
                error:
                  isValidEmail && value && !validateRegex(value as string, REGEX.email) ? en.error.invalidEmail : null,
              }}
            />
            {isPhoneNum && (
              <ParagraphMasterContainer
                content={multipleMobilePhoneNumberEditMessage}
                multiParagraphContainerSxProps={{ p: 0, my: 0 }}
                paragraphSxProps={{
                  pb: '4px',
                }}
                paragraphVariant="body_2_regular"
                paragraphTypoSxProps={{
                  fontSize: '0.6875rem',
                  color: theme.color.secondary.dark_grey.option_3,
                }}
              />
            )}
          </div>
        ))}
      </Box>

      {isFooterValid && (
        <Footer
          primaryBtn={{
            isFullWidth: !isDesktop,
            text: en.common.save,
            customButtonStyles: { width: '200px' },
            customOnClick: onSaveContactDetails,
          }}
          customStyles={{
            position: 'fixed',
            left: 0,
            bottom: 0,
            pr: isDesktop ? 24.375 : 2,
          }}
        />
      )}
    </Box>
  );
};

const form = reduxForm({
  form: FORM.editContactDetail,
  enableReinitialize: true,
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(EditContactDetail);

const connectForm = connect((state: RootState) => ({
  initialValues: state.user.delegatee,
  formValues: getFormValues(FORM.editContactDetail)(state),
}))(form);

connectForm.displayName = FORM.editContactDetail;

export default connectForm;
