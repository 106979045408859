import React, { useState } from 'react';
import { Box, Typography, Autocomplete, TextField, Button, useTheme } from '@mui/material';

import { CloseIcon, DownArrowInheritIcon } from '../../../assets/images';

import en from '../../../translations/en';
import { ADMIN_PERSONALINFO_SELECT_TYPE, UI_STYLES } from '../../../constants/constants';
import { FONT_WEIGHT } from '../../../constants/font';
import { IAdminFlownSuspension } from '../../../interfaces';

import {
  checkValidDateRange, compareDate, formatDMYDateWithSlash, getLocalDate,
  getAdminModulePopupContainerBreakpointStyle,
  getAdminModulePopupContentBreakpointStyle
} from '../../../helpers';

import { InputFieldDatePicker } from '../../../components';

const AdminFlownSuspensionPopup = ({
  setEditFlownSuspensionId,
  data,
  handleConfirmAction,
}: {
  setEditFlownSuspensionId: (value: string) => void;
  data: IAdminFlownSuspension;
  handleConfirmAction: (flownSuspensionId: string, startDate: string, endDate: string) => void;
}) => {
  const theme = useTheme();
  const [flownSuspensionStartDate, setFlownSuspensionStartDate] = useState<string>(
    data?.setFlownSuspensionStartDate ?? data?.flownSuspensionStartDate,
  );
  const [flownSuspensionEndDate, setFlownSuspensionEndDate] = useState<string>(
    data?.setFlownSuspensionEndDate ?? data?.flownSuspensionEndDate,
  );

  const { editProfilePopup } = en.admin.feature.personalInformation.profileDetails;

  const [isNotValidDateRange, setIsNotValidDateRange] = useState<boolean>(false);

  const BOX_WIDTH = '464px';

  const { flownSuspensionStatusOptions } = en.admin.feature;
  const { flownSuspensionDetails } = en.admin.feature.personalInformation;

  const handleUpdateValue = (selectType: string, value: string) => {
    if (selectType === ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionStartDate) {
      setFlownSuspensionStartDate(value);
    } else {
      setFlownSuspensionEndDate(value);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'fixed',
        zIndex: 800,
        background: theme.backgroundColor.popup,
        // [ETP-6035]
        ...getAdminModulePopupContainerBreakpointStyle(theme),
      }}
    >
      <Box
        sx={{
          width: '1024px',
          height: '615px',
          background: 'white',
          borderRadius: '16px',
          mx: 'auto',
          mt: 7,
          p: 5,
          color: theme.color.secondary.dark_grey.option_3,
          display: 'flex',
          flexDirection: 'column',
          // [ETP-6035]
          ...getAdminModulePopupContentBreakpointStyle(theme),
        }}
      >
        <Box
          className="flown_suspension_popup_bar"
          sx={{
            width: '100%',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="title_1_bold">{flownSuspensionDetails.editFlownSuspensionRecord}</Typography>
          <CloseIcon
            fill={theme.color.secondary.dark_grey.option_3}
            style={{ cursor: 'pointer' }}
            onClick={() => setEditFlownSuspensionId('')}
          />
        </Box>
        <Box
          sx={{
            py: 3,
            flex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            borderBottom: `1px solid #D8D8D8`,
          }}
        >
          <Typography variant="headline_medium" sx={{ float: 'left' }}>
            {flownSuspensionDetails.flownSuspensionStartDate}
          </Typography>
          <Box sx={{ width: BOX_WIDTH }}>
            <InputFieldDatePicker
              selectedType={ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionStartDate}
              dateFieldProps={{
                defaultDate: formatDMYDateWithSlash(flownSuspensionStartDate),
                errorMessage: '',
                disabled: compareDate(getLocalDate(), data?.flownSuspensionStartDate) > 0,
              }}
              customStyles={{
                height: '48px',
                mt: -5,
              }}
              setSelectedValue={handleUpdateValue}
            />
          </Box>
        </Box>

        <Box
          sx={{
            py: 3,
            flex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            borderBottom: `1px solid #D8D8D8`,
            height: '112px',
          }}
        >
          <Typography variant="headline_medium" sx={{ float: 'left' }}>
            {flownSuspensionDetails.flownSuspensionEndDate}
          </Typography>
          <Box sx={{ width: BOX_WIDTH }}>
            <InputFieldDatePicker
              selectedType={ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspensionEndDate}
              dateFieldProps={{
                defaultDate: formatDMYDateWithSlash(flownSuspensionEndDate),
                errorMessage: isNotValidDateRange ? editProfilePopup.inputValidDateRange : '',
                disabled: compareDate(getLocalDate(), data?.flownSuspensionEndDate) > 0,
              }}
              customStyles={{
                height: '48px',
                mt: -5,
              }}
              setSelectedValue={handleUpdateValue}
            />
          </Box>
        </Box>
        <Box
          className="flown_suspension_reason_description"
          sx={{
            py: 3,
            flex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            borderBottom: `1px solid #D8D8D8`,
          }}
        >
          <Typography variant="headline_medium" sx={{ float: 'left' }}>
            {flownSuspensionDetails.reason}
          </Typography>
          <TextField
            disabled
            multiline
            value={data?.reason}
            sx={{
              width: BOX_WIDTH,
              height: '46px',
              '.MuiOutlinedInput-root': {
                height: '46px',
                borderRadius: 0.5,
                border: `1px solid ${theme.color.secondary.slate.option_3}`,
                background: theme.palette.primary.light,
                color: theme.color.secondary.dark_grey.option_3,
                px: 1.125,
                '.MuiInputBase-inputMultiline': {
                  fontWeight: FONT_WEIGHT.medium,
                  fontSize: theme.typography.body_2_medium.fontSize,
                },
              },
              '& .Mui-disabled': {
                background: theme.color.utility.unselectable.option_7,
              },
              'label, fieldset': { display: 'none' },
            }}
          />
        </Box>
        <Box
          sx={{
            py: 3,
            width: '100%',
            height: '96px',
          }}
        >
          <Typography variant="headline_medium" sx={{ float: 'left' }}>
            {flownSuspensionDetails.status}
          </Typography>

          <Autocomplete
            disabled
            options={flownSuspensionStatusOptions}
            value={{ code: data?.status, label: data?.status }}
            popupIcon={<DownArrowInheritIcon fill={theme.color.secondary.dark_grey.option_3} />}
            clearIcon={null}
            disablePortal
            sx={{
              width: BOX_WIDTH,
              float: 'right',
              '.MuiFilledInput-root': {
                height: UI_STYLES.concessionTravellerHeight,
                borderRadius: 0.5,
                border: `1px solid ${theme.color.secondary.slate.option_3}`,
                background: theme.palette.primary.light,
                color: theme.color.secondary.dark_grey.option_3,
                paddingTop: '0px',
                '&.Mui-disabled': {
                  background: theme.color.utility.unselectable.option_7,
                },
                '&: after, &: before': {
                  display: 'none',
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  height: UI_STYLES.concessionTravellerHeight,
                  lineHeight: '44px',
                  '& .MuiFilledInput-input': {
                    pt: '0px !important',
                    mt: '7px !important',
                  },
                }}
                variant="filled"
                {...params}
              />
            )}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
          <Button
            sx={{
              width: '200px',
              height: '48px',
              color: theme.color.utility.link.option_3,
              border: `2px solid ${theme.color.utility.link.option_3}`,
              '&: hover': {
                background: 'none',
                border: `2px solid ${theme.color.utility.link.option_3}`,
              },
            }}
            variant="outlined"
            onClick={() => setEditFlownSuspensionId('')}
          >
            <Typography variant="body_1_bold" sx={{ lineHeight: 'initial' }}>
              {en.common.cancel}
            </Typography>
          </Button>
          <Button
            sx={{
              ml: 2,
              width: '200px',
              height: '48px',
              color: 'white',
              background: theme.status.selected,
              '&: hover': {
                background: theme.status.selected,
              },
            }}
            variant="outlined"
            onClick={() => {
              if (!checkValidDateRange(flownSuspensionStartDate, flownSuspensionEndDate)) {
                setIsNotValidDateRange(true);
              } else {
                handleConfirmAction(data.flownSuspensionId, flownSuspensionStartDate, flownSuspensionEndDate);
                setEditFlownSuspensionId('');
              }
            }}
          >
            <Typography variant="body_1_bold" sx={{ lineHeight: 'initial' }}>
              {en.common.confirm}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminFlownSuspensionPopup;
