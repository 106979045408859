import { useContext } from 'react';
import { Box } from '@mui/material';

import en from '../../../../translations/en';

import { getPHubDependentDisplayName } from '../../../../helpers';

import { NominationState } from '../../../../context';
import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';

import { NominationPageTitle, ParagraphMasterContainer, NominationDependentList } from '../../..';

const CompanionCompletedContainer = () => {
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { companionRegistrationFlow } = useContext(NominationState) || {};
  const { companionRegistrationData } = companionRegistrationFlow || {};
  const { companionPassport, companionInfo } = companionRegistrationData || {};

  const companionRegistrationLbl = en.userProfile.nomination.companionRegistration;
  const completedLbl = companionRegistrationLbl.common.completed;
  const commonLbl = en.userProfile.nomination.common;

  const dependentListData = [
    {
      dependentName: getPHubDependentDisplayName({ passportInfo: companionPassport, personalInfo: companionInfo }),
      status: commonLbl.nominationDependentStatus.registered,
    },
  ];
  return (
    <>
      <Box className="companion_completed_container">
        <Box
          sx={{
            width: isDesktop ? '508px' : '100%',
            height: '100%',
            mb: 3,
            mx: 'auto',
            ...(isDesktop && { mt: 9 }),
          }}
        >
          <Box
            sx={{
              ...(isDesktop && { px: 1.25 }),
            }}
          >
            <NominationPageTitle title={completedLbl.title} />
            <ParagraphMasterContainer content={completedLbl.content} paragraphVariant="body_1_regular" />
          </Box>
          <NominationDependentList
            dependentList={dependentListData}
            mainTitle={companionRegistrationLbl.common.personalDetails.fields.travelCompanion}
          />
        </Box>
      </Box>
    </>
  );
};

export default CompanionCompletedContainer;
