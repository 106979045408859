import React, { useState } from 'react';
import { Grid, Box, Typography, useTheme, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ADMIN_BOOKING_SEARCH_TYPE, UI_STYLES, USER_ROLE } from '../../constants/constants';
import { COMMON_API } from '../../constants/api';
import en from '../../translations/en';
import { NavBarSearchIcon, OverlayBackground } from '../../assets/images';

import { Frontend } from 'booking';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { selectUser } from '../../slice/userSlice';
import { selectAuth } from '../../slice/authSlice';
import { selectBookingFilter } from '../../slice/bookingFilterSlice';
import { selectBooking, setIsShowMyBookingFilterDialog } from '../../slice/bookingSlice';
import { EditBookingFilterContextProvider } from '../../context/BookingFilterContext';

import { searchBookingByRecLoc, searchBookingByErn } from '../../services/admin/booking';

import { Header, DesktopView, MobileView, StyledOutlinedInput, DrawerOverlay } from '../../components';
import {
  RoleIndicatorBar,
  MyBookings,
  AdminBookings,
  SearchFilterIconWithCount,
  ViewBookingFilter,
} from '../../containers';

const SearchBarComponent = ({
  ernOrBookingReference,
  setErnOrBookingReference,
  handleErnOrBookingReferenceAction,
}: {
  ernOrBookingReference: string;
  setErnOrBookingReference: any;
  handleErnOrBookingReferenceAction: (event: any) => void;
}) => {
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { role } = useAppSelector(selectUser) || {};
  const isAdminRole = role.type === USER_ROLE.admin;
  const theme = useTheme();
  const maxLength = isAdminRole ? undefined : 6;
  return (
    <StyledOutlinedInput
      notched={false}
      inputProps={{ maxLength }}
      placeholder={isAdminRole ? en.booking.travelType.searchByErnOrBooking : en.booking.travelType.searchByBooking}
      value={ernOrBookingReference}
      onChange={(event: any) => {
        setErnOrBookingReference(event.target.value.toUpperCase());
      }}
      onKeyPress={(event: any) => {
        event.key === 'Enter' && handleErnOrBookingReferenceAction(event);
      }}
      sx={{
        borderRadius: 2.5,
        height: '40px',
        ...(isDesktop && {
          width: '25vw',
          minWidth: '346px',
          mt: 1,
        }),
        ...(!isDesktop && {
          width: '100%',
          my: 'auto',
          mr: 2,
        }),
        background: theme.color.secondary.light_slate.option_5,
        '& .MuiInputBase-input': {
          color: theme.color.secondary.dark_grey.option_4,
          textTransform: ernOrBookingReference.length > 0 ? 'uppercase' : 'none',
        },
      }}
      startAdornment={
        <InputAdornment
          position="start"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <NavBarSearchIcon fill={theme.color.utility.link.option_3} style={{ marginLeft: '8px' }} />
        </InputAdornment>
      }
      endAdornment={
        ernOrBookingReference.length >= 6 && (
          <InputAdornment position="end">
            <IconButton
              sx={{
                p: 0,
                mr: -1,
              }}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                handleErnOrBookingReferenceAction(event);
              }}
            >
              <Typography
                variant="navigation_bold"
                sx={{
                  width: '56px',
                  height: '32px',
                  lineHeight: '32px',
                  background: theme.color.utility.link.option_3,
                  borderRadius: 3,
                  color: theme.palette.bgColor.main,
                  textAlign: 'center',
                }}
              >
                {en.booking.travelType.go}
              </Typography>
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

const LeftHeaderView = ({ headerProps }: { headerProps: any }) => {
  const navigate = useNavigate();
  return (
    <Header
      iconColor={headerProps.iconColor}
      leftChild={
        <Typography
          variant={headerProps.variant}
          color={headerProps.textColor}
          sx={{ ml: 1, cursor: 'pointer' }}
          onClick={() => navigate(-1)}
        >
          {headerProps.title}
        </Typography>
      }
      handleOnBack={() => navigate(-1)}
    />
  );
};

const HeaderComponent = ({
  ernOrBookingReference,
  setErnOrBookingReference,
  handleErnOrBookingReferenceAction,
}: {
  ernOrBookingReference: string;
  setErnOrBookingReference: any;
  handleErnOrBookingReferenceAction: (event: any) => void;
}) => {
  const theme = useTheme();

  const { role } = useAppSelector(selectUser) || {};
  const isAdminRole = role.type === USER_ROLE.admin;
  return (
    <DesktopView component={Grid} isAdmin={isAdminRole}>
      <Box
        sx={{
          display: 'flex',
          background: theme.palette.bgColor.main,
          px: 21,
          boxShadow: theme.boxShadow.important,
          height: UI_STYLES.overlayHeaderHeightSearchCriteria,
          my: 'auto',
        }}
      >
        <LeftHeaderView
          headerProps={{
            iconColor: theme.color.utility.link.option_3,
            textColor: theme.color.utility.link.option_3,
            variant: 'headline_bold',
            title: en.common.backToHome,
          }}
        />

        <SearchBarComponent
          {...{
            ernOrBookingReference,
            setErnOrBookingReference,
            handleErnOrBookingReferenceAction,
          }}
        />
      </Box>
    </DesktopView>
  );
};

const CommonBookings = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { isShowMyBookingFilterDialog } = useAppSelector(selectBooking) || {};
  const bookingFilterData = useAppSelector(selectBookingFilter) || {};
  const { role } = useAppSelector(selectUser) || {};
  const { ern } = useAppSelector(selectAuth) || {};
  const isAdminRole = role.type === USER_ROLE.admin;
  const isDelegation = role.type === USER_ROLE.delegation;
  const isAdminDelegation = isAdminRole || isDelegation;

  const [bookingSelected, setBookingSelected] = useState<Frontend.ParsedBooking | null>(null);
  const [ernOrBookingReference, setErnOrBookingReference] = useState('');
  const [bookingSearchTag, setBookingSearchTag] = useState('');

  const [isShowDefaultView, setIsShowDefaultView] = useState(true);
  const [searchResult, setSearchResult] = useState<string[] | null | undefined>(undefined);

  const handleErnOrBookingReferenceAction = async (event: any) => {
    event && event.target.blur();
    let result = undefined;
    let isSearchByRecLoc = true; //to distinguish how users do the search By recLoc or ern
    if (ernOrBookingReference.length === ADMIN_BOOKING_SEARCH_TYPE.searchByRecLoc) {
      // Booking Reference search
      if (isAdminRole) {
        result = await searchBookingByRecLoc({
          recLoc: ernOrBookingReference,
        });
        const { data } = result || {};
        if (data && data.length === 0) {
          isSearchByRecLoc = false;
          result = await searchBookingByErn(ernOrBookingReference); //if data is null when using recLoc to search,then will do the search again by ern instead
        }
      } else {
        result = await searchBookingByRecLoc(
          {
            recLoc: ernOrBookingReference,
            userId: isDelegation ? role.delegator?.employeeId : ern,
          },
          COMMON_API.searchBooking,
        );
      }
    } else if (ernOrBookingReference.length >= ADMIN_BOOKING_SEARCH_TYPE.searchByErn) {
      //new logic is that no matter what the digits of ern is,the search will still execute
      // BookingUser UserId Search
      isSearchByRecLoc = false;
      result = await searchBookingByErn(ernOrBookingReference);
    }

    if (result) {
      const { data } = result || {};
      if (data && data.length > 0) {
        if (isSearchByRecLoc) {
          setBookingSelected(data[0]);
        }
      }

      setIsShowDefaultView(false);
      setBookingSearchTag(ernOrBookingReference);
      setSearchResult(data);
    }

    event && event.stopPropagation();
  };

  const resetToDefaultView = () => {
    setBookingSelected(null);
    setIsShowDefaultView(true);
    setErnOrBookingReference('');
    setBookingSearchTag('');
    setSearchResult(null);
  };

  return (
    <Box sx={{ background: theme.color.secondary.light_slate.option_7 }}>
      <EditBookingFilterContextProvider>
        <MobileView component={Grid} isAdmin={isAdminRole}>
          {isAdminDelegation && (
            <RoleIndicatorBar
              customStyles={{
                px: 2.5,
                background: 'rgba(237, 237, 237, 0.7)',
              }}
            />
          )}
          <Box
            sx={{
              backgroundImage: `url(${OverlayBackground})`,
              backgroundSize: '128%',
              width: '100%',
              height: '140px',
              backgroundPosition: '20% 19%',
              p: 2,
            }}
          >
            <LeftHeaderView
              headerProps={{
                iconColor: theme.palette.bgColor.main,
                textColor: theme.palette.bgColor.main,
                variant: 'title_2_bold',
                title: isAdminRole ? en.home.adminBooking : en.home.myBooking,
              }}
            />

            <Box
              sx={{
                my: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <SearchBarComponent
                {...{
                  ernOrBookingReference,
                  setErnOrBookingReference,
                  handleErnOrBookingReferenceAction,
                }}
              />

              <SearchFilterIconWithCount
                onClick={() => dispatch(setIsShowMyBookingFilterDialog(true))}
                data={bookingFilterData}
              />
            </Box>
          </Box>
        </MobileView>

        {isAdminRole ? (
          <AdminBookings
            ernOrBookingReference={bookingSearchTag}
            setIsShowDefaultView={() => {
              resetToDefaultView();
            }}
            searchResult={searchResult}
            isShowDefaultView={isShowDefaultView}
            refreshAfterCancelBooking={() => {
              handleErnOrBookingReferenceAction('');
            }}
            searchedBooking={bookingSelected}
            headerComponent={
              <HeaderComponent
                {...{
                  ernOrBookingReference,
                  setErnOrBookingReference,
                  handleErnOrBookingReferenceAction,
                }}
              />
            }
          />
        ) : (
          <MyBookings
            headerComponent={
              <HeaderComponent
                {...{
                  ernOrBookingReference,
                  setErnOrBookingReference,
                  handleErnOrBookingReferenceAction,
                }}
              />
            }
            searchResult={searchResult}
            setSearchResult={setSearchResult}
            searchedBooking={bookingSelected}
          />
        )}

        {/* Mobile View Booking Filter Overlay */}
        {!isDesktop && (
          <DrawerOverlay
            showOverlay={isShowMyBookingFilterDialog}
            isShowBackArrow={true}
            handleClose={() => {
              dispatch(setIsShowMyBookingFilterDialog(false));
            }}
            title={en.home.myBooking}
            component={ViewBookingFilter}
            data={{
              // TODO: [Filter] please update this section during integration
              goToRouteStep: () => {},
              handleBackClick: () => {
                dispatch(setIsShowMyBookingFilterDialog(false));
              },
              handleFooterClick: () => {
                dispatch(setIsShowMyBookingFilterDialog(false));
              },
              setSearchResult,
            }}
          />
        )}
      </EditBookingFilterContextProvider>
    </Box>
  );
};

export default CommonBookings;
