import React, { useEffect } from 'react';
import { Typography, Box, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { USER_PROFILE_DETAILS } from '../../../constants/constants';

import { IInformationListItem } from '../../../containers/UserProfile/Common/InformationList';

import { isRetireeWidow, isAssoSubsid, getDisplayVal, isRetireeSpecialProfile } from '../../../helpers';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectAuth } from '../../../slice/authSlice';
import { selectUser, getProfileThunk } from '../../../slice/userSlice';
import { selectApp } from '../../../slice/appSlice';

import { InformationList } from '../../../containers';

const ContactDetailsContainer = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(selectUser);
  const { ern: ernFromLogin } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};

  useEffect(() => {
    dispatch(getProfileThunk(ernFromLogin));
    return () => {};
  }, [dispatch]);

  const { personalInfo } = profile || {};

  const { personalEmail, businessEmail, mobilePhone, workPhone } = personalInfo || {};
  const {
    companyEmail: companyEmailLbl,
    personalEmail: personalEmailLbl,
    officePhoneNumber: officePhoneNumberLbl,
    mobilePhoneNumber: mobilePhoneNumberLbl,
    title: titleLbl,
    assoSubsidNoticeMessage,
    retireeWidowNoticeMessage,
    retireeSpecialNoticeMessage,
    noticeMessage,
  } = en.userProfile.personal.contactInfo;

  const isAssoSubsidProfile = isAssoSubsid(profile);
  const isRetireeWidowProfile = profile && isRetireeWidow(profile);
  const isSpecialRetireeProfile = profile && isRetireeSpecialProfile(profile);

  const listDataContactInfo: IInformationListItem[] = [
    !isRetireeWidowProfile &&
      !isSpecialRetireeProfile && {
        key: companyEmailLbl,
        value: getDisplayVal(businessEmail),
      },
    {
      key: personalEmailLbl,
      value: getDisplayVal(personalEmail),
    },
    // TODO: hard code
    // {
    //   key: en.userProfile.personal.contactInfo.notificationEmail,
    //   value:  getDisplayVal(businessEmail),
    //   isEdit: true,
    // },
    !isRetireeWidowProfile &&
      !isSpecialRetireeProfile && {
        key: officePhoneNumberLbl,
        value: getDisplayVal(workPhone),
      },
    {
      key: mobilePhoneNumberLbl,
      value: getDisplayVal(mobilePhone),
    },
  ].filter(Boolean) as IInformationListItem[];

  const showNoticeMessage = (): string => {
    if (isAssoSubsidProfile) {
      return assoSubsidNoticeMessage;
    } else if (isRetireeWidowProfile) {
      return retireeWidowNoticeMessage;
    } else if (isSpecialRetireeProfile) {
      return retireeSpecialNoticeMessage;
    } else {
      return noticeMessage;
    }
  };

  return (
    <Box sx={{ ...(isDesktop && { width: USER_PROFILE_DETAILS.width }) }}>
      <InformationList title={titleLbl} listData={listDataContactInfo} />
      <Typography
        variant="body_2_regular"
        sx={{
          mt: isRetireeWidowProfile || isSpecialRetireeProfile ? 3 : 5,
          color: theme.color.secondary.dark_grey.option_3,
          width: { sm: USER_PROFILE_DETAILS.width, xs: 'auto' },
        }}
      >
        {showNoticeMessage()}
      </Typography>
    </Box>
  );
};

export default ContactDetailsContainer;
