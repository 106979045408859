import { ChangeEvent, useContext, useEffect, useMemo } from 'react';
import { Box, useTheme, Typography, IconButton, Checkbox, Paper } from '@mui/material';
import { reduxForm, Field, getFormValues, reset, FormErrors } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';

import en from '../../../../translations/en';
import {
  BENEFICIARY_TYPE_CODE,
  FORM,
  INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
  INIT_EDIT_PASSPORTINFO_CONSENT,
} from '../../../../constants/constants';
import { BackIcon, CheckBoxCheckIcon, CheckBoxIcon, PassportIcon } from '../../../../assets/images';
import { USER_PROFILE_PATHS } from '../../../../constants/paths';

import { getDisplayVal, validateRequiredFields } from '../../../../helpers';

import { ICompanionPassportDetails } from '../../../../interfaces';

import { RootState } from '../../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectUser } from '../../../../slice/userSlice';
import { NominationAction, NominationState } from '../../../../context';

import {
  ReduxFormTextInput,
  CountrySelectInput,
  PersonalOrPassportNameInput,
  PassportExpiryDatePicker,
} from '../../../../components';
import { NominationPageTitle, ParagraphMasterContainer } from '../../../../containers';

export const validatePassport = (
  values: ICompanionPassportDetails | null,
): FormErrors<ICompanionPassportDetails | null> => {
  const errors: FormErrors<ICompanionPassportDetails | null> = {};

  if (values) {
    validateRequiredFields(
      values,
      ['passportNumber', 'passportLastName', 'passportNationality', 'passportCountry'],
      errors,
    );

    if (!values.passportFirstName) {
      errors.passportFirstName =
        en.userProfile.nomination.companionRegistration.common.passportDetails.passportFirstNameErrorLabel;
    }
  }

  return errors;
};

// 1. edit companion passport -> run "edit companion passport" UIUX
// 2. edit NOT companion -> run ETP-6017 "edit passport" UIUX
const CompanionPassportContainer = (props: any) => {
  const {
    companionPassportFormValues,
    beneficiaryTypeCode,
  }: {
    companionPassportFormValues: ICompanionPassportDetails | null;
    beneficiaryTypeCode: string;
  } = props;

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile } = useAppSelector(selectUser) || {};

  const { companionRegistrationFlow } = useContext(NominationState) || {};
  const { setCompanionRegistrationFlow } = useContext(NominationAction) || {};
  const { routeTo, editPassport, showEditPassportInfo, isPassportNameConfirmed } = companionRegistrationFlow || {};
  const { step: routeToStep } = routeTo || {};

  const nominationLbl = en.userProfile.nomination;
  const companionRegistrationLbl = nominationLbl.companionRegistration;
  const passportDetailLbl = companionRegistrationLbl.common.passportDetails;
  const { passportInfo: passportInfoLbl } = en.userProfile.personal;

  const isNomineeDetailPage = location.pathname === USER_PROFILE_PATHS.dependentDetails;
  const isEditTravelCompanionPassportFromNomineeDetail = beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion;
  const isEditModeFromNomineeDetail = editPassport?.isEditMode || false;

  const {
    passportNumber,
    passportFirstName,
    passportLastName,
    passportExpirationDate,
    passportNationality,
    passportCountry,
  } = companionPassportFormValues || {};

  const handlePassportNameConfirmed = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanionRegistrationFlow({
      ...companionRegistrationFlow,
      isPassportNameConfirmed: event.target.checked,
    });
  };

  const handlePassportInfoConsent = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanionRegistrationFlow({
      ...companionRegistrationFlow,
      isPassportInfoConsent: event.target.checked,
    });
  };

  useEffect(() => {
    // ETP-6123 added: to clear context data when enter this page
    return () => {
      if (isNomineeDetailPage) {
        setCompanionRegistrationFlow({
          ...companionRegistrationFlow,
          ...INIT_EDIT_PASSPORTINFO_CONSENT,
          editPassport: INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
        });
      }
    };
  }, []);

  // save the form values to context
  useEffect(() => {
    const basePassport = {
      passportNumber: passportNumber?.trim(),
      passportFirstName: passportFirstName?.trim(),
      passportLastName: passportLastName?.trim(),
      passportExpirationDate,
    };

    const consolidatedPassport = {
      ...basePassport,
      passportNationality,
      passportCountry,
    };

    // checked if all the passport mandatory fields are filled
    const isPassportInputValid = !!(
      Object.values(basePassport).every(Boolean) &&
      passportNationality?.code &&
      passportCountry?.code
    );

    // [ETP-3868] Edit Companion passport in Nominee detail page
    if (isEditModeFromNomineeDetail) {
      setCompanionRegistrationFlow({
        ...companionRegistrationFlow,
        editPassport: {
          ...companionRegistrationFlow.editPassport,
          isPassportInputValid,
          beneficiaryTypeCode,
          requestParams: {
            dependentId: companionRegistrationFlow.editPassport.dependentId,
            passportNumber: basePassport.passportNumber,
            passportExpirationDate: basePassport.passportExpirationDate,
            passportNationality: passportNationality?.code,
            passportCountry: passportCountry?.code,
          },
        },
      });
    } else {
      // [ETP-4622] Fill-in Companion passport in Companion registration flow
      setCompanionRegistrationFlow({
        ...companionRegistrationFlow,
        isPassportInputValid,
        companionRegistrationData: {
          ...companionRegistrationFlow.companionRegistrationData,
          companionPassport: consolidatedPassport,
        },
      });
    }
  }, [companionPassportFormValues]);

  // nominee details->dependent "edit" & companion register flow reuse this file
  // page title condition:
  // 1. from dependent "edit"(isNomineeDetailPage) :
  //   IS  edit companion -> 'Edit companion passport details'
  //   NOT edit companion -> 'Edit passport details'
  // 2. from companion register flow -> 'Travel companion details'
  const pageTitle = useMemo(() => {
    let title = '';
    if (isNomineeDetailPage) {
      if (isEditModeFromNomineeDetail) {
        title = isEditTravelCompanionPassportFromNomineeDetail
          ? companionRegistrationLbl.common.editCompanionPassport.contentTitle
          : passportInfoLbl.editPassportDetails;
      }
    } else {
      title = nominationLbl.nomineesDetail.contentTitle;
    }

    return title;
  }, [isNomineeDetailPage, isEditModeFromNomineeDetail]);

  const editPassportFields = [
    {
      component: (
        <PersonalOrPassportNameInput
          name="passportFirstName"
          title={passportDetailLbl.fields.firstName}
          isReadOnly={isEditModeFromNomineeDetail}
        />
      ),
      sortIndex: 1,
    },
    {
      component: (
        <PersonalOrPassportNameInput
          name="passportLastName"
          title={passportDetailLbl.fields.lastName}
          isReadOnly={isEditModeFromNomineeDetail}
        />
      ),
      sortIndex: 2,
    },
    {
      component: (
        <Field
          component={ReduxFormTextInput}
          name="passportNumber"
          isNumOrAlphaOrSpace
          title={passportDetailLbl.fields.passportNumber}
          isAlphaOrSpace
          isUpperCase
        />
      ),
      sortIndex: 0, // companion register flow, "passportNumber" filed in top position
    },
    {
      component: <PassportExpiryDatePicker name="passportExpirationDate" title={passportDetailLbl.fields.expiryDate} />,
      sortIndex: 3,
    },
    {
      component: <CountrySelectInput name="passportNationality" title={passportDetailLbl.fields.nationality} />,
      sortIndex: 4,
    },
    {
      component: <CountrySelectInput name="passportCountry" title={passportDetailLbl.fields.issuedCountry} />,
      sortIndex: 5,
    },
  ];

  // passport 6 edit fields in companion register flow & edit dependent has different sequence
  // companion register flow: passportNumber, passport firstName, lastName ...
  // edit dependent: passport firstName, lastName, passportNumber ...
  const sortedEditPassportFields = isNomineeDetailPage
    ? editPassportFields
    : _.orderBy(editPassportFields, ['sortIndex', 'asc']);

  const editPassportContainer = useMemo(() => {
    return sortedEditPassportFields.map((item) => {
      return <>{item.component}</>;
    });
  }, [sortedEditPassportFields]);

  return (
    <Box className="companion_passport_container">
      {!isDesktop && (
        <IconButton
          onClick={() => {
            // edit companion passport flow
            if (isEditModeFromNomineeDetail) {
              dispatch(reset(FORM.companionPassport));

              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                editPassport: INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
              });
            } else {
              // companion registration flow
              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                routeTo: {
                  step: routeToStep - 1,
                },
              });
            }
          }}
          sx={{ py: 1.625, pl: 0 }}
        >
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}

      <Box sx={{ px: { xs: 0, sm: 0 } }}>
        <Box
          sx={{
            ...(isDesktop
              ? {
                  mt: 2,
                  mb: 1,
                  width: '488px',
                  mx: 'auto',
                }
              : {
                  mt: 2.25,
                }),
          }}
        >
          <NominationPageTitle
            title={pageTitle}
            sxProps={{
              pb: 3,
            }}
          />

          {!isEditModeFromNomineeDetail && (
            <ParagraphMasterContainer
              content={passportDetailLbl.descriptionContent}
              paragraphVariant="body_1_regular"
            />
          )}

          {!isEditModeFromNomineeDetail && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: isDesktop ? 0.5 : 1.5 }}>
              <Box sx={{ display: 'flex', width: 20, height: 20, mr: 0.5, mt: 0.375 }}>
                <PassportIcon width={16} height={16} />
              </Box>

              <Typography
                variant="body_1_medium"
                sx={{
                  color: theme.color.secondary.dark_grey.option_3,
                }}
              >
                {passportDetailLbl.heading}
              </Typography>
            </Box>
          )}

          <Paper sx={{ padding: '8px 16px' }}>
            <Typography variant="caption" color="textSecondary">
              {passportDetailLbl.fields.firstName}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getDisplayVal(passportFirstName || profile?.personalInfo?.firstName)}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {passportDetailLbl.fields.lastName}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getDisplayVal(passportLastName || profile?.personalInfo?.lastName)}
            </Typography>
          </Paper>

          {showEditPassportInfo ? (
            <>
              <Paper sx={{ padding: '8px 16px', mt: 2.125 }}>{editPassportContainer.slice(2, 6)}</Paper>
              <Paper sx={{ padding: '4px', mt: 2.125 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ alignSelf: 'flex-start' }}>
                    <Checkbox
                      sx={{
                        '&.MuiCheckbox-root': {
                          color: theme.status.selected,
                        },
                      }}
                      checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                      icon={<CheckBoxIcon stroke={theme.status.selected} />}
                      color="info"
                      checked={companionRegistrationFlow.isPassportInfoConsent}
                      onChange={handlePassportInfoConsent}
                    />
                  </Box>
                  <Typography variant="body_2_regular">
                    {en.userProfile.personal.passportInfo.consentMessage}
                  </Typography>
                </Box>
              </Paper>
            </>
          ) : (
            <Paper sx={{ padding: '4px', mt: 2.125 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ alignSelf: 'flex-start', mt: 1.4 }}>
                  <Checkbox
                    sx={{
                      '&.MuiCheckbox-root': {
                        color: theme.status.selected,
                      },
                    }}
                    checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                    icon={<CheckBoxIcon stroke={theme.status.selected} />}
                    color="info"
                    checked={isPassportNameConfirmed}
                    onChange={handlePassportNameConfirmed}
                  />
                </Box>

                <Box>
                  <ParagraphMasterContainer
                    content={en.userProfile.personal.passportInfo.confirmPassportNameContent}
                    paragraphTypoSxProps={{
                      color: theme.color.secondary.dark_grey.option_1,
                    }}
                    multiParagraphContainerSxProps={{ p: 0 }}
                    paragraphVariant="body_2_regular"
                  />
                </Box>
              </Box>
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  );
};

/* 
  TBC below coding, can not combine as single reduxForm() and mapStateToProps() due to different config & will trigger runtime rendering error
*/
const currentForm = FORM.companionPassport;

// [ETP-4622] Fill-in Companion passport in Companion registration flow
const fillInCompanionPassportForm = reduxForm({
  form: currentForm,
  validate: validatePassport,
  initialValues: {},
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: false,
})(CompanionPassportContainer);

const fillInCompanionPassportFormMapStateToProps = (state: RootState) => {
  return {
    companionPassportFormValues: getFormValues(currentForm)(state),
  };
};

// [ETP-3868] Edit Companion passport in Nominee detail page
const editCompanionPassportForm = reduxForm({
  form: currentForm,
  validate: validatePassport,
  initialValues: {},
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CompanionPassportContainer);

const editCompanionPassportFormMapStateToProps = (state: RootState, props: any) => {
  const { passportData, isEditMode } = props;
  return {
    initialValues: (isEditMode && passportData) || null,
    companionPassportFormValues: getFormValues(currentForm)(state),
  };
};

export const ConnectedFillInCompanionPassportForm = connect(fillInCompanionPassportFormMapStateToProps)(
  fillInCompanionPassportForm,
);
export const ConnectedEditCompanionPassportForm = connect(editCompanionPassportFormMapStateToProps)(
  editCompanionPassportForm,
);
