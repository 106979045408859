import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';

import { Configuration } from 'configuration';
import { Employee, AssoSubsidEmployee } from 'employee';
import { EmployeeDB } from 'database';
import { Theme } from '@mui/material';

import {
  ADMIN_CONCESSION,
  INFINITE_DATE,
  LABEL_VALUE_TYPE,
  OWOAL_TYPE,
  PAYMENT_MEDIUM,
  ALLOTMENT_TYPE,
  DIRECT_DEBIT_STATUS,
  PAYMENT,
  ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST,
  ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST,
  CX_COMPANY_LIST,
} from '../constants/constants';
import { ADMIN_PATHS } from '../constants/paths';
import en from '../translations/en';

import {
  IAdminConcession,
  IAdminQuotaList,
  IAdminConcessionFilterDependent,
  IEligibilityDependent,
  IDependentForAdmin,
  IAdminToggleBarTabListProps,
  IAssoSubsidAdminProfileDetail,
  IAssoSubsidAdminEmploymentDetail,
  IAssoSubsidAdminBenefitDetail,
  IAssoSubsidAdminPaymentDetail,
  IAssoSubsidAdminContactDetail,
  IAssoSubsidAdminPassportDetail,
  IAssoSubsidAdminFlownSuspensionDetail,
  IAssoSubsidAdminEmployeeDetailRoot,
  INominationDetails,
} from '../interfaces';
import { DependentInfo as IDependentInfo } from 'booking';
import {
  IAssoSubsidAdminEmployeeStateType,
  IAssoSubsidAdminEmployeeActionType,
} from '../context/AssoSubsidAdmin/AssoSubsidAdminEmployeeContext';
import {
  IEmployeeStateType as IAdminEmployeeStateType,
  IActionType as IAdminEmployeeActionType,
} from '../context/AdminAccessEmployeeContext';

import {
  checkValidDateRange,
  isEmptyField,
  checkIsFocFare,
  checkIsId50Fare,
  checkIsZoneFare,
  formatDMYDateWithSpace,
  getDisplayVal,
  getAdminPersonalDisplayName,
  stringAlphabeticSort,
} from '.';

import {
  AdminAccessEmployeeState,
  AdminAccessEmployeeAction,
  AssoSubsidAdminEmployeeState,
  AssoSubsidAdminEmployeeAction,
} from '../context';

import {
  AdminNotes,
  AssoSubsidAdminPersonalInfoDetails,
  NominationHistory,
  AdminConcession,
  QuotaHistory,
  AssoSubsidAdminDependents,
} from '../containers/AdminMaintainEmployeeDetail';

const filterConcessionListByCarrierGroup = (list: IAdminConcession[] = [], carrierGroupName: string) => {
  if (!carrierGroupName) return [];
  return _.filter(list, (item: IAdminConcession) => item?.concessionDef?.carrierGroup === carrierGroupName);
};

const transformedConcessionList = (list: IAdminConcession[] = []) => {
  if (list.length === 0) return [];
  const transformedFocConcessionList = _.chain(list)
    .orderBy([ADMIN_CONCESSION.DISPLAY_NAME])
    .groupBy((item: IAdminConcession) => item?.concessionDef?.displayName)
    .map((item: IAdminConcession[]) => {
      return {
        title: _.head(item)?.concessionDef?.displayName,
        data: item,
      };
    })
    .value();
  return transformedFocConcessionList;
};

const checkAgeInEligibilityDependent = (
  eligibility: IEligibilityDependent,
  dependent: IAdminConcessionFilterDependent,
) => {
  // check ageFrom or ageTo exist, then proceed to validate the age.
  if (
    eligibility.beneficiaryType === dependent.beneficiaryTypeCode &&
    eligibility.ageFrom !== undefined &&
    eligibility.ageTo !== undefined &&
    !_.isNil(dependent?.age)
  ) {
    // validate the age in the age period
    return dependent?.age >= eligibility?.ageFrom && dependent?.age < eligibility?.ageTo;
  }
  return true;
};

// [ETP-4887] validate OAL eligibility
const checkEligibilityWithOALEligibility = (
  isWithOALEligibility: Configuration.OALEligibilityLabel['withOALEligibility'],
  dependent: IAdminConcessionFilterDependent,
) => {
  if (isWithOALEligibility && dependent.isAssignedOAL !== undefined) {
    return dependent.isAssignedOAL;
  }

  return true;
};

// [ETP-4887] validate nomination type
const checkEligibilityNominationType = (
  eligibilityNominationTypeList: string[],
  dependent: IAdminConcessionFilterDependent,
) => {
  // bypass empty nomination type OR Employee self
  return (
    eligibilityNominationTypeList.length === 0 ||
    dependent.dependentId === null ||
    (dependent.nominationType && eligibilityNominationTypeList.includes(dependent.nominationType))
  );
};

const adminConcessionCarrierGroupSort = (
  adminConcessionData: IAdminConcession[] = [],
  dependent: IAdminConcessionFilterDependent,
) => {
  // cx group (include foc, zone, id50)
  const cxCarrierGroupList = filterConcessionListByCarrierGroup(adminConcessionData, 'CX');

  // ow group
  const owCarrierGroupList = filterConcessionListByCarrierGroup(adminConcessionData, OWOAL_TYPE.OW);

  // oal group
  const oalCarrierGroupList = filterConcessionListByCarrierGroup(adminConcessionData, OWOAL_TYPE.OAL);

  const focGroupList: IAdminConcession[] = [];
  const zoneGroupList: IAdminConcession[] = [];
  const id50GroupList: IAdminConcession[] = [];

  // cx carrier spilt into foc, zone, id50 groups
  cxCarrierGroupList?.map((cxConcessions: IAdminConcession) => {
    const { fareType, fareDiscount } = cxConcessions?.concessionDef || {};

    if (cxConcessions?.numberOfAllotmentSectors === -1) {
      if (
        checkIsFocFare({
          fareType,
          fareDiscount,
        })
      ) {
        focGroupList.push(cxConcessions);
      } else if (
        checkIsZoneFare({
          fareType,
          fareDiscount,
        })
      ) {
        zoneGroupList.push(cxConcessions);
      } else if (
        checkIsId50Fare({
          fareType,
          fareDiscount,
        })
      ) {
        id50GroupList.push(cxConcessions);
      }
    } else {
      // if numberOfAllotmentSectors != -1 which means quotaList is not empty,
      // check all quotaList if equals filter user's dependentId
      cxConcessions?.quotaList?.map((quota: IAdminQuotaList) => {
        if (
          quota?.dependentId === dependent?.dependentId ||
          (cxConcessions?.allotmentType === ALLOTMENT_TYPE.P && quota.quotaType === ALLOTMENT_TYPE.P)
        ) {
          if (
            checkIsFocFare({
              fareType,
              fareDiscount,
            })
          ) {
            const item = {
              ...cxConcessions,
              focusQuota: quota,
            };
            focGroupList.push(item);
          } else if (
            checkIsZoneFare({
              fareType,
              fareDiscount,
            })
          ) {
            const item = {
              ...cxConcessions,
              focusQuota: quota,
            };
            zoneGroupList.push(item);
          } else if (
            checkIsId50Fare({
              fareType,
              fareDiscount,
            })
          ) {
            const item = {
              ...cxConcessions,
              focusQuota: quota,
            };
            id50GroupList.push(item);
          }
        }
      });
    }
  });

  const transformedFocConcessionList = transformedConcessionList(focGroupList);
  const transformedId50ConcessionList = transformedConcessionList(id50GroupList);
  const transformedZoneConcessionList = transformedConcessionList(zoneGroupList);
  const transformedOwConcessionList = transformedConcessionList(owCarrierGroupList);
  const transformedOALConcessionList = transformedConcessionList(oalCarrierGroupList);

  return [
    ...transformedFocConcessionList,
    ...transformedZoneConcessionList,
    ...transformedId50ConcessionList,
    ...transformedOwConcessionList,
    ...transformedOALConcessionList,
  ];
};

const validAdminEditFromToDateParams = (jobLevel?: string, from?: string, to?: string, mayKey?: string) => {
  const errorInfo = new Map();
  const { editProfilePopup } = en.admin.feature.personalInformation.profileDetails;

  if (_.isUndefined(jobLevel)) {
    errorInfo.set(mayKey, {});
  } else {
    if (!isEmptyField(jobLevel)) {
      if (!from) {
        errorInfo.set(mayKey, { from: en.error.required });
      } else if (!to) {
        errorInfo.set(mayKey, { to: en.error.required });
      } else if (!checkValidDateRange(from, to)) {
        errorInfo.set(mayKey, {
          from: editProfilePopup.inputValidDateRange,
          to: editProfilePopup.inputValidDateRange,
        });
      }
    }
  }

  return {
    errorObject: errorInfo.get(mayKey),
  };
};

const constructAdminEditJobLevelParams = (jobLevel?: string, from?: string, to?: string, mayKey?: string) => {
  const jobLevelInfo = new Map();

  if (jobLevel === null || isEmptyField(jobLevel)) {
    // null or "--"
    jobLevelInfo.set(mayKey, { jobLevel: null });
  } else if (!_.isUndefined(jobLevel)) {
    jobLevelInfo.set(mayKey, {
      jobLevel: jobLevel,
      from: _.isEmpty(from) ? null : from,
      to: _.isEmpty(to) ? null : to,
    });
  } else {
    jobLevelInfo.set(mayKey, {});
  }

  return {
    jobLevelObject: jobLevelInfo.get(mayKey),
  };
};

const constructDefaultJobLevelValue = (editJobLevel?: string, profileJobLevel?: string | null): string => {
  if (editJobLevel) {
    return editJobLevel;
  } else {
    return profileJobLevel === null ? en.common.empty : profileJobLevel || '';
  }
};

const validFlownSuspensionParams = (suspensionStartDate?: string, suspensionEndDate?: string, mayKey?: string) => {
  const errorInfo = new Map();
  const { editProfilePopup } = en.admin.feature.personalInformation.profileDetails;

  if (_.isEmpty(suspensionStartDate) && !_.isEmpty(suspensionEndDate)) {
    // has end date, but no start date
    errorInfo.set(mayKey, { from: en.error.required });
  } else if (
    !_.isEmpty(suspensionStartDate) &&
    !_.isEmpty(suspensionEndDate) &&
    !checkValidDateRange(suspensionStartDate, suspensionEndDate)
  ) {
    errorInfo.set(mayKey, {
      from: editProfilePopup.inputValidDateRange,
      to: editProfilePopup.inputValidDateRange,
    });
  }

  return {
    errorObject: errorInfo.get(mayKey),
  };
};

const constructAdminFlownSuspensionParams = (
  suspensionStartDate?: string,
  suspensionEndDate?: string,
  mayKey?: string,
) => {
  const suspensionInfo = new Map();

  if (!_.isEmpty(suspensionStartDate)) {
    suspensionInfo.set(mayKey, {
      flownSuspensionStartDate: suspensionStartDate,
      flownSuspensionEndDate: suspensionEndDate ?? INFINITE_DATE,
    });
  } else {
    suspensionInfo.set(mayKey, {});
  }

  return {
    suspensionObject: suspensionInfo.get(mayKey),
  };
};

const checkTargetPaymentMedium = ({
  targetPaymentMedium,
  profileData,
  editEmployeeDetail,
}: {
  targetPaymentMedium: string;
  profileData?: any;
  editEmployeeDetail?: any;
}) => {
  if (profileData && editEmployeeDetail) {
    return (
      editEmployeeDetail?.payment?.paymentMedium === targetPaymentMedium ||
      profileData.paymentMedium === targetPaymentMedium
    );
  } else if (profileData) {
    return profileData.paymentMedium === targetPaymentMedium;
  } else if (editEmployeeDetail) {
    return editEmployeeDetail?.payment?.paymentMedium === targetPaymentMedium;
  }
};

const isPaymentMediumPaypal = ({ profileData, editEmployeeDetail }: { profileData?: any; editEmployeeDetail?: any }) =>
  checkTargetPaymentMedium({
    targetPaymentMedium: PAYMENT_MEDIUM.PAYPAL_PAYMENT,
    profileData,
    editEmployeeDetail,
  });

const isPaymentMediumDirectDebit = ({
  profileData,
  editEmployeeDetail,
}: {
  profileData?: any;
  editEmployeeDetail?: any;
}) =>
  checkTargetPaymentMedium({
    targetPaymentMedium: PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT,
    profileData,
    editEmployeeDetail,
  });

const getPaymentStatusLabelInfo = ({
  profileData,
  editEmployeeDetail,
  isEditingAdminProfile = false,
}: {
  profileData?: any;
  editEmployeeDetail?: any;
  isEditingAdminProfile?: boolean;
}) => {
  const isEmptyEditPaymentMedium = _.isUndefined(editEmployeeDetail?.payment?.paymentMedium);

  const isSelectedDirectDebit = isPaymentMediumDirectDebit({
    ...(isEmptyEditPaymentMedium && { profileData }),
    ...(!isEmptyEditPaymentMedium && { editEmployeeDetail }),
  });

  const isPaypal = isPaymentMediumPaypal({
    // select paypal
    ...(!editEmployeeDetail?.payment?.paymentMedium && { profileData }),
    ...(editEmployeeDetail?.payment?.paymentMedium && { editEmployeeDetail }),
  });

  let labelValue: any;
  let labelType: LABEL_VALUE_TYPE = LABEL_VALUE_TYPE.BOOLEAN;
  if (isSelectedDirectDebit) {
    // for direct debit status, use backend "directDebitStatus"
    // labelValue is for view mode (edit mode use context data)
    labelValue = profileData.directDebitStatus === PAYMENT.paymentStatusValue.yes;
    if (isEditingAdminProfile) {
      // edit mode, label type "drop select"
      labelType = LABEL_VALUE_TYPE.FORM_SELECT;
    } else {
      // view mode: backend directDebitStatus will return "Y"/"N"/null these 3 values
      // use ["Y", "N"].includes(directDebitStatus) to distinguish "boolean" and "string" (LabelValueContainer Type)
      // 1. if "Y", "N" -> label type is "boolean" , use "labelValue(Y->true, N->false)" AND options: ["Yes", "No"]" to convert "Yes"/ "No"
      // 2. if null -> label type is "string", use "getDisplayVal(null)" function convert to "--"
      labelType = DIRECT_DEBIT_STATUS.includes(profileData.directDebitStatus)
        ? LABEL_VALUE_TYPE.BOOLEAN
        : LABEL_VALUE_TYPE.STRING;
    }
  }

  if (isPaypal) {
    // for paypal, use backend "isPayPalBillingAgreementSetup" (true/false)
    labelValue = profileData.isPayPalBillingAgreementSetup;
    // view & edit mode both show boolean type
    labelType = LABEL_VALUE_TYPE.BOOLEAN;
  }

  return { labelValue, labelType };
};

// TODO: will consider to refactor the CX Admin Employee in later time
// Extract API response to context state for Asso/Subsid Admin Employee > Personal Information Tab > Profile Detail section
const consolidateProfileDetailByApiResponse = ({
  profileData = null,
  personalInfoData = null,
}: {
  profileData: AssoSubsidEmployee.Profile | null;
  personalInfoData: AssoSubsidEmployee.PersonalInfo | null;
}) => {
  const {
    isAccessible,
    directDebitStatus,
    yearOfService,
    isAdminEditedYOS,
    yearOfServiceByAdmin,
    paymentConcept,
    paymentMedium,
    benefitDTJobLevel,
    benefitLTJobLevel,
    hiringCompany,
    employeeId,
    revision,
    company,
    isPayPalBillingAgreementSetup,
    type,
    upn,
  } = profileData || {};
  const {
    title,
    firstName,
    middleName,
    lastName,
    gender,
    dateOfBirth,
    firstNamePreferred,
    lastNamePreferred,
    countryOfResidential,
    maritalStatus,
    dependentId,
  } = personalInfoData || {};
  return {
    title,
    firstName,
    middleName,
    lastName,
    firstNamePreferred,
    lastNamePreferred,
    gender,
    dateOfBirth,
    employeeId,
    revision,
    company,
    hiringCompany,
    countryOfResidential,
    maritalStatus,
    isAccessible,
    dependentId,
    directDebitStatus,
    isPayPalBillingAgreementSetup,
    yearOfService,
    isAdminEditedYOS,
    yearOfServiceByAdmin,
    paymentConcept,
    paymentMedium,
    benefitDTJobLevel,
    benefitLTJobLevel,
    type,
    upn,
  };
};

// TODO: will consider to refactor the CX Admin Employee in later time
// Extract API response to context state for Asso/Subsid Admin Employee > Personal Information Tab > Employment Detail section
const consolidateEmploymentDetailByApiResponse = ({
  profileData = null,
}: {
  profileData: (AssoSubsidEmployee.Profile & EmployeeDB.Profile) | null;
}) => {
  const {
    jobLevel,
    jobTitle,
    contractType,
    employmentType,
    personOfInterestType,
    officerCode,
    hrStatus,
    lastHireDate,
    terminationDate,
    terminationActionReason,
    employeeClassification,
    fullPartTime,
    regularTemporary,
    domicilePort,
    recruitmentPort,
    regulatoryRegion,
    location,
    homePort,
    isHouseStaff,
    travelEndDate,
    reportEmployeeId,
  } = profileData || {};
  return {
    jobLevel,
    jobTitle,
    contractType,
    employmentType,
    personOfInterestType,
    officerCode,
    hrStatus,
    lastHireDate, // Lbl serviceStartDate
    terminationDate, // Lbl serviceEndDate
    travelEndDate, // travelEndDate
    terminationActionReason, // resignationCode,
    employeeClassification, // employeeCategory
    fullPartTime, // fullOrPartTime
    regularTemporary, // regularOrTemp
    domicilePort,
    recruitmentPort, // recruitmentPort
    regulatoryRegion, // countryOfEmployment
    location, // workingLocation
    homePort,
    isHouseStaff, // houseStaff
    reportEmployeeId,
  };
};

// TODO: will consider to refactor the CX Admin Employee in later time
// Extract API response to context state for Asso/Subsid Admin Employee > Personal Information Tab > Benefit Detail section
const consolidateBenefitDetailByApiResponse = ({
  profileData = null,
  personalInfoData = null,
}: {
  profileData: AssoSubsidEmployee.Profile | null;
  personalInfoData: AssoSubsidEmployee.PersonalInfo | null;
}) => {
  const { benefitDTJobLevel, benefitLTJobLevel, isActive, type, yearOfService, companySeniorityDate } =
    profileData || {};
  const { age } = personalInfoData || {};
  return {
    companySeniorityDate,
    yearOfService,
    isActive,
    age,
    type,
    benefitDTJobLevel,
    benefitLTJobLevel,
  };
};

// TODO: will consider to refactor the CX Admin Employee in later time
// Extract API response to context state for Asso/Subsid Admin Employee > Personal Information Tab > Payment Detail section
const consolidatePaymentDetailByApiResponse = ({
  profileData = null,
}: {
  profileData: AssoSubsidEmployee.Profile | null;
}) => {
  const { paymentConcept, paymentMedium } = profileData || {};
  return {
    paymentConcept,
    paymentMedium,
  };
};

// TODO: will consider to refactor the CX Admin Employee in later time
// Extract API response to context state for Asso/Subsid Admin Employee > Personal Information Tab > Contact Detail section
const consolidateContactDetailByApiResponse = ({
  personalInfoData = null,
}: {
  personalInfoData: AssoSubsidEmployee.PersonalInfo | null;
}) => {
  const { personalEmail, businessEmail, mobilePhone, workPhone } = personalInfoData || {};
  return {
    personalEmail,
    businessEmail,
    mobilePhone,
    workPhone,
  };
};

// TODO: will consider to refactor the CX Admin Employee in later time
// Extract API response to context state for Asso/Subsid Admin Employee > Personal Information Tab > Passport Detail section
const consolidatePassportDetailByApiResponse = ({
  employeePassport = null,
  personalInfoData = null,
}: {
  personalInfoData: AssoSubsidEmployee.PersonalInfo | null;

  employeePassport: any;
}) => {
  const {
    passportNumber,
    passportFirstName,
    passportLastName,
    passportExpirationDate,
    passportNationality,
    passportCountry,
  } = employeePassport || {};
  const { countryOfResidential } = personalInfoData || {};

  return {
    passportNumber,
    passportFirstName,
    passportLastName,
    passportExpirationDate,
    passportNationality,
    passportCountry,
    countryOfResidential,
  };
};

const transformInitAssoSubsidAdminEmployeeContext = (initData: any) => {
  const {
    profile,
    personalInfo,
    passportDetail,
    nominationList,
    nominationHistory,
    flownSuspensionDetails,
    quotaHistory,
  } = initData;

  /*
    Remark:
    (1) API resp > passportDetail may empty [] 
    (2) Below transformation logic will handle the `passportDetail[]` to be `passportDetail` object type.
    Please aware the `passportDetail` is object type in Assosubsid Admin Employee Context but
    `passportDetail` to be keep as array type in CX Admin Employee Context.
  */
  const employeePassport = passportDetail?.find((item: Employee.PassportInfo) => item.dependentId === null) || {};

  return {
    // Personal Information Tab content as below
    profileDetail: consolidateProfileDetailByApiResponse({
      profileData: profile,
      personalInfoData: personalInfo,
    }) as IAssoSubsidAdminProfileDetail,
    employmentDetail: consolidateEmploymentDetailByApiResponse({
      profileData: profile,
    }) as IAssoSubsidAdminEmploymentDetail,
    benefitDetail: consolidateBenefitDetailByApiResponse({
      profileData: profile,
      personalInfoData: personalInfo,
    }) as IAssoSubsidAdminBenefitDetail,
    paymentDetail: consolidatePaymentDetailByApiResponse({ profileData: profile }) as IAssoSubsidAdminPaymentDetail,
    flownSuspensionDetails: flownSuspensionDetails as IAssoSubsidAdminFlownSuspensionDetail,
    contactDetail: consolidateContactDetailByApiResponse({
      personalInfoData: personalInfo,
    }) as IAssoSubsidAdminContactDetail,
    passportDetail: consolidatePassportDetailByApiResponse({
      employeePassport,
      personalInfoData: personalInfo,
    }) as IAssoSubsidAdminPassportDetail,

    // Other Tab contents as below
    dependents: nominationList as IAssoSubsidAdminEmployeeDetailRoot['dependents'],
    nominationHistory: nominationHistory as IAssoSubsidAdminEmployeeDetailRoot['nominationHistory'],
    quotaHistory: quotaHistory as IAssoSubsidAdminEmployeeDetailRoot['quotaHistory'],
  };
};

// Apply for CX admin dependent + Asso/subsid admin dependent
const getInitEmployeeDependents = (initDependents: IDependentInfo[] | null) => {
  if (!Array.isArray(initDependents)) return [];

  // initialize the dependents data
  const flattenedTmpDependentList = initDependents.flatMap((item: IDependentInfo) => {
    const {
      age,
      beneficiaryType,
      dateOfBirth,
      dependentId,
      firstName,
      gender,
      lastName,
      middleName,
      passportCountry,
      passportExpirationDate,
      passportName,
      passportNationality,
      passportNumber,
      profileFirstName,
      profileMiddleName,
      profileLastName,
      relationship,
      title,
      validFrom,
      validTo,
      isActive: isPersonalInfoActive,
    } = item || {};

    const dependentName = getAdminPersonalDisplayName({
      lastName,
      firstName,
      middleName,
    });

    const baseProfileDetails = {
      dependentType: beneficiaryType,
      title,
      firstName: profileFirstName,
      middleName: profileMiddleName,
      surname: profileLastName,
      relationship,
      gender,
      dateOfBirth: formatDMYDateWithSpace(dateOfBirth),
      validFrom: formatDMYDateWithSpace(getDisplayVal(validFrom)),
      validTo: formatDMYDateWithSpace(getDisplayVal(validTo)),
    };

    const basePassportDetails = {
      passportNumber,
      firstOtherNameAsInPassport: passportName?.firstName,
      surnameAsInPassport: passportName?.lastName,
      passportExpiryDate: formatDMYDateWithSpace(passportExpirationDate),
      nationalityInPassport: passportNationality,
      issuedCountry: passportCountry,
    };

    if (item.nominationDetails?.length > 0) {
      return item.nominationDetails.map((nominationItem: INominationDetails) => {
        const { isActive, nominationFrom, nominationTo, nominationType } = nominationItem || {};

        // Nominated meanings: nomination active + personal info active
        const isCurrentNominee = isActive && isPersonalInfoActive;

        return {
          dependentId,
          pureDependentData: {
            nominationFrom,
            nominationTo,
            nominationType,
          },
          name: dependentName,
          relationship,
          age,
          nominationFrom: isCurrentNominee ? formatDMYDateWithSpace(nominationFrom) : '',
          nominationTo: isCurrentNominee ? formatDMYDateWithSpace(nominationTo) : '',
          nominationStatus: isCurrentNominee ? en.admin.dependents.currentNominee : en.admin.dependents.notNominated,
          nominationType,
          profileDetails: {
            ...baseProfileDetails,
            dependentStatus: isPersonalInfoActive,
          },
          passportDetails: basePassportDetails,
          isPersonalInfoActive,
          isCurrentNominee, // same as previous naming -> isNominationActive
        } as IDependentForAdmin;
      });
    } else {
      // Empty nomination record for this dependent
      const nominationFrom = '';
      const nominationTo = '';
      const nominationType = '';

      return {
        // Remark: used for select row item combination
        dependentId,
        pureDependentData: {
          nominationFrom,
          nominationTo,
          nominationType,
        },
        name: dependentName,
        relationship,
        age,
        nominationFrom,
        nominationTo,
        nominationStatus: en.admin.dependents.notNominated,
        nominationType,
        profileDetails: {
          ...baseProfileDetails,
          dependentStatus: isPersonalInfoActive,
        },
        passportDetails: basePassportDetails,
        isPersonalInfoActive,
        isCurrentNominee: false, // same as previous naming -> isNominationActive
      } as IDependentForAdmin;
    }
  });

  return flattenedTmpDependentList || [];
};

const getAdminFirstLevelToggleList = () => {
  const {
    personalInformation: personalInformationToggleLbl,
    dependents: dependentsToggleLbl,
    note: noteToggleLbl,
    nominationHistory: nominationHistoryToggleLbl,
    concession: concessionToggleLbl,
    quotaHistory: quotaHistoryToggleLbl,
  } = en.admin.adminToggleList;

  const firstToggleBarTabList: IAdminToggleBarTabListProps[] = [
    {
      id: ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.personalInformation,
      title: personalInformationToggleLbl,
      component: <AssoSubsidAdminPersonalInfoDetails defaultId={ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.all} />,
    },
    {
      id: ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.dependents,
      title: dependentsToggleLbl,
      component: <AssoSubsidAdminDependents />,
    },
    {
      id: ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.note,
      title: noteToggleLbl,
      component: <AdminNotes />,
    },
    {
      id: ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.concession,
      title: concessionToggleLbl,
      component: <AdminConcession />,
    },
    {
      id: ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.nominationHistory,
      title: nominationHistoryToggleLbl,
      component: <NominationHistory />,
    },
    {
      id: ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.quotaHistory,
      title: quotaHistoryToggleLbl,
      component: <QuotaHistory />,
    },
  ];
  return firstToggleBarTabList;
};

const getAdminSecondLevelToggleList = () => {
  const {
    all: allToggleLbl,
    profile: profileToggleLbl,
    employment: employmentToggleLbl,
    payment: paymentToggleLbl,
    benefit: benefitToggleLbl,
    contact: contactToggleLbl,
    passport: passportToggleLbl,
    flownSuspension: flownSuspensionToggleLbl,
    profileHousekeeping: profileHousekeepingToggleLbl,
  } = en.admin.adminToggleList;
  const secondToggleBarTabList: IAdminToggleBarTabListProps[] = [
    {
      id: ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.all,
      title: allToggleLbl,
      component: <></>,
    },
    {
      id: ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.profile,
      title: profileToggleLbl,
      component: <></>,
    },
    {
      id: ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.employment,
      title: employmentToggleLbl,
      component: <></>,
    },
    {
      id: ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.payment,
      title: paymentToggleLbl,
      component: <></>,
    },
    {
      id: ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.flownSuspension,
      title: flownSuspensionToggleLbl,
      component: <></>,
    },
    {
      id: ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.benefit,
      title: benefitToggleLbl,
      component: <></>,
    },
    {
      id: ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.contact,
      title: contactToggleLbl,
      component: <></>,
    },
    {
      id: ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.passport,
      title: passportToggleLbl,
      component: <></>,
    },
    {
      id: ADMIN_SECOND_LEVEL_TOGGLE_BAR_ID_LIST.profileHousekeeping,
      title: profileHousekeepingToggleLbl,
      component: <></>,
    },
  ];
  return secondToggleBarTabList;
};

const checkIsAssoSubsidAdminPage = () => {
  const location = useLocation();
  return location?.pathname === ADMIN_PATHS.adminAssoSubsidEmployee;
};

// To return context state and context action by checking the current page is Asso/Subsid Admin or Admin
interface IAdminCurrentContext {
  currentContextState: IAdminEmployeeStateType;
  currentContextAction: IAdminEmployeeActionType;
}
interface IAssoSubsidAdminCurrentContext {
  currentContextState: IAssoSubsidAdminEmployeeStateType;
  currentContextAction: IAssoSubsidAdminEmployeeActionType;
}

const getAdminCurrentContext = (): IAssoSubsidAdminCurrentContext | IAdminCurrentContext => {
  const isAssoSubsidAdminPage = checkIsAssoSubsidAdminPage();

  // Return AssoSubsid Admin Context
  if (isAssoSubsidAdminPage) {
    return {
      currentContextState: useContext(AssoSubsidAdminEmployeeState),
      currentContextAction: useContext(AssoSubsidAdminEmployeeAction),
    } as IAssoSubsidAdminCurrentContext;
  }

  // Return CX Admin Context
  return {
    currentContextState: useContext(AdminAccessEmployeeState),
    currentContextAction: useContext(AdminAccessEmployeeAction),
  } as IAdminCurrentContext;
};

const getAdminCurrentProfileContextData = () => {
  const currentContext = getAdminCurrentContext();
  const isAssoSubsidAdminPage = checkIsAssoSubsidAdminPage();

  return isAssoSubsidAdminPage
    ? currentContext.currentContextState?.employeeDetail?.profileDetail
    : currentContext.currentContextState?.employeeDetail?.profile;
};

const getAdminMatchedDependent = (targetDependentItem: IDependentForAdmin, dependents: IDependentForAdmin[]) => {
  const matchedResult = dependents?.find((item: IDependentForAdmin) => {
    return (
      item.dependentId === targetDependentItem.dependentId &&
      JSON.stringify(item.pureDependentData) === JSON.stringify(targetDependentItem.pureDependentData)
    );
  });
  return matchedResult;
};

const getAssoSubsidEligibleCompanyList = (
  adminRoleCompany: string[],
  configurationsCompanies: Configuration.Companies[],
) => {
  const eligibleCompanyList = configurationsCompanies
    .filter((company) => {
      return adminRoleCompany?.includes(company.code) && !(company.code in CX_COMPANY_LIST);
    })
    .map((company) => {
      return {
        code: company.code,
        label: company.name,
      };
    })
    .sort((a, b) => stringAlphabeticSort(a.label, b.label));

  return eligibleCompanyList;
};

const controlBreakpointDisplayByLoginRole = ({
  isAdmin = false,
  isShowMobileView = false,
}: {
  isAdmin: boolean;
  isShowMobileView: boolean;
}) => {
  // example: xs: 'none', sm: 'block'.

  // Admin role setting
  if (isAdmin) {
    return {
      xs: isShowMobileView ? 'none' : 'block',
      adminDesktopView: isShowMobileView ? 'none' : 'block',
    };
  }

  /*
    Normal user role setting
    meaning: arrives as breakpoint sm or above, will show else be hidden.
  */
  return {
    xs: isShowMobileView ? 'block' : 'none',
    sm: isShowMobileView ? 'none' : 'block',
  };
};

// [ETP-6035] detect the screen size for setting the popup container style
/*
  Remark:
  Keep popup container overflow-y scrollable if needed
*/
const getAdminModulePopupContainerBreakpointStyle = (theme: Theme) => {
  if (!theme) return undefined;
  return {
    [theme.breakpoints.up('adminDesktopView')]: {
      overflowY: 'auto',
    },
  };
}

// [ETP-6035] detect the screen size for setting the popup container > content style
/*
  Remark:
  keep 100% width for content <Box> when breakpoint <= 1024px and
  keep 1024px width for content <Box> when breakpoint > 1024px.
*/
const getAdminModulePopupContentBreakpointStyle = (theme: Theme) => {
  if (!theme) return undefined;
  return {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      height: 'auto',
    },
  };
}

export {
  adminConcessionCarrierGroupSort,
  validAdminEditFromToDateParams,
  constructAdminEditJobLevelParams,
  constructDefaultJobLevelValue,
  validFlownSuspensionParams,
  constructAdminFlownSuspensionParams,
  isPaymentMediumPaypal,
  isPaymentMediumDirectDebit,
  checkAgeInEligibilityDependent,
  checkEligibilityWithOALEligibility,
  checkEligibilityNominationType,
  getPaymentStatusLabelInfo,
  transformInitAssoSubsidAdminEmployeeContext,
  checkIsAssoSubsidAdminPage,
  getAdminCurrentContext,
  getInitEmployeeDependents,
  getAdminFirstLevelToggleList,
  getAdminSecondLevelToggleList,
  getAdminCurrentProfileContextData,
  getAssoSubsidEligibleCompanyList,
  getAdminMatchedDependent,
  controlBreakpointDisplayByLoginRole,
  getAdminModulePopupContainerBreakpointStyle,
  getAdminModulePopupContentBreakpointStyle
};
