import { CSSProperties, FunctionComponent, SVGProps, useMemo } from 'react';
import { Dispatch } from 'redux';
import { isArray, isEmpty, isNull, isUndefined, pick } from 'lodash';

import { useTheme } from '@mui/material';
import { ManipulateType } from 'dayjs';

import { Pnr, Ticket } from 'booking';
import { Configuration } from 'configuration';
import { BookingDB } from 'database';
import { Employee } from 'employee';
import { FlightSearch } from 'flightSearch';

import { IConfigurationSlice } from '../slice/configurationSlice';

import {
  REGEX,
  BOOKING_STATUS,
  BOOKING_UNUC_TYPE,
  DATE_FORMAT,
  FLIGHT_AVAILABILITY_STATUS,
  LABEL_CATEGORY,
  MANDATORY_BOOKING_FIELDS,
  SEAT_CLASS,
  SEAT_CLASS_OPTIONS,
  SEAT_CLASS_TYPE_MAPPING,
  PAYMENT_CONCEPT,
  PAYMENT_MEDIUM,
  HKG,
  TRAVELLER_TYPE,
  DATE_UNIT,
  DATE_UNIT_CODE,
  TRAVEL_PURPOSE,
  TRAVEL_PURPOSE_TOGGLE,
  HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
  NOMINATED_PASSENGER_AGE_TYPE,
  BOOKING_CLASS,
  TRAVEL_TYPES,
  PREPAID_STATUS,
  CARRIER_OPTION_MAP_TRANSLATION,
  ID_TICKET_TYPE,
  AIRPORT_CODE,
  RETRIEVE_AND_PAY_COMPLETED_ISSUED_TICKETS_STATUS,
  FARE_TYPES,
  USER_PROFILE_TYPE,
} from '../constants/constants';

import en from '../translations/en';

import {
  IClassAvailability,
  IConcessionOption,
  IDetailsInfoProps,
  IDocsDoca,
  IFilterData,
  IFlightSectors,
  IKeyValue,
  INominatedPassenger,
  IPassportDetails,
  ISelectedFlight,
  ITravellerDetails,
  ITravellerName,
  IUserConcessionProps,
  ITravelDetail,
  ILeisureTravelDetail,
  ISeatClassOption,
  ICompanionInfoDetails,
  ICompanionPassportDetails,
} from '../interfaces';

import {
  findDropdownOptionClient,
  formatDateAsString,
  getDisplayLabel,
  compareDate,
  constructFlightSectors,
  stringAlphabeticSort,
  checkNominatedPassengers,
  sort,
  getInputDateAdding,
  getLocalDateAdding,
} from '.';

import { FirstSeatIcon, BusinessSeatIcon, EconomySeatIcon } from '../assets/images';

import { setLeisureTravelDetails } from '../slice/bookingSlice';
import { setIsOpenBillingAgreementDialog } from '../slice/paypalSlice';

import { showErrorAlert } from '../services/api';
import { getNominatedPassengerListLt } from '../services/booking';

import { IPaymentMethodIndicator } from '../hooks/payment';

import { ExpandedConcessionDetails } from '../containers';

const calcStops = (numberOfStops: number) => {
  let text = '';
  if (numberOfStops === 0) {
    text = en.booking.searchFlight.nonStop;
  } else if (numberOfStops === 1) {
    text = en.booking.searchFlight.oneStop;
  } else {
    text = `${numberOfStops} ${en.booking.searchFlight.stops}`;
  }

  return text;
};

const formatPrice = (price?: number | null, decimal = 2): string => {
  if (isNull(price) || isUndefined(price) || isNaN(price)) {
    return '';
  }

  return price.toFixed(decimal);
};

const getTicketNumArr = (tickets: { [ticketNo: string]: Pnr.Ticket }, segRefNum: string): string[] => {
  return Object.entries(tickets)
    .filter(([, ticketObj]) => {
      return ticketObj.segRefNum === segRefNum;
    })
    .map(([key]) => key);
};

const getDisplayVal = (param?: any) => param || en.common.empty;
const getDebtorCode = (param: IConfigurationSlice['configurations'], profile: Employee.Profile | null) =>
  profile ? param.companies.find((item: any) => item.code === profile.hiringCompany)?.debtorCode || '' : '';

const getSeatClass = (travelClass: string[] | string | undefined, byPassGetDisplayVal = false) => {
  const travelClassCode = isArray(travelClass) && travelClass.length > 0 ? travelClass[0] : travelClass;

  const seatClassLbl = SEAT_CLASS_OPTIONS.find((item) => item.val === travelClassCode)?.title;

  return byPassGetDisplayVal ? seatClassLbl || travelClass : getDisplayVal(seatClassLbl);
};

const getSeatClassImage = (travelClass: string): FunctionComponent<SVGProps<SVGSVGElement>> => {
  switch (travelClass) {
    case SEAT_CLASS.first:
      return FirstSeatIcon;
    case SEAT_CLASS.business:
      return BusinessSeatIcon;
    case SEAT_CLASS.econ:
      return EconomySeatIcon;
    default:
      return EconomySeatIcon;
  }
};

const getCabinClassColor = (travelClass: string): CSSProperties['color'] => {
  const theme = useTheme();

  switch (travelClass) {
    case SEAT_CLASS.first:
      return theme.color.cabin_class.first.option_4;
    case SEAT_CLASS.business:
      return theme.color.cabin_class.business.option_4;
    case SEAT_CLASS.econ:
      return theme.color.cabin_class.economy.option_4;
    case SEAT_CLASS.premiumEcon:
      return theme.color.cabin_class.premium_economy.option_4;
    default:
      return theme.color.secondary.grey.option_4;
  }
};

const getBaggage = (baggages: Ticket.Baggage[]) => {
  const baggage = {
    value: 0,
    unit: '',
    desc: '',
  };
  if (baggages.length === 1) {
    baggage.value = baggages[0].quantity;
    baggage.unit = baggages[0].unit;
  }
  if (baggages.length === 2) {
    const pc = baggages.find((i) => i.unit === 'PC');
    const otherUnit = baggages.find((i) => i.unit !== 'PC');
    if (pc && otherUnit) {
      baggage.value = pc.quantity;
      baggage.unit = pc.unit;
      baggage.desc = `${en.booking.confirmation.ticketDetails.baggage.start} ${otherUnit.quantity}${otherUnit.unit} ${en.booking.confirmation.ticketDetails.baggage.end}`;
    }
  }
  return baggage;
};

const isLevelZEmployee = (userProfile: Employee.Profile | null) => {
  return userProfile && userProfile.activeBenefitDTJobLevel === 'Z';
};

const constructBookingDetailsKeyValues = ({
  passengerDetails,
  configurations,
  contactInformation,
  dutyTravel,
  isLevelZUser,
  docsDoca,
}: {
  passengerDetails?: Pnr.Passenger;
  configurations: Configuration.ConfigurationsClient;
  contactInformation?: {
    phone: string | null;
    email: string | null;
  };
  dutyTravel?: BookingDB.DutyTravel;
  isLevelZUser?: boolean;
  docsDoca?: IDocsDoca;
}) => {
  const { docs: isPassportMandatory = false } = docsDoca || {};
  const { passport } = passengerDetails || {};
  const { email, phone } = contactInformation || {};
  const {
    endorsingStaff,
    endorsingStaffMaskedName,
    purpose,
    description,
    costCentre,
    projectCode,
    generalLedgerAccount,
    paymentMode,
    debtorCode,
  } = dutyTravel?.travelDetail || {};

  const { flightConfirmation } = en.booking;
  const { travelDetails } = flightConfirmation || {};
  const {
    travellerDetails: travellerDetailsLbl,
    passportDetails: passportDetailsLbl,
    contactDetails: contactDetailsLbl,
    dutyTravelDetails: dutyTravelDetailsLbl,
    paymentDetails: paymentDetailsLbl,
    usAddress: usAddressLbl,
  } = travelDetails || {};

  const travellerDetailsData: IDetailsInfoProps[] = useMemo(
    () => [
      {
        key: travellerDetailsLbl.travellerName,
        value: passengerDetails && getDisplayName(passengerDetails),
        singleRow: true,
      },
      {
        key: travellerDetailsLbl.countryOfResidential,
        value: findDropdownOptionClient(configurations.countries, passengerDetails?.countryOfResidential)?.label,
        singleRow: true,
      },
    ],
    [passengerDetails],
  );

  const usAddressData = useMemo(
    () => [
      {
        key: usAddressLbl.street,
        value: getDisplayVal(passengerDetails?.usAddress?.street),
        singleRow: true,
      },
      {
        key: usAddressLbl.city,
        value: getDisplayVal(passengerDetails?.usAddress?.city),
        singleRow: true,
      },
      {
        key: usAddressLbl.state,
        value: getDisplayVal(passengerDetails?.usAddress?.state),
        singleRow: true,
      },
      {
        key: usAddressLbl.zipCode,
        value: getDisplayVal(passengerDetails?.usAddress?.zipCode),
        singleRow: true,
      },
    ],
    [passengerDetails?.usAddress],
  );

  const passportDetailsData = useMemo(
    () =>
      passport
        ? [
            {
              key: passportDetailsLbl.passportNumber,
              value: getDisplayVal(passport.passportNumber),
              singleRow: true,
            },
            ...(isPassportMandatory
              ? [
                  {
                    key: passportDetailsLbl.dateOfBirth,
                    value: formatDateAsString(getDisplayVal(passport.dateOfBirth), DATE_FORMAT.ddmmmyyyy),
                  },
                  {
                    key: passportDetailsLbl.expiryDate,
                    value: formatDateAsString(getDisplayVal(passport.expiryDate), DATE_FORMAT.ddmmmyyyy),
                  },
                  {
                    key: passportDetailsLbl.gender,
                    value: getDisplayVal(passport.gender),
                    singleRow: true,
                  },
                  {
                    key: passportDetailsLbl.nationality,
                    value: getDisplayVal(passport.nationality),
                    singleRow: true,
                  },
                  {
                    key: passportDetailsLbl.issuingCountry,
                    value: getDisplayVal(passport.issuingCountry),
                    singleRow: true,
                  },
                ]
              : []),
          ]
        : [
            {
              key: passportDetailsLbl.passportNumber,
              value: getDisplayVal(),
              singleRow: true,
            },
          ],
    [passport],
  );

  const contactDetailsData: IDetailsInfoProps[] = useMemo(
    () => [
      {
        key: contactDetailsLbl.phoneNumber,
        value: getDisplayVal(phone),
        singleRow: true,
      },
      {
        key: contactDetailsLbl.email,
        value: getDisplayVal(email),
        singleRow: true,
      },
    ],
    [contactInformation],
  );

  const dutyTravelDetailsData: IDetailsInfoProps[] = useMemo(
    () => [
      ...(!isLevelZUser
        ? [
            {
              key: dutyTravelDetailsLbl.endorserGalaCXyId,
              value:
                typeof endorsingStaffMaskedName !== 'undefined'
                  ? getDisplayVal(endorsingStaffMaskedName)
                  : getDisplayVal(endorsingStaff),
            },
          ]
        : []),
      {
        key: dutyTravelDetailsLbl.dutyTravelPurpose,
        value: getDisplayVal(purpose),
      },
      {
        key: dutyTravelDetailsLbl.travelDescription,
        value: getDisplayVal(description),
        singleRow: true,
      },
    ],
    [endorsingStaff, purpose, description],
  );

  const paymentDetailsData: IDetailsInfoProps[] = useMemo(() => {
    return [
      {
        key: paymentDetailsLbl.projectCode,
        value: getDisplayVal(projectCode),
      },
      ...(paymentMode
        ? [
            {
              key: en.booking.travelDetail.paymentMode,
              value: getDisplayVal(paymentMode),
            },
          ]
        : [
            {
              key: paymentDetailsLbl.costCentre,
              value: getDisplayVal(costCentre),
            },
          ]),
      {
        key: paymentMode ? en.booking.travelDetail.debtorCode : paymentDetailsLbl.accountCode,
        value: paymentMode ? getDisplayVal(debtorCode) : getDisplayVal(generalLedgerAccount),
      },
    ];
  }, [projectCode, costCentre, generalLedgerAccount, paymentMode, debtorCode, configurations]);

  return {
    passportDetailsData,
    travellerDetailsData,
    contactDetailsData,
    dutyTravelDetailsData,
    paymentDetailsData,
    usAddressData,
  };
};

const constructTicketDetailsKeyValues = ({
  configurations,
  latestTicket,
  flownTicket,
}: {
  configurations: Configuration.ConfigurationsClient;
  latestTicket?: Pnr.Ticket;
  flownTicket?: Pnr.FlownTicket;
}) => {
  const { confirmation, flightConfirmation } = en.booking;
  const { travelDetails } = flightConfirmation || {};
  const { ticketDetails: ticketDetailsText } = confirmation || {};
  const { fareDetails: fareDetailsText } = flightConfirmation || {};

  const { flownDetails: flownDetailsLbl } = travelDetails || {};

  const getTicketDetails = () => {
    if (!latestTicket) {
      return [];
    }

    const baggage = getBaggage(latestTicket.baggage);

    return [
      {
        key: confirmation.ticketNumber,
        value: formatTicketNumForDisplay(latestTicket.ticketNum),
      },
      {
        key: fareDetailsText.baseFare,
        value: getDisplayVal(formatPrice(latestTicket.fare?.baseFare)),
        remark: latestTicket.fare?.currency,
      },
      {
        key: ticketDetailsText.dateOfIssue,
        value: getDisplayVal(formatDateAsString(latestTicket.issueDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date)),
      },
      {
        key: fareDetailsText.tax,
        value: getDisplayVal(formatPrice(latestTicket.fare?.totalTax)),
        remark: latestTicket.fare?.currency,
      },
      {
        key: fareDetailsText.travellerType,
        value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.travelerType, latestTicket.paxObj?.type),
      },
      {
        key: fareDetailsText.total,
        value: getDisplayVal(formatPrice(latestTicket.fare?.totalAmount)),
        remark: latestTicket.fare?.currency,
      },
      {
        key: ticketDetailsText.couponStatus,
        value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.ticketCouponStatus, latestTicket.status),
        singleRow: true,
      },
      {
        key: ticketDetailsText.baggageAllowance,
        value: baggage.value || en.common.empty,
        unit: baggage.unit,
        remark: baggage.desc,
        singleRow: true,
      },
    ];
  };

  const getFlownDetails = () => {
    if (!flownTicket) {
      return [];
    }

    return [
      {
        key: flownDetailsLbl.processDate,
        value: getDisplayVal(
          formatDateAsString(flownTicket.flownProcessCompletedAt, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.dateTime),
        ),
        singleRow: true,
      },
      {
        key: flownDetailsLbl.actualClass,
        value: getDisplayVal(SEAT_CLASS_TYPE_MAPPING[flownTicket.flownUpliftRecord.flownClass]),
        singleRow: true,
      },
      {
        key: confirmation.ticketNumber,
        value: formatTicketNumForDisplay(flownTicket.ticketNum),
      },
      {
        key: fareDetailsText.baseFare,
        value: getDisplayVal(formatPrice(flownTicket.fare?.baseFare)),
        remark: flownTicket.fare?.currency,
      },
      {
        key: flownDetailsLbl.actualDate,
        value: getDisplayVal(
          formatDateAsString(flownTicket.flownUpliftRecord.flownDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date),
        ),
      },
      {
        key: fareDetailsText.tax,
        value: getDisplayVal(formatPrice(flownTicket.fare?.totalTax)),
        remark: flownTicket.fare?.currency,
      },
      {
        key: flownDetailsLbl.actualFlight,
        value: getDisplayVal(
          `${flownTicket.flownUpliftRecord.carrier}${flownTicket.flownUpliftRecord.flightNo.replace(/^0+/, '')}`,
        ),
      },
      {
        key: fareDetailsText.total,
        value: getDisplayVal(formatPrice(flownTicket.fare?.totalAmount)),
        remark: flownTicket.fare?.currency,
      },
    ];
  };

  return {
    ticketDetails: getTicketDetails(),
    flownDetails: getFlownDetails(),
  };
};

export function getLastDepartureDate(flightSectors: IFlightSectors[]): string | undefined {
  const sortedDepartureDates = flightSectors
    .map((sector) => sector.departureDate)
    .sort()
    .reverse();

  return sortedDepartureDates && sortedDepartureDates.length > 0 ? sortedDepartureDates[0] : undefined;
}

const getSeatDisplayByAvailability = (availability?: string) => {
  const { notAvail: notAvailLbl, confirm: confirmLbl, waitList: waitlistLbl } = en.booking.flightClassStatus;

  switch (availability) {
    case FLIGHT_AVAILABILITY_STATUS.notAvailable:
      return notAvailLbl;
    case FLIGHT_AVAILABILITY_STATUS.others:
      return en.common.empty;
    case FLIGHT_AVAILABILITY_STATUS.confirm:
      return confirmLbl;
    case FLIGHT_AVAILABILITY_STATUS.waitlist:
      return waitlistLbl;
    default:
      return en.common.empty;
  }
};

const getSeatClassDetails = (
  availability: FlightSearch.FlightAvailability,
  restriction?: FlightSearch.FlightRestriction,
): IClassAvailability[] =>
  Object.entries(availability).map(([k, v]) => {
    return {
      type: SEAT_CLASS[k as keyof FlightSearch.FlightAvailability],
      availability: v,
      restriction: restriction ? restriction[k as keyof FlightSearch.FlightRestriction] : false,
    };
  });

// get highest available class
const getDefaultSeatClassSelection = (flightAvailability: IClassAvailability[]): IClassAvailability | undefined => {
  // (1) return hightest class with confirmed status
  const highestConfirmedClass = flightAvailability.find(
    ({ availability, restriction }: IClassAvailability) =>
      !restriction && availability && availability === FLIGHT_AVAILABILITY_STATUS.confirm,
  );

  if (highestConfirmedClass) {
    return highestConfirmedClass;
  }

  // (2) return hightest class with wait list status
  const highestWaitListClass = flightAvailability.find(
    ({ availability, restriction }: IClassAvailability) =>
      !restriction && availability && availability === FLIGHT_AVAILABILITY_STATUS.waitlist,
  );

  if (highestWaitListClass) {
    return highestWaitListClass;
  }

  // (3) return empty class
  return;
};

const getColorOfSeatClassStatus = (flightClassStatus: string) => {
  const theme = useTheme();

  return flightClassStatus === en.booking.flightClassStatus.confirm
    ? theme.color.primary.cathay_jade.option_4
    : theme.color.secondary.dark_grey.option_1;
};

const getDisplayName = (name: ITravellerName): string => {
  const { title, firstName, lastName, middleName } = name;
  return [title, firstName, middleName, lastName]
    .filter((i) => i)
    .join(' ')
    .trim();
};

const getFullDisplayName = (name: ITravellerName): string => {
  const { preferredFirstName, firstName, middleName, lastName } = name || {};
  if (lastName) {
    return `${preferredFirstName ?? firstName ?? middleName ?? ''} ${lastName}`.trim();
  } else {
    return '';
  }
};

const getShortDisplayName = (name: ITravellerName): string => {
  const { preferredFirstName, firstName, middleName, lastName } = name || {};
  return preferredFirstName || firstName || middleName || lastName || '';
};

const getPassengerDisplayName = (name: ITravellerName): string => {
  return `${name?.lastName || ''} / ${name?.firstName || ''}`;
};

const getPersonalDisplayName = (name: ITravellerName): string => {
  const { firstName, lastName, middleName } = name;
  return [lastName, firstName, middleName]
    .filter((i) => i)
    .join(' ')
    .trim();
};

const getAdminPersonalDisplayName = (name: ITravellerName): string => {
  const { firstName, lastName, middleName } = name;
  const tempLastName = isEmpty(lastName) ? lastName : lastName + ',';
  return [tempLastName, firstName, middleName]
    .filter((i) => i)
    .join(' ')
    .trim();
};

const getPHubDependentDisplayName = ({
  passportInfo,
  personalInfo,
}: {
  passportInfo?: ICompanionPassportDetails;
  personalInfo?: ICompanionInfoDetails;
}) => {
  const { passportFirstName, passportLastName } = passportInfo || {};
  const { firstName, lastName } = personalInfo || {};
  let names = [];

  if (passportFirstName || passportLastName) {
    names = [passportFirstName, passportLastName];
  } else {
    names = [firstName, lastName];
  }

  return names
    .filter((i) => i)
    .join(' ')
    .trim();
};

const isSomePassportDetailsMissing = (passportDetails: IPassportDetails | null): boolean => {
  if (!passportDetails) {
    return false;
  }

  const passportEssentialDetails = pick(passportDetails, MANDATORY_BOOKING_FIELDS.passport);

  const noOfFilled = Object.values(passportEssentialDetails).reduce((acc, cur) => (cur ? ++acc : acc), 0);

  return noOfFilled > 0 && noOfFilled < MANDATORY_BOOKING_FIELDS.passport.length;
};

const formatTicketNumForDisplay = (ticketNum: string) => ticketNum.slice(0, 3) + en.common.hyphen + ticketNum.slice(3);

const getUnUcData = (
  bookingStatus: string,
  ticketsBySegment: Pnr.TicketBySegment[],
): {
  type: string;
  originalFlightNumber: string;
  inactiveSegObj: Pnr.Segment;
} | null => {
  const firstTicketsBySegment = ticketsBySegment?.[0] || {};

  const { segRefNum, inactiveSegObj } = firstTicketsBySegment;

  if (inactiveSegObj) {
    const { flightNum: originalFlightNum, carrier: originalCarrier } = inactiveSegObj;

    const originalFlightNumber = `${originalCarrier}${originalFlightNum}`;

    if (bookingStatus === BOOKING_STATUS.cancelled && !segRefNum) {
      return {
        type: BOOKING_UNUC_TYPE.noProtection,
        originalFlightNumber,
        inactiveSegObj,
      };
    }

    if (bookingStatus === BOOKING_STATUS.protectedDifferentCarrier) {
      return {
        type: BOOKING_UNUC_TYPE.protectedDifferentCarrier,
        originalFlightNumber,
        inactiveSegObj,
      };
    }

    if (bookingStatus === BOOKING_STATUS.booked) {
      return {
        type: BOOKING_UNUC_TYPE.protected,
        originalFlightNumber,
        inactiveSegObj,
      };
    }
  }

  return null;
};

const getTicketsBySegment = (status: string, ticketsBySegment: Pnr.TicketBySegment[]): Pnr.TicketBySegment[] => {
  const unUcData = getUnUcData(status, ticketsBySegment);

  if (
    unUcData &&
    [BOOKING_UNUC_TYPE.noProtection, BOOKING_UNUC_TYPE.protectedDifferentCarrier]?.includes(unUcData.type)
  ) {
    return [{ ...unUcData.inactiveSegObj, tickets: [] }];
  }

  return ticketsBySegment;
};

const isFlightInfoGreyOut = ({
  bookingStatus,
  unUcType,
  isViewBookingDetails,
  isOriginalFlightForUnUc,
}: {
  bookingStatus: string;
  unUcType?: string;
  isViewBookingDetails?: boolean;
  isOriginalFlightForUnUc?: boolean;
}): boolean => {
  if (isOriginalFlightForUnUc) {
    return true;
  }

  if (unUcType && isViewBookingDetails && [BOOKING_UNUC_TYPE.protectedDifferentCarrier]?.includes(unUcType)) {
    return true;
  }

  return [
    BOOKING_STATUS.cancelled,
    BOOKING_STATUS.flown,
    BOOKING_STATUS.notRefund,
    BOOKING_STATUS.refunded,
    BOOKING_STATUS.original,
  ]?.includes(bookingStatus);
};

const getAdminBookingEmployeeErn = (travellerDetails: ITravellerDetails | null) => {
  const { travellerInfo } = travellerDetails || {};
  const { employee } = travellerInfo || {};
  const { ern } = employee || {};

  return ern;
};

// find the max day from LT, DT data
const getWholeDTLTMaxAdvanceDay = ({
  dtConcession = null,
  ltConcession = null,
}: {
  dtConcession: IUserConcessionProps[] | null;
  ltConcession: IUserConcessionProps[] | null;
}) => {
  let returnObj: {
    value: number | undefined;
    unit: string | undefined;
    actualDate: Date | null;
  } = {
    value: 0,
    unit: '',
    actualDate: null,
  };

  /* 
    DT

    Remark:
    only have `advanceBookingDays`, default is days unit.
   */
  const maxDTAdvanceDay = getDTMaxAdvanceDay({
    concession: dtConcession,
  });
  const dtActualDate = getActualAdvanceBookingDateByUnit({
    quantity: maxDTAdvanceDay,
    unitCode: DATE_UNIT_CODE.day,
  });

  /* 
    LT

    Remark:
    for the unit included: DYS, YRS, MON
   */
  const maxLTAdvanceDay = getLTMaxAdvanceDay({
    concession: ltConcession,
  });
  const ltActualDate = maxLTAdvanceDay?.actualDate;

  // compare DT, LT day
  if (dtActualDate && ltActualDate) {
    const result = compareDate(
      formatDateAsString(ltActualDate, DATE_FORMAT.date),
      formatDateAsString(dtActualDate, DATE_FORMAT.date),
      DATE_FORMAT.date,
      DATE_FORMAT.date,
    );
    // result > 0. use LT, else use DT max days.
    const isUseLT = result > 0;
    returnObj = {
      value: isUseLT ? maxLTAdvanceDay?.value : maxDTAdvanceDay,
      unit: isUseLT ? maxLTAdvanceDay?.unit || DATE_UNIT.DYS : DATE_UNIT.DYS,
      actualDate: (isUseLT ? ltActualDate : dtActualDate) || null,
    };
  } else {
    // either one of the LT/DT concession exist, cause one of concession may empty.
    returnObj = {
      value: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
      // Commented on 11 Apr, 2023 because BU think this logic is too complex and will require extra effort to test
      // maxLTAdvanceDay?.value ||
      // maxDTAdvanceDay ||
      // HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
      unit: maxLTAdvanceDay?.unit || DATE_UNIT.DYS,
      actualDate: ltActualDate || dtActualDate || null,
    };
  }

  return returnObj;
};

const getDTMaxAdvanceDay = ({ concession = [] }: { concession: IUserConcessionProps[] | null }) => {
  if (!concession) {
    return 0;
  }
  const maxAdvanceDay = Math.max(...concession.map((_) => (_.advanceBookingDays ? _.advanceBookingDays : 0)));

  return maxAdvanceDay;
};

const getActualAdvanceBookingDateByUnit = ({
  inputDate,
  quantity,
  unitCode,
}: {
  inputDate?: string | Date;
  quantity: number;
  unitCode: string;
}) => {
  const unit = DATE_UNIT?.[unitCode as keyof typeof DATE_UNIT] as ManipulateType;
  if ((quantity === undefined && !unitCode) || !unit) return null;

  if (inputDate) getInputDateAdding(inputDate, quantity, unit);

  return getLocalDateAdding(quantity, unit);
};

const getLTMaxAdvanceDay = ({ concession = [] }: { concession: IUserConcessionProps[] | null }) => {
  if (!concession?.length) {
    return {
      value: 0,
      unit: null,
      actualDate: null,
    };
  }
  // transform all advance booking `value` + `unit` to be Date type in array
  const transformedDateArr = concession.map((_) => {
    const unitCode = _?.concessionDef?.advanceBookingDay?.unit;
    const quantity = _?.concessionDef?.advanceBookingDay?.value;
    if (unitCode && quantity) {
      const actualDate = getActualAdvanceBookingDateByUnit({
        quantity,
        unitCode,
      });
      return actualDate?.valueOf() || 0;
    }
    return 0;
  });
  const maxDate = Math.max(...transformedDateArr);
  const maxDateItemIndex = transformedDateArr.indexOf(maxDate);
  const currentConcessionItem = concession?.[maxDateItemIndex];

  let actualDate; // actual max advance booking date
  let maxAdvanceValue; // the max advance booking day|year|month value
  let maxAdvanceUnit; // the max advance booking unit
  let unitCode;
  const currentAdvanceBookingDay = currentConcessionItem?.concessionDef?.advanceBookingDay;
  if (currentAdvanceBookingDay) {
    maxAdvanceValue = currentAdvanceBookingDay?.value;
    unitCode = currentAdvanceBookingDay?.unit;
    maxAdvanceUnit = DATE_UNIT?.[unitCode as keyof typeof DATE_UNIT];
    actualDate = getActualAdvanceBookingDateByUnit({
      quantity: maxAdvanceValue,
      unitCode: unitCode,
    });
  }

  return {
    value: maxAdvanceValue,
    unit: maxAdvanceUnit,
    actualDate: actualDate,
  };
};

const isSomeTravellerAvailable = (travellers: INominatedPassenger[] | INominatedPassenger): boolean => {
  if (Array.isArray(travellers)) {
    return travellers.some((traveller: INominatedPassenger) => {
      return (
        (typeof traveller?.validationInfo?.restriction === 'string' && traveller?.validationInfo?.restriction === '') ||
        (typeof traveller?.validationInfo?.restriction === 'object' &&
          traveller?.validationInfo?.restriction.length === 0)
      );
    });
  } else if (typeof travellers === 'object') {
    return (
      (typeof travellers?.validationInfo?.restriction === 'string' && travellers?.validationInfo?.restriction === '') ||
      (typeof travellers?.validationInfo?.restriction === 'object' &&
        travellers?.validationInfo?.restriction.length === 0)
    );
  }
  return false;
};

const checkLtIsExceedAdvanceBookingDay = ({
  startDate,
  advanceBookingDays,
  advanceBookingDayUnitCode = DATE_UNIT.DYS,
}: {
  startDate: string;
  advanceBookingDays: number;
  advanceBookingDayUnitCode?: string;
}) => {
  const actualDate = getActualAdvanceBookingDateByUnit({
    quantity: advanceBookingDays,
    unitCode: advanceBookingDayUnitCode,
  });
  if (actualDate) {
    const comparedDate = compareDate(
      formatDateAsString(startDate, DATE_FORMAT.date),
      formatDateAsString(actualDate, DATE_FORMAT.date),
      DATE_FORMAT.date,
      DATE_FORMAT.date,
    );
    return comparedDate > 0;
  }
  return false;
};

const sortConcessionForRule = (concessionList: IConcessionOption[]) => {
  if (!concessionList) return [];
  const eligibleList = concessionList
    ?.filter((item: IConcessionOption) => !item.disabled)
    .sort((a: IConcessionOption, b: IConcessionOption) => stringAlphabeticSort(a.label, b.label))
    .sort(
      (a: IConcessionOption, b: IConcessionOption) =>
        parseInt(a.boardingPriority || '') - parseInt(b.boardingPriority || ''),
    );
  const nonEligibleList = concessionList
    ?.filter((item: IConcessionOption) => item.disabled)
    .sort((a: IConcessionOption, b: IConcessionOption) => stringAlphabeticSort(a.label, b.label))
    ?.sort(
      (a: IConcessionOption, b: IConcessionOption) =>
        parseInt(a.boardingPriority || '') - parseInt(b.boardingPriority || ''),
    );
  return [...eligibleList, ...nonEligibleList];
};

const handlePaymentAndFlownSuspensionMessageAction = ({
  paymentMethodIndicator,
  isFromBookOneWay = false,
}: {
  paymentMethodIndicator: IPaymentMethodIndicator;
  isFromBookOneWay?: boolean;
}) => {
  const myCasesLinkPrefix = en.payment.directDebit.myCasesLink;

  if (!paymentMethodIndicator) return;

  // Prepaid -> flown suspension warning message
  // Remark: Not for book one way case
  if (!isFromBookOneWay && paymentMethodIndicator?.isSuspensionStatus && window.config?.flownSuspensionMoreDetailLink) {
    window.open(window.config.flownSuspensionMoreDetailLink);
    return;
  }

  // Direct debit - directDebitStatus is null, and redirect to ETP portal website
  if (
    paymentMethodIndicator?.paymentMedium === PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT &&
    window.config?.employeeTravelPortalLink &&
    paymentMethodIndicator?.replaceTarget !== myCasesLinkPrefix
  ) {
    window.open(window.config.employeeTravelPortalLink);
  }

  // [ETP-4526] Direct debit - directDebitStatus is 'N', and redirect to myCases site.
  if (
    paymentMethodIndicator?.paymentMedium === PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT &&
    window.config?.myCasesLink &&
    paymentMethodIndicator?.replaceTarget === myCasesLinkPrefix
  ) {
    window.open(window.config.myCasesLink);
  }
};

const handleBookOneWayOnClick = async ({
  isLt = false,
  ern,
  startDate,
  outwardFlight,
  leisureTravelDetails,
  concession = [],
  handleFooterClick,
  dispatch,
  paymentMethodIndicator,
  regulatoryRegion,
  isShowNonEnglishCharacter,
  isDelegationRole = false,
  concessionRuleId,
  concessionId,
  travellerDetails,
  profile,
}: {
  isLt: boolean;
  ern: string;
  startDate: string;
  outwardFlight: ISelectedFlight | null;
  leisureTravelDetails: ILeisureTravelDetail;
  concession: IUserConcessionProps[] | [] | null;
  handleFooterClick?: () => void;
  dispatch: Dispatch;
  paymentMethodIndicator: IPaymentMethodIndicator;
  regulatoryRegion: string;
  isShowNonEnglishCharacter?: boolean;
  isDelegationRole?: boolean;
  concessionRuleId?: string;
  concessionId?: string;
  travellerDetails?: ITravellerDetails | null;
  profile?: Employee.Profile | null;
}) => {
  const {
    isShowWarning,
    isSuspensionStatus,
    paymentConcept,
    paymentMedium,
    paymentValidated,
    isDirectDebitSetupSuspended,
  } = paymentMethodIndicator;
  const isShowPaymentIndicatorDialog = isShowWarning && !isSuspensionStatus; // ETP-2431 // ETP-2431

  if (isLt) {
    let title = '';
    let message = '';
    const isDetailsEmpty = !paymentConcept || !paymentMedium;
    const isPaypal = paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT;
    const isDirectDebit = paymentMedium === PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT;
    if (isDetailsEmpty) {
      title = en.payment.detailsIncomplete.warningTitle;
      if (regulatoryRegion === HKG) {
        message = `${en.payment.detailsIncomplete.commonWarning.base} ${en.payment.detailsIncomplete.commonWarning.HKGEmployee}`;
      } else {
        message = `${en.payment.detailsIncomplete.commonWarning.base} ${en.payment.detailsIncomplete.commonWarning.outportEmployee}`;
      }
    }
    if (isPaypal) {
      title = en.payment.paypal.warningTitle;
      message = en.payment.paypal.commonWarning;

      // [ETP-4266] checked paymentValidated is `null` value, show generic message
      if (paymentValidated === null) {
        title = en.payment.paypal.unableRetrievePaypalDetailTitle;
        message = en.payment.paypal.unableRetrievePaypalDetailWarning;
      }
    }
    if (isDirectDebit) {
      if (isDirectDebitSetupSuspended) {
        title = en.payment.directDebit.rejectedWarningTitle;
        message = en.payment.directDebit.rejectedWarning;
      } else {
        title = en.payment.directDebit.warningTitle;
        message = en.payment.directDebit.commonWarning;
      }
    }

    if (
      profile &&
      (isRetireeWidow(profile) || isRetireeSpecialProfile(profile)) &&
      isEmpty(profile.personalInfo?.personalEmail)
    ) {
      showErrorAlert({
        title: en.error.personalEmailMissing,
        message: en.userProfile.personal.personalEmailWarning,
        noErrorAlert: true,
      });
      return;
    }

    if (isShowPaymentIndicatorDialog) {
      if (isDetailsEmpty) {
        showErrorAlert({
          title,
          message,
          noErrorAlert: true,
        });
        return;
      }

      showErrorAlert({
        title,
        message,
        isShowCommonWarning: true,
        commonWarningActionFunc: () => {
          // Paypal flow - open billing agreement dialog
          if (paymentMethodIndicator?.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT) {
            dispatch(setIsOpenBillingAgreementDialog(true));
          } else {
            handlePaymentAndFlownSuspensionMessageAction({
              paymentMethodIndicator,
              isFromBookOneWay: true,
            });
          }
        },
        commonWarningReplaceTarget: paymentMethodIndicator.replaceTarget,
        noErrorAlert: true,
      });
      return;
    }
    // if user chose SUBLO, then use SUBLO logic (calculate largest advance booking day)
    // SUBLO => check SUBLO list and calculate largest advance booking day
    // if user chose NOSUB, then use the NOSUB’s concession’s advance booking day
    // NOSUB => get chose NOSUB item the advance booking day
    let maxAdvanceDay;
    const SUBLOTravelOption: IUserConcessionProps[] | undefined = concession?.filter(
      (item: IUserConcessionProps) => item.concessionDef?.travelType === ID_TICKET_TYPE.SUBLO,
    );
    if (leisureTravelDetails.sub === ID_TICKET_TYPE.SUBLO) {
      //SUBLO logic
      maxAdvanceDay = getLTMaxAdvanceDay({
        concession: SUBLOTravelOption,
      } as any);
    } else {
      // NOSUB logic
      const maxAdvanceValue = leisureTravelDetails?.advanceBookingDay?.value || 0;
      const unitCode = leisureTravelDetails?.advanceBookingDay?.unit || '';
      const maxAdvanceUnit = DATE_UNIT?.[unitCode as keyof typeof DATE_UNIT];
      const actualDate = getActualAdvanceBookingDateByUnit({
        quantity: maxAdvanceValue,
        unitCode,
      });
      maxAdvanceDay = {
        value: maxAdvanceValue,
        unit: maxAdvanceUnit,
        actualDate,
      };
    }
    if (maxAdvanceDay?.value && maxAdvanceDay?.actualDate && maxAdvanceDay?.unit) {
      // only shown the edit search button on mobile view
      const exceedAdvanceBookingPeriodTranslation = en.booking.selectFlight.exceedAdvanceBookingPeriod;

      const isExceeded =
        compareDate(
          formatDateAsString(startDate, DATE_FORMAT.date),
          formatDateAsString(maxAdvanceDay?.actualDate, DATE_FORMAT.date),
          DATE_FORMAT.date,
          DATE_FORMAT.date,
        ) > 0;

      // check if exceed advance booking period checking
      if (isExceeded) {
        const unitTranslation = exceedAdvanceBookingPeriodTranslation.unit;
        showErrorAlert({
          title: exceedAdvanceBookingPeriodTranslation.title,
          message: exceedAdvanceBookingPeriodTranslation.message.replace(
            REGEX.specificString,
            `${maxAdvanceDay?.value?.toString() || ''} ${
              unitTranslation?.[maxAdvanceDay?.unit as keyof typeof unitTranslation] || unitTranslation.day
            }`,
          ),
          isEnableMobileEditSearchFlag: true,
        });
        return;
      }
    }

    // [ETP-3887] domicile concession - OD restriction
    const selectedConcession = concessionRuleId ? concession?.find((item) => item._id === concessionRuleId) : null;
    if (selectedConcession?.concessionDef?.isDomicile) {
      const { domicileAirportCode } = selectedConcession.concessionDef;
      const { departurePort, arrivalPort } = outwardFlight || {};

      if (!domicileAirportCode || !departurePort || !arrivalPort) {
        showErrorAlert({
          title: en.error.domicileOnlyRestriction,
          message: en.errorAlert.genericMessage,
        });

        return;
      }

      if (
        !(
          (departurePort === AIRPORT_CODE.hongKong && domicileAirportCode?.includes(arrivalPort)) ||
          (domicileAirportCode?.includes(departurePort) && arrivalPort === AIRPORT_CODE.hongKong)
        )
      ) {
        showErrorAlert({
          title: en.error.domicileOnlyRestriction,
          message:
            en.errorAlert.domicileOnlyRestriction.msg1
              .replaceAll('{Origin}', AIRPORT_CODE.hongKong)
              .replaceAll('{Destination}', domicileAirportCode.filter((airportCode) => airportCode).join(', ')) +
            en.errorAlert.domicileOnlyRestriction.msg2,
          noErrorAlert: true,
        });

        return;
      }
    }

    const flightSectors = constructFlightSectors(outwardFlight);

    const nominatedPassengers = await getNominatedPassengerListLt(ern, flightSectors, concessionRuleId);

    if (!nominatedPassengers) return;

    // note: need to sort results by eligible and nonEligible
    const { eligibleTravellers, nonEligibleTravellers } = checkNominatedPassengers(nominatedPassengers) || {};

    const results = eligibleTravellers.concat(nonEligibleTravellers);

    dispatch(
      setLeisureTravelDetails({
        ...leisureTravelDetails,
        originLtTravelers: results.map((traveller: INominatedPassenger, index: number) => {
          return {
            ...traveller,
            isSelected: false,
            isUpdated: false,
            keyIndex: index,
            infantWithSeat: false,
          };
        }),
        ltSelectTravellerList: results.map((traveller: INominatedPassenger, index: number) => {
          return {
            ...traveller,
            keyIndex: index,
          };
        }),
      }),
    );
  } else {
    const title = en.booking.nonEnglishCharacter.title;
    const message = isDelegationRole
      ? en.booking.nonEnglishCharacter.message
      : regulatoryRegion === HKG
      ? en.booking.nonEnglishCharacter.hkg
      : en.booking.nonEnglishCharacter.notHkg;
    if (isShowNonEnglishCharacter) {
      showErrorAlert({
        title,
        message,
      });
      return;
    }

    // [ETP-6023] Routing concept for special LT FOC & DT concessions - DT flow
    // data come from API get user/concession when user search by ERN
    const selectedConcession = concessionId ? concession?.find((item) => item._id === concessionId) : null;

    const originDestinationCode = selectedConcession?.routingODs?.length
      ? selectedConcession.routingODs // ESS DT booking flow
      : travellerDetails?.travellerInfo?.employee?.routingODs; // Admin DT booking flow

    // when there is routing configuration for the concession, the checking will be applied to the booking after clicking "book one way
    if (originDestinationCode?.length) {
      const { departurePort, arrivalPort } = outwardFlight || {};
      if (!originDestinationCode || !departurePort || !arrivalPort) {
        showErrorAlert({
          title: en.errorAlert.getFareTravellerAndRouting,
          message: en.errorAlert.genericMessage,
        });

        return;
      }
      const isValid = originDestinationCode.some(
        (sector) => sector.departurePort === departurePort && sector.arrivalPort === arrivalPort,
      );

      if (!isValid) {
        const errorList: string[] = [];
        const validSectorsList = originDestinationCode
          .map((sector) => `${sector.departurePort} to ${sector.arrivalPort}`)
          .join(', ');
        const displayName = travellerDetails?.travellerInfo?.employee
          ? travellerDetails?.travellerInfo?.employee.name
          : getDisplayName({
              title: profile?.personalInfo?.title || '',
              firstName: profile?.personalInfo?.firstName,
              lastName: profile?.personalInfo?.lastName,
            });
        errorList.push(`${displayName}: valid sectors: ${validSectorsList}.`);
        showErrorAlert({
          title: en.errorAlert.getFareTravellerAndRouting,
          message: [
            'The following traveller(s) are not eligible to the selected sector.',
            ...errorList,
            'Please input valid origin and destination as per the sectors shown.',
          ].join('\n\n'),
        });
        return;
      }
    }
  }

  handleFooterClick && handleFooterClick();
};

const getFlightKey = ({
  marketingCompany,
  flightNo,
  departureDate,
  departurePort,
  arrivalPort,
}: {
  marketingCompany: string;
  flightNo: string;
  departureDate: string;
  departurePort: string;
  arrivalPort: string;
}) => {
  return `${marketingCompany.substring(0, 2).padEnd(2, '-')}${flightNo
    .substring(0, 4)
    .padStart(4, '0')}${departureDate.replace(/-/g, '')}${departurePort}${arrivalPort}`;
};

const getFilterCount = (filters: IFilterData) => {
  return Object.values(filters).filter((value) => {
    if (typeof value === 'string') {
      return value.trim() !== '';
    } else if (Array.isArray(value)) {
      return value.length !== 0;
    } else if (typeof value === 'object') {
      return Object.keys(value).length !== 0 && Object.values(value).every((value) => !!value);
    }
    return false;
  }).length;
};

const getNonEnglishCharacterTravellers = (paxList: INominatedPassenger[], isEmployee: boolean) => {
  return paxList?.find((item: INominatedPassenger) => {
    if (!isEmployee) {
      return item?.validationInfo?.restriction?.includes(en.booking.nonEnglishCharacter.errorMes);
    }
    return (
      item.beneficiaryTypeCode === TRAVELLER_TYPE.employee &&
      item?.validationInfo?.restriction?.includes(en.booking.nonEnglishCharacter.errorMes)
    );
  });
};

const getTravelTypeSelectionList = (isHideLT = false, isHideDT = false) => {
  // hide DT conession
  if (isHideDT) return TRAVEL_PURPOSE_TOGGLE.filter((item) => item.val !== TRAVEL_PURPOSE.employeeDutyTravel);

  // normal flow
  if (!isHideLT) return TRAVEL_PURPOSE_TOGGLE;

  // hide LT concession
  return TRAVEL_PURPOSE_TOGGLE.filter((item) => item.val !== TRAVEL_PURPOSE.employeeLeisureTravel);
};

/*
  For story 2157, 2182

  Hide LT feature when:
  (1) delegatee role
  (2) `isHiddenLtFeatureFlag` is true + empty LT concession data
 */
const isHideLtFeatures = ({ isDelegation = false, isEmptyLtConcession = false }) => {
  const isHiddenLtFeatureFlag = window.config?.isHiddenLtFeatureFlag;

  return isDelegation || (isHiddenLtFeatureFlag && !isDelegation && isEmptyLtConcession);
};

/*
  For story 2344

  check LT if select Zone Sub-load.
 */

const isLtZoneType = (selectedType: string) => {
  return selectedType?.toUpperCase() === TRAVEL_TYPES.ZONE;
};

/*
  For story 2352

  show reminder message feature in:
  (1) LtTraveller Page
  (2) Seat Class Concession Page
 */
const isShowReminderMessage = window.config?.isDisplayEMPTravelWithFamilyConcessionMsg;

const getIsOnlySeatOptionAvailable = (seatConcession: IConcessionOption[]) => {
  return seatConcession.filter((item: IConcessionOption) => item?.disabled === false);
};

const getInvalidFJClassTravellerWarningMessage = (
  invalidAgeFirst: string,
  age: number,
  invalidAgeSecond: string,
  bookingClass: string,
) => `${invalidAgeFirst} ${age} ${invalidAgeSecond} ${bookingClass} class`;

// check traveller type is INF(without seat) or INS(with seat). ref on constants's `NOMINATED_PASSENGER_AGE_TYPE`
const checkInfantTypeIsINSOrINF = (type: keyof typeof NOMINATED_PASSENGER_AGE_TYPE | undefined) => {
  return type === NOMINATED_PASSENGER_AGE_TYPE.infant || type === NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat;
};

const getConcessionForRadioButtonGroup = (options: IUserConcessionProps[], startDate: string) => {
  const { travelType: travelTypeString } = en.booking;
  return options
    .map((concession) => {
      const {
        _id: concessionId,
        name,
        advanceBookingDays,
        boardingPriority,
        bookingClass,
        regradeClass,
      } = concession || {};

      const concessionDetailItems: IKeyValue[] = [
        {
          key: travelTypeString.boardingPriority,
          value: boardingPriority || en.common.empty,
        },
        {
          key: travelTypeString.dtAdvanceBookingDays,
          value: advanceBookingDays || en.common.empty,
        },
        {
          key: travelTypeString.travelClass,
          value: sort(bookingClass?.slice(), BOOKING_CLASS) || [],
        },
        {
          key: travelTypeString.ugsaClass,
          value: getSeatClass(regradeClass),
        },
      ];

      const isInAdvanceBookingDays =
        advanceBookingDays &&
        advanceBookingDays >= 0 &&
        compareDate(startDate, new Date(), DATE_FORMAT.date) + 1 <= advanceBookingDays;

      return {
        id: concessionId,
        val: {
          title: name,
          advanceDay: advanceBookingDays,
        },
        title: name,
        expandedComponent: <ExpandedConcessionDetails concessionDetailItems={concessionDetailItems} />,
        disabledSubtitle: `${travelTypeString.dtAdvanceBookingDays} - ${advanceBookingDays}`,
        subtitle: `${travelTypeString.priority} ${boardingPriority}`,
        isDisabled: !isInAdvanceBookingDays,
      };
    })
    .sort((a, b) => {
      const titleA = a.title.toUpperCase();
      const titleB = b.title.toUpperCase();
      const isDisabledA = a.isDisabled;
      const isDisabledB = b.isDisabled;
      if (titleA < titleB || (isDisabledB && !isDisabledA)) {
        return -1;
      }
      if (titleB < titleA || (isDisabledA && !isDisabledB)) {
        return 1;
      }
      return 0;
    });
};

const getCanPreselectAvailableSeatOption = ({
  bookingClassInConcession,
  outwardFlight,
}: {
  bookingClassInConcession: string[];
  outwardFlight: ISelectedFlight | null;
}) => {
  // preselect Seat option logic
  const seatOptionInConcession = sort(bookingClassInConcession, BOOKING_CLASS).map(
    (item: (typeof BOOKING_CLASS)[number]) => {
      return {
        id: item,
        val: item,
        title: item,
        isDisabled:
          !Object.keys(outwardFlight?.availability || {})?.includes(item) ||
          outwardFlight?.availability?.[item as keyof FlightSearch.IntegratedFlightAvailability] ===
            FLIGHT_AVAILABILITY_STATUS.notAvailable,
      };
    },
  );

  const availableSeatOption =
    seatOptionInConcession?.filter((seatOption: ISeatClassOption) => seatOption.isDisabled === false) || [];

  // Select the last available seat option
  const preselectedSeatOption =
    availableSeatOption.length > 0 ? availableSeatOption[availableSeatOption.length - 1].title : '';
  return preselectedSeatOption;
};

const requireAccompanyForm = (travellerList: INominatedPassenger[]) => {
  let hasChildren = false;
  for (const traveller of travellerList) {
    if (traveller?.validationInfo?.allowAccompany === true) {
      return false;
    } else if (traveller?.age < 12) {
      hasChildren = true;
    }
  }
  return hasChildren;
};

// [flight search] for concession selection
const getConcessionSelectionValue = ({
  isSearchResultPage = false,
  isLT,
  isDT,
  travelDetails,
  leisureTravelDetails,
  isShowDefaultTravelTypeOption = false,
}: {
  isSearchResultPage: boolean;
  isLT: boolean;
  isDT: boolean;
  travelDetails: ITravelDetail;
  leisureTravelDetails: ILeisureTravelDetail;
  isShowDefaultTravelTypeOption?: boolean;
}) => {
  let label = en.booking.searchBar.otherTravelConcession;
  if (isDT) label = travelDetails?.title || '';

  if (isLT) {
    const travelType = leisureTravelDetails?.type || '';

    // FOC/ID50, display the concession `displayName` directly
    if (travelType.toUpperCase() !== TRAVEL_TYPES.ZONE) {
      label = leisureTravelDetails?.label || '';
    } else {
      // ZONE CX, OW/OAL
      const carrierGroupKey =
        CARRIER_OPTION_MAP_TRANSLATION?.[
          leisureTravelDetails.carrierGroup as keyof typeof CARRIER_OPTION_MAP_TRANSLATION
        ];
      const carrierGroupLabel =
        en.booking.carrierGroupLabels?.[carrierGroupKey as keyof typeof en.booking.carrierGroupLabels];
      const ltZoneTravelTypePostfix = ` - ${carrierGroupLabel || ''}`;
      label = `${en.booking.fareType.zone}${(leisureTravelDetails.carrierGroup && ltZoneTravelTypePostfix) || ''}`;
    }

    // [Home] Hidden label when first come home page and no opened the concession + selected concession
    if (!isSearchResultPage && !isShowDefaultTravelTypeOption) label = '';
  }

  return label;
};

const getBookingStatus = ({
  status,
  paymentConcept,
  prepaidStatus,
  prepaidInfo,
}: {
  status?: string;
  paymentConcept?: string | null;
  prepaidStatus?: string | null;
  prepaidInfo?: {
    isRetrieveAndPay: boolean;
  };
}): string => {
  // If paymentConcept or prepaidStatus is null, return the original status.
  if (!paymentConcept || !prepaidStatus) {
    return status || '';
  }

  // Middle process of
  if (status === BOOKING_STATUS.modified && prepaidStatus === BOOKING_STATUS.authPending) {
    return BOOKING_STATUS.retrieveAndPay;
  }

  if (prepaidStatus === BOOKING_STATUS.retrieveAndPay) {
    if (status === BOOKING_STATUS.booked) {
      return prepaidStatus;
    } else if (status === BOOKING_STATUS.cancelled) {
      return status || '';
    }
  }

  // If the booking is 'booked' and payment is 'PREPAID',
  // return the prepaidStatus (rebook, paid)
  if (
    status === BOOKING_STATUS.booked &&
    paymentConcept === PAYMENT_CONCEPT.PREPAID &&
    !prepaidInfo?.isRetrieveAndPay
  ) {
    return prepaidStatus;
  }

  // If the booking is 'cancelled' and payment is 'PREPAID',
  // return the prepaidStatus (not refund, refund)
  if (status === BOOKING_STATUS.cancelled && paymentConcept === PAYMENT_CONCEPT.PREPAID) {
    return prepaidStatus;
  }

  if (status === BOOKING_STATUS.flown && paymentConcept === PAYMENT_CONCEPT.PREPAID) {
    if (prepaidStatus === PREPAID_STATUS.REFUNDED) {
      return prepaidStatus;
    }
    return status || '';
  }

  // New display retrieve and pay logic, Base on:
  // condition 1: the flag "prepaidInfo.isRetrieveAndPay" is true
  // condition 2: NOT include CAPTURE_PENDING, CAPTURE_SUCCESS, CAPTURE_FAILED
  if (
    prepaidInfo?.isRetrieveAndPay &&
    !RETRIEVE_AND_PAY_COMPLETED_ISSUED_TICKETS_STATUS?.includes(prepaidStatus as PREPAID_STATUS)
  ) {
    if (!RETRIEVE_AND_PAY_COMPLETED_ISSUED_TICKETS_STATUS?.includes(prepaidStatus as PREPAID_STATUS)) {
      // case not include these 3 scenario, need to return "RETRIEVE_AND_PAY"
      // eg. "AUTH_PENDING"
      return BOOKING_STATUS.retrieveAndPay;
    }
    // else return current prepaid status
    return prepaidStatus || '';
  }

  // For 'flown' bookings, return the original status.
  return status || '';
};

// Prepaid use only
const getPaymentStatus = (prepaidStatus?: string, codeLabels?: Configuration.CodeLabel[]) => {
  if (prepaidStatus === null || !codeLabels) return en.common.empty;

  /* 
  Remark for reference
  (below label need refer to the existing DB codelabel)

  Condition when prepaidStatus including one of - RETRIEVE_AND_PAY, AUTH_PENDING
    - show `Not paid`

  Condition when prepaidStatus equal to - REFUNDED
    - show `Requested for refund`


  Condition when prepaidStatus including one of -
    AUTH_SUCCESS
    CAPTURE_PENDING
    CAPTURE_FAILED
    CAPTURE_SUCCESS
    NOT_REFUND
    PAID

    - show `Paid`

    
  Other cases:
    - show '--'

  */

  return getDisplayLabel(codeLabels, LABEL_CATEGORY.prepaidStatus, prepaidStatus);
};

// check booking is owoal flight
const isOWOALBooking = (flightNum: string): boolean => {
  return flightNum === en.booking.confirmation.open;
};

interface ICheckConcessionFare {
  fareType: string | undefined;
  fareDiscount: number | undefined;
}

const checkIsFocFare = (params: ICheckConcessionFare) => {
  return params.fareType === FARE_TYPES.ID && params.fareDiscount === 100;
};

const checkIsId50Fare = (params: ICheckConcessionFare) => {
  return params.fareType === FARE_TYPES.ID && params.fareDiscount === 50;
};

const checkIsZoneFare = (params: ICheckConcessionFare) => {
  return params.fareType === FARE_TYPES.ZED && params.fareDiscount === 0;
};

const isRetireeWidow = (profile: Employee.Profile) => {
  return [USER_PROFILE_TYPE.retiree, USER_PROFILE_TYPE.widow].includes(profile.type);
};

const isWidow = (profile?: Employee.Profile | null) => {
  return profile?.type === USER_PROFILE_TYPE.widow;
};

const isRetiree = (profile: Employee.Profile) => {
  return profile.type === USER_PROFILE_TYPE.retiree;
};

const isRetireeSpecialProfile = (profile: Employee.Profile) => {
  return profile.type === USER_PROFILE_TYPE.retireeSpecialProfile;
};

export {
  calcStops,
  formatPrice,
  getDisplayVal,
  getDebtorCode,
  getTicketNumArr,
  getSeatClass,
  getBaggage,
  isLevelZEmployee,
  constructBookingDetailsKeyValues,
  constructTicketDetailsKeyValues,
  getSeatDisplayByAvailability,
  getDefaultSeatClassSelection,
  getSeatClassDetails,
  getColorOfSeatClassStatus,
  getDisplayName,
  getPassengerDisplayName,
  getPersonalDisplayName,
  isSomePassportDetailsMissing,
  formatTicketNumForDisplay,
  getSeatClassImage,
  getCabinClassColor,
  getFullDisplayName,
  getShortDisplayName,
  getUnUcData,
  getTicketsBySegment,
  isFlightInfoGreyOut,
  getAdminBookingEmployeeErn,
  getWholeDTLTMaxAdvanceDay,
  getDTMaxAdvanceDay,
  getLTMaxAdvanceDay,
  isSomeTravellerAvailable,
  checkLtIsExceedAdvanceBookingDay,
  handleBookOneWayOnClick,
  handlePaymentAndFlownSuspensionMessageAction,
  getFlightKey,
  getFilterCount,
  getNonEnglishCharacterTravellers,
  getTravelTypeSelectionList,
  isHideLtFeatures,
  isLtZoneType,
  isShowReminderMessage,
  sortConcessionForRule,
  getIsOnlySeatOptionAvailable,
  getInvalidFJClassTravellerWarningMessage,
  checkInfantTypeIsINSOrINF,
  getConcessionForRadioButtonGroup,
  getCanPreselectAvailableSeatOption,
  requireAccompanyForm,
  getConcessionSelectionValue,
  getBookingStatus,
  getPaymentStatus,
  isOWOALBooking,
  getAdminPersonalDisplayName,
  getPHubDependentDisplayName,
  checkIsFocFare,
  checkIsId50Fare,
  checkIsZoneFare,
  isRetireeWidow,
  isWidow,
  isRetiree,
  isRetireeSpecialProfile,
};
