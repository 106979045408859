import React, { useContext } from 'react';
import { Box } from '@mui/material';

import en from '../../../../translations/en';

import { getLabelKeyByProfileType } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectUser } from '../../../../slice/userSlice';
import { NominationAction, NominationState } from '../../../../context';

import { DisclaimerContainer } from '../../..';

const NominationDisclaimerContainer = () => {
  const { profile } = useAppSelector(selectUser) || {};

  const { setNominationFlow } = useContext(NominationAction) || {};
  const { nominationFlow } = useContext(NominationState) || {};
  const { isAgreeDisclaimer } = nominationFlow || {};

  const labelKeyByProfileType = getLabelKeyByProfileType(profile);

  const nominationFlowDisclaimerPageLbl = en.userProfile.nomination.nominationFlow;

  const descriptionContentByProfileType =
    nominationFlowDisclaimerPageLbl[
      labelKeyByProfileType as Exclude<
        keyof typeof nominationFlowDisclaimerPageLbl,
        'common' | 'nextYearNominationFlow'
      >
    ].disclaimerPolicy;

  const disclaimerCheckboxOnChange = () => {
    setNominationFlow({
      ...nominationFlow,
      isAgreeDisclaimer: !isAgreeDisclaimer,
    });
  };

  const policySectionList = [
    {
      title: descriptionContentByProfileType.description,
      descriptionContent: descriptionContentByProfileType.firstPolicyDetailDescriptionContent,
    },
    {
      descriptionContent: descriptionContentByProfileType.secondPolicyDetailDescriptionContent,
      isShowDisclaimerCheckbox: true,
      disclaimerCheckboxOnChange,
      isAgreeDisclaimer,
    },
  ];

  return (
    <Box className="nomination_disclaimer_container">
      <DisclaimerContainer policySectionList={policySectionList} title={descriptionContentByProfileType.title} />
    </Box>
  );
};

export default NominationDisclaimerContainer;
