import { Typography, Box, useTheme } from '@mui/material';

import en from '../../translations/en';

const AdminMobileView = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        color: theme.color.secondary.dark_grey.option_3,
        pt: 'calc(22% - 35px)',
        pl: 'calc(30% - 100px)',
        position: 'fixed',
        zIndex: '1301',
        top: 0,
        left: 0,
      }}
    >
      <Typography variant="title_1_bold" sx={{ mb: 2.5, width: '240px' }}>
        {en.home.resizeBrowser}
      </Typography>
      <Typography variant="body_2_regular">{en.home.adminFunctionUnavailable}</Typography>
    </Box>
  );
};

export default AdminMobileView;
