import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

import { Employee } from 'employee';

import {
  BENEFICIARY_TYPE_CODE,
  INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
  PAYMENT_CONCEPT,
  UI_STYLES,
  INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
  USER_ROLE,
} from '../../constants/constants';
import { NOMINATION_PATHS, USER_PROFILE_PATHS } from '../../constants/paths';

import en from '../../translations/en';

import { IDependent } from '../../interfaces';

import {
  handleBackToUserProfile,
  isRetireeSpecialProfile,
  isRetireeWidow,
  isWidow,
  validateForRegisterMaxNumberOfTravelCompanion,
} from '../../helpers';

import { selectApp, setSnackBarData } from '../../slice/appSlice';
import { selectAuth } from '../../slice/authSlice';
import {
  selectUser,
  setEditContactPassportDetails,
  setIsLoadedDependent,
  setIsShowPaymentHistoryDialog,
  getDependentThunk,
} from '../../slice/userSlice';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { usePaymentMethodIndicator } from '../../hooks';

import { NominationAction, NominationState, CountryOfResidenceAction } from '../../context';
import { EditPaymentHistoryContextProvider } from '../../context/PaymentHistoryContext';

import {
  editCompanionPassport,
  editNonCompanionPassport,
  validateEligibleUpdateCountryOfResidential,
  inactiveCompanion,
} from '../../services/user';

import { DesktopView, Div100vhView, FormButton, Header, ScrollableView } from '../../components';
import { ConcessionWithTraveller, NomineeFooterComponent, ConcessionWithYear } from '../../containers';
import UpdateCountryOfResidentialFooterContainer from '../../containers/UserProfile/PaymentMethodDetails/UpdateCountryOfResidentialFooterComponent';

const UserProfileDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const screenHeight = use100vh();

  const { ern } = useAppSelector(selectAuth) || {};

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile, editContactPassportDetails, role } = useAppSelector(selectUser);

  const { dependent, isLoadedDependent } = useAppSelector(selectUser) || [];

  const { companionRegistrationFlow, companionDependentStatusFlow, defaultDependentDetailsCardExpand } =
    useContext(NominationState) || {};
  const { setCompanionRegistrationFlow, setCompanionDependentStatusFlow, setDefaultDependentDetailsCardExpand } =
    useContext(NominationAction) || {};

  const isAdminRole = [USER_ROLE.admin].includes(role?.type) || false;

  const location = useLocation();

  const isPaymentHistoryPage = location?.pathname === USER_PROFILE_PATHS.paymentHistory;

  const isConcessionPage = location?.pathname === USER_PROFILE_PATHS.concessionDetails;

  const isNomineesDetailPage = location?.pathname === USER_PROFILE_PATHS.dependentDetails;

  // etp-6017 add: retiree/widow/subs/asso users can edit passport
  const isEditPassportPage = location?.pathname === USER_PROFILE_PATHS.editPassportDetail;

  const {
    isEditMode: isEditPassportModeFromNomineeDetail,
    isPassportInputValid: isEditCompanionPassportInputValid,
    beneficiaryTypeCode,
  } = companionRegistrationFlow?.editPassport || {};

  const isEditTravelCompanionPassport = beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion;

  //TODO can enhance more pages if need empty padding config
  const isEmptyPaddingPage = [USER_PROFILE_PATHS.paymentHistory].includes(location?.pathname);

  const { isInactivatedMode, isAgreeToInactivatedTheDependent, reason, dependentId } =
    companionDependentStatusFlow.editDependentStatusData || {};

  const travelCompanionList = dependent.filter(
    (item: IDependent) => item.beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion,
  );

  const [isShowRegisterNomineeFooter, setIsShowRegisterNomineeFooter] = useState(false);

  const scrollViewRef = useRef<HTMLDivElement>(null);

  // Edit companion passport > trigger to open dialog
  const triggerCancelEditCompanionPassportDialog = () => {
    setCompanionRegistrationFlow({
      ...companionRegistrationFlow,
      editPassport: {
        ...companionRegistrationFlow.editPassport,
        isOpenCancelEditCompanionPassportDialog: true,
      },
    });
  };

  // Companion registration footer primary button text
  const footerPrimaryButtonText = () => {
    let text = en.userProfile.nomination.nomineesDetail.registerTravelCompanionButton;

    if (isEditPassportModeFromNomineeDetail) {
      text = en.common.confirm;
    }
    return text;
  };
  const onCancel = () => {
    setCompanionDependentStatusFlow({
      ...companionDependentStatusFlow,
      editDependentStatusData: {
        ...INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
      },
    });
  };
  // [ETP-3868] edit companion passport action
  const editPassportFromNomineeDetailAction = async () => {
    const requestParams = companionRegistrationFlow.editPassport.requestParams;

    const result = isEditTravelCompanionPassport
      ? await editCompanionPassport(ern, requestParams)
      : await editNonCompanionPassport(ern, requestParams);

    if (result) {
      // after API call success, trigger to refresh nominee detail page, then show toast message
      setCompanionRegistrationFlow({
        ...companionRegistrationFlow,
        isPassportNameConfirmed: false,
        showEditPassportInfo: false,
        isPassportInfoConsent: false,
        editPassport: {
          ...INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
          isTriggerEditCompanionPassportToast: true,
          beneficiaryTypeCode,
        },
      });

      if (defaultDependentDetailsCardExpand) {
        setDefaultDependentDetailsCardExpand(Number(defaultDependentDetailsCardExpand));
      }
    }
  };

  const footerPrimaryBtnOnClick = async () => {
    if (isNomineesDetailPage) {
      if (isEditPassportModeFromNomineeDetail) {
        // prevent user click confirm button if input is invalid
        if (!companionRegistrationFlow.showEditPassportInfo && companionRegistrationFlow.isPassportNameConfirmed) {
          setCompanionRegistrationFlow({
            ...companionRegistrationFlow,
            showEditPassportInfo: true,
          });
          return;
        }
        if (!isEditCompanionPassportInputValid) return;

        editPassportFromNomineeDetailAction();
      } else {
        // primary btn click
        navigate(NOMINATION_PATHS.companionRegistration);
      }
    }
  };

  const checkIsDisableFooterPrimaryBtn = () => {
    if (isNomineesDetailPage && isEditPassportModeFromNomineeDetail) {
      if (!companionRegistrationFlow.showEditPassportInfo && !companionRegistrationFlow.isPassportNameConfirmed) {
        return true;
      }

      if (
        companionRegistrationFlow.showEditPassportInfo &&
        (!companionRegistrationFlow.isPassportInfoConsent || !isEditCompanionPassportInputValid)
      ) {
        return true;
      }
    }

    return false;
  };

  const checkIsEnableFooterPrimaryBtn = (profile?: Employee.Profile | null) => {
    // [ETP-5012] Disable Companion registration footer primary button by:
    // profile is Widow or Retiree special profile
    if (isNomineesDetailPage && profile && (isWidow(profile) || isRetireeSpecialProfile(profile))) {
      return false;
    }

    // [ETP-4627] Limit number of travel companions
    if (isNomineesDetailPage && !isEditPassportModeFromNomineeDetail) {
      return validateForRegisterMaxNumberOfTravelCompanion<IDependent>(travelCompanionList);
    }
    return true;
  };

  // [ETP-3868] edit companion passport
  const clearEditCompanionPassportOrDependentStatusHandler = () => {
    setCompanionRegistrationFlow({
      ...companionRegistrationFlow,
      editPassport: INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
    });
    onCancel();
  };

  useEffect(() => {
    // for Edit Companion passport
    if (isNomineesDetailPage && isEditPassportModeFromNomineeDetail) {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTop = 0;
      }
    }
  }, [isNomineesDetailPage, isEditPassportModeFromNomineeDetail]);

  const inactiveCompanionAction = async () => {
    const result = await inactiveCompanion(ern, dependentId, reason);
    if (result) {
      // after API call success, trigger to refresh nominee detail page, then show toast message
      setCompanionDependentStatusFlow({
        ...companionDependentStatusFlow,
        editDependentStatusData: {
          ...INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
        },
      });
      dispatch(
        setSnackBarData({
          message: en.userProfile.nomination.inactivatedDependentStatusDetails.inactivatedSuccessfully,
          isShow: true,
          delay: 700,
          customStyles: {
            // TMP support desktop or mobile view.
            mb: isDesktop ? 9 : 10.5,
          },
        }),
      );
      dispatch(getDependentThunk({ userId: ern }));
    }
  };

  // Nominee detail (non edit companion passport mode) > handle display register companion registration button
  useEffect(() => {
    if (isNomineesDetailPage && !isEditPassportModeFromNomineeDetail && isLoadedDependent) {
      const isShowFooterInNomineeDetailPage =
        validateForRegisterMaxNumberOfTravelCompanion<IDependent>(travelCompanionList);

      setIsShowRegisterNomineeFooter(isShowFooterInNomineeDetailPage);
    }
  }, [isNomineesDetailPage, isEditPassportModeFromNomineeDetail, travelCompanionList, isLoadedDependent]);

  useEffect(() => {
    // etp: 6017 add: check if click "confirm" update passport details
    // if yes, call "editPassportDetail" api, and reset "isConfirmPassportDetails"+"isDependentPassportEdit" to false.
    if (editContactPassportDetails?.isConfirmPassportDetails && editContactPassportDetails?.isDependentPassportEdit) {
      editPassportFromNomineeDetailAction();
      dispatch(
        setEditContactPassportDetails({
          ...editContactPassportDetails,
          isConfirmPassportDetails: false,
          isDependentPassportEdit: false,
        }),
      );
    }
  }, [editContactPassportDetails]);

  const isBasicInfoPage = location?.pathname === USER_PROFILE_PATHS.basicInformation;

  const { setOpen: setOpenCountryOfResidenceDialog } = useContext(CountryOfResidenceAction) || {};

  const [paymentMethodIndicator] = usePaymentMethodIndicator();

  const isRegisterCompanionBtnHidden =
    window.config?.assoSubsidCompanyListHideRegisterCompanionButton?.some(
      (itemHiringCompany) => itemHiringCompany === profile?.hiringCompany,
    ) || false;

  const calcContentPageHeight = () => {
    const desktopContentHeight = `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - ${UI_STYLES.desktopHeaderHeight
      } - ${isEditPassportPage ? UI_STYLES.desktopFooterHeight : '0px'})`;

    const mobileContentHeight = `calc(${screenHeight}px - ${isEditPassportPage ? UI_STYLES.footerHeight : '0px'})`;

    return isDesktop ? desktopContentHeight : mobileContentHeight;
  };

  return (
    <EditPaymentHistoryContextProvider>
      <Div100vhView sx={{ bgcolor: theme.color.secondary.light_slate.option_7 }}>
        <DesktopView component={Grid} className="user_profile_header_desktop" isAdmin={isAdminRole}>
          <Box
            sx={{
              display: 'flex',
              background: 'white',
              px: 21,
              ...(isPaymentHistoryPage && {
                pr: 21,
              }),
              boxShadow: theme.boxShadow.important,
              height: UI_STYLES.homepageNavBarHeight,
              my: 'auto',
            }}
          >
            {isEditPassportModeFromNomineeDetail || isInactivatedMode ? (
              <Header
                iconColor={theme.color.utility.link.option_3}
                leftChild={
                  <Typography
                    variant="headline_bold"
                    color={theme.color.utility.link.option_3}
                    sx={{ ml: 1, cursor: 'pointer' }}
                    onClick={() => {
                      clearEditCompanionPassportOrDependentStatusHandler();
                    }}
                  >
                    {
                      en.userProfile.nomination.companionRegistration.common.editCompanionPassport
                        .backToDependantDetails
                    }
                  </Typography>
                }
                handleOnBack={() => {
                  dispatch(setIsShowPaymentHistoryDialog(false));
                  clearEditCompanionPassportOrDependentStatusHandler();
                }}
              />
            ) : (
              <Header
                iconColor={theme.color.utility.link.option_3}
                leftChild={
                  <Typography
                    variant="headline_bold"
                    color={theme.color.utility.link.option_3}
                    sx={{ ml: 1, cursor: 'pointer' }}
                    onClick={() => {
                      handleBackToUserProfile(navigate);
                    }}
                  >
                    {en.userProfile.backToProfile}
                  </Typography>
                }
                rightChild={
                  isDesktop &&
                  isConcessionPage && (
                    <Box
                      className="concession_detail_dropdowns_container"
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <ConcessionWithYear />
                      <ConcessionWithTraveller />
                    </Box>
                  )
                }
                handleOnBack={() => {
                  dispatch(setIsShowPaymentHistoryDialog(false));
                  handleBackToUserProfile(navigate);

                  // clear isLoadedDependent flag in redux when back to other page
                  dispatch(setIsLoadedDependent(false));
                }}
              />
            )}
          </Box>
        </DesktopView>
        <Grid
          className="user_profile_container"
          component={ScrollableView}
          ref={scrollViewRef}
          sx={{
            height: calcContentPageHeight(),
          }}
          container
          pt={!isEmptyPaddingPage && isDesktop ? 2 : 0}
          px={{
            xs: !isEmptyPaddingPage ? 2 : 0,
            sm: 0,
          }}
        >
          <Grid container direction="column">
            <Grid
              item
              py={isEmptyPaddingPage ? 0 : 2}
              sx={{
                ...(isDesktop && {
                  display: 'flex',
                  justifyContent: 'center',
                }),
              }}
            >
              <Outlet />
            </Grid>
          </Grid>
        </Grid>

        {/* // Nominees Detail Page */}
        {isNomineesDetailPage && isShowRegisterNomineeFooter && !isRegisterCompanionBtnHidden && !isInactivatedMode && (
          <NomineeFooterComponent
            // [ETP-3868] edit companion passport
            {...(isEditPassportModeFromNomineeDetail && {
              leftChild: (
                <FormButton
                  theme={theme}
                  colour="transparent"
                  size="large"
                  sx={{
                    height: '44px',
                    display: 'flex',
                    justifyContent: 'center',
                    ...{ minWidth: 0 },
                  }}
                  onClick={triggerCancelEditCompanionPassportDialog}
                >
                  {en.common.cancel}
                </FormButton>
              ),
            })}
            {...(checkIsEnableFooterPrimaryBtn(profile) && {
              primaryBtn: {
                text: footerPrimaryButtonText(),
                customOnClick: footerPrimaryBtnOnClick,
                status: checkIsDisableFooterPrimaryBtn(),
                ...(!isEditPassportModeFromNomineeDetail
                  ? {
                      isFullWidth: !isDesktop,
                      restProps: {
                        colour: 'outline',
                      },
                      customButtonStyles: {
                        ...(isDesktop && {
                          width: '280px',
                          padding: '8px 16px',
                        }),
                        '&:hover': {
                          boxShadow: 'none',
                          background: 'inherit',
                        },
                      },
                    }
                  : {
                      customButtonStyles: {
                        '&.Mui-disabled': {
                          color: theme.palette.primary.light,
                        },
                      },
                    }),
              },
            })}
          />
        )}
        {isBasicInfoPage &&
          profile &&
          isRetireeWidow(profile) &&
          // only NOT to show update button when countryOfResidential is not empty and paymentMedium is empty
          (profile.paymentMedium || !profile.retireeWidow?.countryOfResidential) &&
          profile.paymentConcept !== PAYMENT_CONCEPT.PREPAID &&
          profile.retireeWidow?.countryOfResidential &&
          !paymentMethodIndicator.isShowWarning ? (
          <UpdateCountryOfResidentialFooterContainer
            primaryBtn={{
              text: en.payment.countryOfResidential.updateCountryOfResidentialButton,
              customOnClick: async () => {
                const result = await validateEligibleUpdateCountryOfResidential(profile.employeeId);
                if (result) {
                  setOpenCountryOfResidenceDialog(true);
                }
              },
              isFullWidth: !isDesktop,
              restProps: {
                colour: 'outline',
              },
              customButtonStyles: {
                ...(isDesktop && {
                  width: '280px',
                  padding: '8px 16px',
                }),
                '&:hover': {
                  boxShadow: 'none',
                  background: 'inherit',
                },
              },
            }}
          />
        ) : (
          <></>
        )}
        {isInactivatedMode && (
          <NomineeFooterComponent
            primaryBtn={{
              isFullWidth: false, //,!isDesktop,
              text: en.common.confirm,
              status: !(isAgreeToInactivatedTheDependent && reason !== ''),
              customOnClick: () => {
                inactiveCompanionAction();
              },
            }}
            {...(isInactivatedMode && {
              leftChild: (
                <FormButton
                  theme={theme}
                  colour="transparent"
                  size="large"
                  onClick={onCancel}
                  sx={{
                    height: '44px',
                    display: 'flex',
                    justifyContent: 'center',
                    minWidth: 0,
                  }}
                >
                  {en.common.cancel}
                </FormButton>
              ),
            })}
          />
        )}
      </Div100vhView>
    </EditPaymentHistoryContextProvider>
  );
};

export default UserProfileDetails;
