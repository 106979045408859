import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, IconButton, Typography, useTheme, Popper, Theme, SxProps } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import _ from 'lodash';

import {
  ADMIN_PERSONALINFO_SELECT_TYPE,
  PAYMENT_MEDIUM,
  UI_STYLES,
  PAYMENT_STATUS_OPTIONS,
  PAYMENT,
  ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST,
} from '../../constants/constants';
import en from '../../translations/en';
import { CheckMarkIcon } from '../../assets/images';

import { IAdminFlownSuspension, IAdminToggleBarTabListProps } from '../../interfaces';
import { DependentInfo as IDependentInfo } from 'booking';

import {
  constructAdminEditJobLevelParams,
  validFlownSuspensionParams,
  isEmptyField,
  validAdminEditFromToDateParams,
  constructAdminFlownSuspensionParams,
  isValid,
  isRetireeSpecialProfile,
  getInitEmployeeDependents,
} from '../../helpers';
import { AdminAccessEmployeeState, AdminAccessEmployeeAction, ConcessionInProfileAction } from '../../context';

import { getEmployee, getSuggestion, getNotes, updateEmployee } from '../../services/admin/employee';
import { showErrorAlert } from '../../services/api';

import {
  AdminNotes,
  AdminInformationBar,
  AdminPersonalInfoDetails,
  AdminToggleButtonGroup,
  AdminDependents,
  AdminEmployeeList,
  NominationHistory,
  AdminConcession,
  QuotaHistory,
} from '../../containers/AdminMaintainEmployeeDetail';
import { SnackbarComponent } from '../../containers';
import { ScrollableView, AutoCompleteBox, CancelDialog } from '../../components';

const BoldButtonTypography = ({ customStyles, textLabel }: { customStyles?: SxProps<Theme>; textLabel: string }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="body_2_bold"
      sx={{
        width: '120px',
        height: '50px',
        lineHeight: '50px',
        background: theme.color.utility.link.option_3,
        borderRadius: 1,
        color: theme.palette.bgColor.main,
        textAlign: 'center',
        ...customStyles,
      }}
    >
      {textLabel}
    </Typography>
  );
};

const AdminEmployee = () => {
  const screenHeight = use100vh();
  const theme = useTheme();
  const scrollViewRef = useRef<HTMLDivElement>(null);

  // TODO: need refactor same state type
  const [adminMaintainId, setAdminMaintainId] = useState<number>(0);
  const [ernValue, setErnValue] = useState<string>('');
  const [galaCXyIdValue, setGalaCXyIdValue] = useState<string>('');
  const [suggestionList, setSuggestionList] = useState<any[] | null>(null);
  const [searchResult, setSearchResult] = useState<any[] | null>(null);
  const [isOnSelect, setIsOnSelect] = useState<boolean>(false);
  const [previewProfile, setPreviewProfile] = useState(null);
  const [searchTips, setSearchTips] = useState<string>('');

  const [isShowEditButton, setIsShowEditButton] = useState<boolean>(true);
  const [isShowCancelPopup, setIsShowCancelPopup] = useState<boolean>(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);
  const [isChangedByPasteForErn, setIsChangedByPasteForErn] = useState<boolean>(false);
  const [isChangedByPasteForGalaxyId, setIsChangedByPasteForGalaxyId] = useState<boolean>(false);

  const { employeeDetail, isEditingAdminProfile, editEmployeeDetail } = useContext(AdminAccessEmployeeState) || {};
  const { profile: searchEmployeeProfile } = employeeDetail || {};
  const {
    setEmployeeDetail,
    setEmployeeNotes,
    setEmployeeDependent,
    setIsEditingAdminProfile,
    setEditEmployeeDetail,
    setEditEmployeeError,
    setConcessions,
    setConcessionName,
    setFocusConcession,
    setFocusNote,
  } = useContext(AdminAccessEmployeeAction) || {};
  const {
    setCurrentContextLocation,
    setEmployeeProfileDetailForConcession,
    setIsTriggerRefreshConcessionYearDropdownData,
    setConcessionYearDropdownData,
  } = useContext(ConcessionInProfileAction) || {};

  const { personalInformation, dependents, note, nominationHistory, concession, quotaHistory } =
    en.admin.adminToggleList;
  const { maintainEmployeeDetails, employeeProfile } = en.admin.feature;
  const { editProfilePopup } = en.admin.feature.personalInformation.profileDetails;
  const isEmptyEditEmployeeDetail = editEmployeeDetail === null;

  const paperStyles = {
    width: '240px',
    position: 'absolute',
    left: '-16px',
    mt: 0.5,
  };

  const textFieldStyle = {
    height: '50px',
    borderRadius: 0.5,
    background: 'white',
    border: `1px solid ${theme.color.secondary.slate.option_3}`,
  };

  const specialRetireeERNInputWidth = '360px';

  // config by jenkins, frontend default as true - tune of the edit mode & Notes tab
  const isShowAdminEmployeeEditAction = window.config.isShowAdminEditMode;
  const isSpecialRetireeProfile = !!(searchEmployeeProfile && isRetireeSpecialProfile(searchEmployeeProfile));

  // TODO The component property will use correct component replace
  const firstToggleBarTabList: IAdminToggleBarTabListProps[] = [
    {
      id: 0,
      title: personalInformation,
      component: <AdminPersonalInfoDetails defaultId={1} />,
    },
    {
      id: 1,
      title: dependents,
      component: <AdminDependents />,
    },
    ...(isShowAdminEmployeeEditAction
      ? [
          {
            id: 2,
            title: note,
            component: <AdminNotes />,
          },
        ]
      : []),

    {
      // etp-4401 added
      // if remove flag, need to handle id key index value.
      // same as below 3 tabs
      id: isShowAdminEmployeeEditAction ? 3 : 2,
      title: concession,
      component: <AdminConcession />,
    },
    {
      id: isShowAdminEmployeeEditAction ? 4 : 3,
      title: nominationHistory,
      component: <NominationHistory />,
    },
    // {
    //   id: isShowAdminEmployeeEditAction ? 5 : 4,
    //   title: admin,
    //   component: <></>,
    // },
    {
      id: isShowAdminEmployeeEditAction ? 5 : 4,
      title: quotaHistory,
      component: <QuotaHistory />,
    },
  ];

  const getSuggestionList = async (payload: { userId?: string; galaCXyId?: string }) => {
    const suggestionResult = (await getSuggestion(payload)) || [];
    const suggestionOption = suggestionResult.map((item: string) => {
      return { label: item, code: item };
    });
    setSuggestionList(suggestionOption);
  };

  const handleFetchEmployee = async (searchValue?: { userId?: string; galaCXyId?: string }) => {
    const { userId, galaCXyId } = searchValue || {
      userId: ernValue,
      galaCXyId: galaCXyIdValue,
    };

    if (userId) {
      if (userId.length < 5) {
        setSearchResult(null);
        setSearchTips(maintainEmployeeDetails.incompleteErn);
        return;
      }
      searchValue ??= { userId: ernValue };
    }
    if (galaCXyId) {
      if (galaCXyId.length < 5) {
        setSearchResult(null);
        setSearchTips(maintainEmployeeDetails.incompleteGalaCXyId);
        return;
      }
      searchValue ??= { galaCXyId: galaCXyIdValue };
    }

    if (searchValue) {
      const employeeList = await getEmployee(searchValue);
      setEmployeeDetail(null);
      setAdminMaintainId(0);
      resetEditToViewMode();
      setSearchResult(employeeList);
    }
  };

  const dropDownListPop = (props: any) => {
    if (suggestionList && suggestionList.length > 0) {
      return <Popper {...props} placement="bottom-start" />;
    }
    return null;
  };

  const fetchNotes = async () => {
    // clear previous list first
    setEmployeeNotes([]);

    const notesData = await getNotes({
      userId: employeeDetail.profile.employeeId,
      profileRevision: employeeDetail.profile.revision,
    });
    setEmployeeNotes(notesData || []);
  };

  const resetEditToViewMode = () => {
    setIsEditingAdminProfile(false);
    setEditEmployeeDetail(null);
    setEditEmployeeError(null);
    setFocusNote(null);
  };

  // TODO [ETP-5405] please remove some outdated editable fields checking
  const updateEmployeeAction = async () => {
    const { payment, benefit, flownSuspension, editFlownSuspensionList } = editEmployeeDetail || {};
    const { paymentConcept, paymentMedium, paymentStatus } = payment || {};
    const {
      benefitDTJobLevel,
      effectiveFromDateDuty,
      effectiveToDateDuty,
      benefitLTJobLevel,
      effectiveFromDateLeisure,
      effectiveToDateLeisure,
      yearOfServiceByAdmin,
    } = benefit || {};
    const { flownSuspensionStartDate, flownSuspensionEndDate } = flownSuspension || {};

    // dt request params checking
    const { errorObject: dtErrorMessage } =
      validAdminEditFromToDateParams(
        benefitDTJobLevel,
        effectiveFromDateDuty,
        effectiveToDateDuty,
        ADMIN_PERSONALINFO_SELECT_TYPE.benefitDTJobLevel,
      ) || {};

    // lt request params checking
    const { errorObject: ltErrorMessage } =
      validAdminEditFromToDateParams(
        benefitLTJobLevel,
        effectiveFromDateLeisure,
        effectiveToDateLeisure,
        ADMIN_PERSONALINFO_SELECT_TYPE.benefitLTJobLevel,
      ) || {};

    // flown suspension request params checking
    const { errorObject: flownSuspensionErrorMessage } =
      validFlownSuspensionParams(
        flownSuspensionStartDate,
        flownSuspensionEndDate,
        ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspension,
      ) || {};

    // construct error Object
    let adminEditErrorObject = {};
    if (dtErrorMessage) {
      adminEditErrorObject = { benefitDTJobLevel: dtErrorMessage };
    }
    if (ltErrorMessage) {
      adminEditErrorObject = { ...adminEditErrorObject, benefitLTJobLevel: ltErrorMessage };
    }
    if (flownSuspensionErrorMessage) {
      adminEditErrorObject = {
        ...adminEditErrorObject,
        flownSuspension: flownSuspensionErrorMessage,
      };
    }

    if (
      (dtErrorMessage !== undefined && !isValid(dtErrorMessage)) ||
      (ltErrorMessage !== undefined && !isValid(ltErrorMessage)) ||
      !!flownSuspensionErrorMessage
    ) {
      // has error
      showErrorAlert({
        title: editProfilePopup.missingInformation,
        message: editProfilePopup.fillFields,
        noErrorAlert: true,
        showErrorIcon: false,
      });
      setEditEmployeeError(adminEditErrorObject);

      return;
    } else {
      setEditEmployeeError(null);
    }

    // benefit dt params
    const dTJobLevelParams =
      constructAdminEditJobLevelParams(
        benefitDTJobLevel,
        effectiveFromDateDuty,
        effectiveToDateDuty,
        ADMIN_PERSONALINFO_SELECT_TYPE.benefitDTJobLevel,
      ).jobLevelObject || {};

    // benefit lt params
    const lTJobLevelParams =
      constructAdminEditJobLevelParams(
        benefitLTJobLevel,
        effectiveFromDateLeisure,
        effectiveToDateLeisure,
        ADMIN_PERSONALINFO_SELECT_TYPE.benefitLTJobLevel,
      ).jobLevelObject || {};

    // add flown suspension params
    const addFlownSuspensionParams =
      constructAdminFlownSuspensionParams(
        flownSuspensionStartDate,
        flownSuspensionEndDate,
        ADMIN_PERSONALINFO_SELECT_TYPE.flownSuspension,
      ).suspensionObject || {};

    // edit flown suspension params
    const editFlownSuspensionParams = editFlownSuspensionList
      ?.filter((item: IAdminFlownSuspension) => item.setFlownSuspensionStartDate && !item.isDeleted)
      .map((item: IAdminFlownSuspension) => {
        return {
          flownSuspensionId: item.flownSuspensionId,
          flownSuspensionStartDate: item.flownSuspensionStartDate,
          flownSuspensionEndDate: item.flownSuspensionEndDate,
          setFlownSuspensionStartDate: item.setFlownSuspensionStartDate,
          setFlownSuspensionEndDate: item.setFlownSuspensionEndDate,
        };
      });

    // delete flown suspension params
    const deleteFlownSuspensionParams = editFlownSuspensionList
      ?.filter((item: IAdminFlownSuspension) => item.isDeleted)
      .map((item: IAdminFlownSuspension) => item.flownSuspensionId);

    const requestParams = {
      userId: employeeDetail.profile.employeeId,
      profileRevision: employeeDetail.profile.revision,
      ...(paymentConcept && { paymentConcept }),
      ...(paymentMedium && {
        paymentMedium: isEmptyField(paymentMedium) ? null : paymentMedium,
      }), // payment medium is "--", pass null
      ...(paymentMedium == PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT && {
        directDebitStatus: paymentStatus
          ? // use edit data first
            PAYMENT_STATUS_OPTIONS.find((item) => item.label === paymentStatus)?.value
          : // use BE data if not edit
            employeeDetail?.profile?.directDebitStatus ||
            // default "null"
            PAYMENT.paymentStatusValue.empty,
      }), // pass status only if payment medium is direct debit
      ...(!isValid(dTJobLevelParams) && {
        benefitDTJobLevel: dTJobLevelParams,
      }),
      ...(!isValid(lTJobLevelParams) && {
        benefitLTJobLevel: lTJobLevelParams,
      }),
      ...(!isValid(addFlownSuspensionParams) && {
        addFlownSuspensionInfo: addFlownSuspensionParams,
      }),
      ...(!_.isEmpty(editFlownSuspensionParams) && {
        editFlownSuspensionList: editFlownSuspensionParams,
      }),
      ...(!_.isEmpty(deleteFlownSuspensionParams) && {
        deleteFlownSuspensionIds: deleteFlownSuspensionParams,
      }),
      yearOfServiceByAdmin,
    };

    const result = await updateEmployee(requestParams);

    // use backend latest data to reset context employee data
    if (result) {
      setEmployeeDetail({
        ...employeeDetail,
        profile: {
          ...result,
        },
        flownSuspensionDetails: result.flownSuspensionDetails,
        editFlownSuspensionList: undefined,
      });
      resetEditToViewMode();
      setIsOpenSnackbar(true);

      setTimeout(() => {
        setIsOpenSnackbar(false);
      }, 3000);
    }
  };

  const initEmployeeDetail = (employee: any) => {
    const tempEmployeeProfile = employee?.profile;

    // reset location context for `ConcessionInProfileContext` used
    setCurrentContextLocation('');

    // Clean up Year dropdown data
    setConcessionYearDropdownData({
      dropdownOptions: [],
      selectedOptionItem: null,
    });

    // reset flag
    setIsTriggerRefreshConcessionYearDropdownData(false);

    if (tempEmployeeProfile?.isAccessible) {
      // initialize the dependentList from employee.nominationList
      // after call "inactiveNomination" api, load dependents data
      initEmployeeDependentList(employee?.dependents || employee?.nominationList);

      setEmployeeDetail(employee);

      const tempEmployeePersonalInfo = employee?.personalInfo;

      // initialize few Employee profile & personalInfo data for Concession tab Context used
      setEmployeeProfileDetailForConcession({
        employeeId: tempEmployeeProfile?.employeeId,
        revision: tempEmployeeProfile?.revision,
        hiringCompany: tempEmployeeProfile?.hiringCompany,
        firstName: tempEmployeePersonalInfo?.firstName,
        lastName: tempEmployeePersonalInfo?.lastName,
        middleName: tempEmployeePersonalInfo?.middleName,
        age: tempEmployeePersonalInfo?.age,
      });
    }
  };

  const initEmployeeDependentList = (employeeNominationList: IDependentInfo[] = []) => {
    // initialize Dependents Tab whole data
    const initializedDependents = getInitEmployeeDependents(employeeNominationList);
    setEmployeeDependent(initializedDependents);
  };

  useEffect(() => {
    setEmployeeDetail(null);
    return () => {
      resetEditToViewMode();
    };
  }, []);

  const beforeUnloadHandler = (event: any) => {
    // event.returnValue cannot empty string
    const message = 'confirm exit';
    event.returnValue = message;
    return message;
  };

  useEffect(() => {
    const setupBeforeUnloadListener = () => {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    };

    const removeBeforeUnloadListener = () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };

    if (isEditingAdminProfile) {
      // only when editing will trigger beforeunload
      setupBeforeUnloadListener();
    } else {
      removeBeforeUnloadListener();
    }

    return () => {
      removeBeforeUnloadListener();
    };
  }, [isEditingAdminProfile]);

  useEffect(() => {
    // will remove `isShowAdminEmployeeEditAction` after integration
    setIsShowEditButton(adminMaintainId === 0 && isShowAdminEmployeeEditAction);
  }, [adminMaintainId]);

  useEffect(() => {
    if (searchResult) {
      if (searchResult?.length > 0) {
        setSearchTips('');
        const tempPreviewProfile = searchResult?.find((item) => item.profile.isAccessible);
        setPreviewProfile(tempPreviewProfile);
        if (searchResult?.length === 1) {
          // Directly display the profile detail if user searches for an ERN or GalaCXy ID and there is single revision of the profile returned
          const employee = _.head(searchResult);
          initEmployeeDetail(employee);
        }
      } else {
        setSearchTips(`${en.booking.travelType.noErnResult} ${en.booking.travelType.tryAgain}`);
      }
    }
  }, [searchResult]);

  useEffect(() => {
    // after inactiveNomination api call, reset context data
    if ((employeeDetail?.dependents || [])?.length > 0) {
      initEmployeeDetail(employeeDetail);
    }
  }, [employeeDetail?.dependents]);

  useEffect(() => {
    if (isChangedByPasteForErn && ernValue.length > 4) {
      getSuggestionList({ userId: ernValue });
      setIsChangedByPasteForErn(false);
      return;
    }
    if (ernValue.length > 4) {
      !suggestionList && getSuggestionList({ userId: ernValue });
    } else {
      suggestionList && setSuggestionList(null);
    }
  }, [ernValue]);

  useEffect(() => {
    if (isChangedByPasteForGalaxyId && galaCXyIdValue.length > 4) {
      getSuggestionList({ galaCXyId: galaCXyIdValue });
      setIsChangedByPasteForGalaxyId(false);
      return;
    }
    if (galaCXyIdValue.length > 4) {
      !suggestionList && getSuggestionList({ galaCXyId: galaCXyIdValue });
    } else {
      suggestionList && setSuggestionList(null);
    }
  }, [galaCXyIdValue]);

  return (
    <Box
      component={ScrollableView}
      sx={{
        pt: 2,
        pb: 10,
        background: theme.palette.primary.light,
        height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${UI_STYLES.indicatorBarHeight} )`,
      }}
      ref={scrollViewRef}
    >
      {/* Page title & Search form */}
      <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 2 }}>
        <Typography variant="large_title_1_bold" color={theme.color.secondary.dark_grey.option_1}>
          {employeeProfile}
        </Typography>
        <Box
          className="admin_search_box"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 5,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ mr: 2, width: specialRetireeERNInputWidth, height: '50px' }}>
              <AutoCompleteBox
                label={maintainEmployeeDetails.ern}
                options={suggestionList || []}
                value={ernValue}
                inputValue={ernValue}
                noOptionsText={en.common.noMatchResult}
                onPaste={() => {
                  setIsChangedByPasteForErn(true);
                }}
                onChange={(newValue: { code: string } | null): void => {
                  setGalaCXyIdValue('');
                  const upperValue = newValue?.code ? newValue.code.toUpperCase() : '';
                  setErnValue(upperValue);
                  if (upperValue) {
                    handleFetchEmployee({ userId: upperValue });
                  }
                }}
                onInputChange={(newInputValue: any) => {
                  setGalaCXyIdValue('');
                  setErnValue(newInputValue.toUpperCase());
                }}
                onKeyDown={() => {
                  if (!isOnSelect) {
                    ernValue && handleFetchEmployee();
                  }
                }}
                onHighlightChange={(event) => {
                  event && setIsOnSelect(true);
                }}
                onClose={() => {
                  setIsOnSelect(false);
                }}
                dropDownListPop={(props: any) => (ernValue ? dropDownListPop(props) : null)}
                renderOption={undefined}
                filterOptions={(array) =>
                  array.filter((item: { code: string; label: string }) => {
                    return item.label?.includes(ernValue);
                  })
                }
                customStyles={{
                  pl: 0,
                  '.MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: theme.color.secondary.grey.option_3,
                    },
                  },
                }}
                paperStyles={{
                  ...paperStyles,
                  width: specialRetireeERNInputWidth,
                }}
                textFieldStyle={textFieldStyle}
              />
            </Box>

            <Box sx={{ mr: 2, width: '240px', height: '50px' }}>
              <AutoCompleteBox
                label={maintainEmployeeDetails.galaCXyId}
                options={suggestionList || []}
                value={galaCXyIdValue}
                inputValue={galaCXyIdValue}
                noOptionsText={en.common.noMatchResult}
                onChange={(newValue: { code: string } | null): void => {
                  setErnValue('');
                  const upperValue = newValue?.code ? newValue.code.toUpperCase() : '';
                  setGalaCXyIdValue(upperValue);
                  upperValue && handleFetchEmployee({ galaCXyId: upperValue });
                }}
                onPaste={() => {
                  setIsChangedByPasteForGalaxyId(true);
                }}
                onInputChange={(newInputValue: any) => {
                  setErnValue('');
                  setGalaCXyIdValue(newInputValue.toUpperCase());
                }}
                onKeyDown={() => {
                  if (!isOnSelect) {
                    galaCXyIdValue && handleFetchEmployee();
                  }
                }}
                onHighlightChange={(event) => {
                  event && setIsOnSelect(true);
                }}
                onClose={() => {
                  setIsOnSelect(false);
                }}
                dropDownListPop={(props: any) => (galaCXyIdValue ? dropDownListPop(props) : null)}
                renderOption={undefined}
                filterOptions={(array) =>
                  array.filter((item: { code: string; label: string }) => {
                    return item.label?.includes(galaCXyIdValue);
                  })
                }
                customStyles={{
                  pl: 0,
                  '.MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: theme.color.secondary.grey.option_3,
                    },
                  },
                }}
                paperStyles={paperStyles}
                textFieldStyle={textFieldStyle}
              />
            </Box>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                handleFetchEmployee();
              }}
            >
              <BoldButtonTypography textLabel={en.common.search} />
            </IconButton>
          </Box>

          {employeeDetail && isShowEditButton ? (
            isEditingAdminProfile ? (
              <Box>
                <IconButton
                  sx={{ p: 0, mr: 2 }}
                  onClick={() => {
                    if (editEmployeeDetail) {
                      setIsShowCancelPopup(true);
                    } else {
                      setIsShowCancelPopup(false);
                      resetEditToViewMode();
                    }
                  }}
                >
                  <BoldButtonTypography
                    customStyles={{
                      border: `2px solid ${theme.color.utility.link.option_3}`,
                      background: theme.palette.bgColor.main,
                      color: theme.color.utility.link.option_3,
                    }}
                    textLabel={en.common.cancel}
                  />
                </IconButton>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() => {
                    updateEmployeeAction();
                  }}
                  disabled={isEmptyEditEmployeeDetail}
                >
                  <BoldButtonTypography
                    customStyles={{
                      background: isEmptyEditEmployeeDetail
                        ? theme.color.utility.unselectable.option_7
                        : theme.color.utility.link.option_3,
                      color: isEmptyEditEmployeeDetail ? theme.palette.text.disabled : theme.palette.bgColor.main,
                    }}
                    textLabel={en.common.save}
                  />
                </IconButton>
              </Box>
            ) : !isSpecialRetireeProfile ? (
              // hide edit button for special retiree profile
              <IconButton
                sx={{ p: 0 }}
                onClick={() => {
                  setIsEditingAdminProfile(true);
                }}
              >
                <BoldButtonTypography
                  customStyles={{
                    background: theme.palette.bgColor.main,
                    border: `2px solid ${theme.color.utility.link.option_3}`,
                    color: theme.color.utility.link.option_3,
                  }}
                  textLabel={en.common.edit}
                />
              </IconButton>
            ) : null
          ) : null}
        </Box>
      </Box>

      {employeeDetail ? (
        <>
          <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
            <AdminInformationBar />
          </Box>
          <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
            <AdminToggleButtonGroup
              list={firstToggleBarTabList}
              onClick={(e) => {
                setAdminMaintainId(e as number);
                // TODO: extra the logic into select function(no useState)
                if (isShowAdminEmployeeEditAction && e === 2) fetchNotes();

                const isAccessIntoConcessionPage =
                  (isShowAdminEmployeeEditAction && e === 3) || (!isShowAdminEmployeeEditAction && e === 2);

                // [ETP-5619] just mark indicator when access to the Concession page
                setCurrentContextLocation(
                  isAccessIntoConcessionPage ? ADMIN_FIRST_LEVEL_TOGGLE_BAR_ID_LIST.concession : '',
                );

                if (isAccessIntoConcessionPage) {
                  // Clean up the selected Concession detail context data
                  setFocusConcession(null);
                  setConcessions(null);
                  setConcessionName(null);

                  // Clean up Year dropdown data
                  setConcessionYearDropdownData({
                    dropdownOptions: [],
                    selectedOptionItem: null,
                  });

                  // trigger to refresh Concession API call with context data
                  setIsTriggerRefreshConcessionYearDropdownData(true);
                }
              }}
              defaultValue={0}
              isDisabled={isEditingAdminProfile}
            />
          </Box>
          <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 2 }}>
            {/* show tab match with component */}
            {firstToggleBarTabList[adminMaintainId].component}
          </Box>
        </>
      ) : searchResult && searchResult?.length > 0 ? (
        <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
          {previewProfile && <AdminInformationBar employee={previewProfile} />}
          <AdminEmployeeList
            dataList={searchResult}
            handleRowDataOnClick={({ employee }) => {
              initEmployeeDetail(employee);
            }}
          />
        </Box>
      ) : (
        searchTips && (
          <Typography variant="body_2_regular" sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
            {searchTips}
          </Typography>
        )
      )}

      <CancelDialog
        open={isShowCancelPopup}
        handleConfirmAction={() => {
          setIsShowCancelPopup(false);
        }}
        handleCloseDialog={() => {
          setIsShowCancelPopup(false);
          resetEditToViewMode();
        }}
        showCancelIcon={false}
        confirmToClose={false}
        dialogContent={{
          title: editProfilePopup.title,
          message: editProfilePopup.body,
          noTitle: editProfilePopup.withoutSaving,
          yesTitle: editProfilePopup.stayOnThePage,
        }}
      />

      <SnackbarComponent
        open={isOpenSnackbar}
        toastNotification={editProfilePopup.changesSaved}
        IconComponent={<CheckMarkIcon />}
        handleClose={() => {}}
      />
    </Box>
  );
};

export default AdminEmployee;
