import React, { createContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { Configuration } from 'configuration';
import {
  INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
  BENEFICIARY_TYPE_CODE,
  NOMINATION_FLOW_PAGE_NO,
  NOMINATION_TYPE,
  NOMINATION_SUB_ROUTE_PAGE,
  INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
  INIT_EDIT_PASSPORTINFO_CONSENT,
} from '../constants/constants';

import {
  ICompanionRegistration,
  INominationSelectionData,
  INominationViewModeItem,
  INominationViewModeList,
  IOALEligibilitySelectedData,
} from '../interfaces';

import { filterCompanionData, sortByDependents } from '../helpers';

interface ICompanionStateType {
  routeTo: any;
  companionRegistrationData: ICompanionRegistration | null;
  isPersonalInputValid: boolean;
  isPassportInputValid: boolean;
  isAgreeDisclaimer: boolean;
  isOpenNominationPolicyConfirmButton: boolean;
  isOpenCancelRegistrationDialog: boolean;
  isPassportNameConfirmed: boolean;
  isPassportInfoConsent: boolean;
  showEditPassportInfo: boolean;
  isSelectDependentRelationship?: boolean;
  editPassport: {
    isOpenCancelEditCompanionPassportDialog: boolean;
    isEditMode: boolean;
    isPassportInputValid: boolean;
    dependentId: string;
    beneficiaryTypeCode: string; // etp-6017 add: to check retiree/widow/assosubsid if edit companion passport
    initFormData: {
      passportNumber: string;
      passportFirstName: string;
      passportLastName: string;
      passportExpirationDate: string;
      passportNationality: string | Configuration.DropdownOptionClient;
      passportCountry: string | Configuration.DropdownOptionClient;
    };
    // for API used
    requestParams: {
      dependentId: string;
      passportNumber: string;
      passportExpirationDate: string;
      passportNationality: string;
      passportCountry: string;
    };
    isTriggerEditCompanionPassportToast: boolean;
  };
  editDependentStatusData: {
    isInactivatedMode: boolean;
    dependentId: string;
    reason?: string;
    isAgreeToInactivatedTheDependent: boolean;
  };
}
interface ICompanionDependentStatusType {
  editDependentStatusData: {
    isInactivatedMode: boolean;
    dependentId: string;
    reason?: string;
    isAgreeToInactivatedTheDependent: boolean;
    displayName: string;
    relationship: string;
    dateOfBirth: string;
    nominationDetails: string;
  };
}

interface INominationFlowStateType {
  nominationFlowRouteTo: any;
  nominationFlowSelectedType: '';
  isNominationTypeValid: boolean;
  nominationViewModeData: INominationViewModeList & { [key: string]: any };
  nominationSelectionData: INominationSelectionData;
  nominationSubRoutePage: string;
  isAgreeDisclaimer: boolean;
  isTriggerSaveNominationToast: boolean;

  // etp-4615 add, for OAL eligibility
  oalEligibilityTickedDependents: IOALEligibilitySelectedData[];
  isOALEligibilityEnableContinue: boolean;
  selectedOALEligibilityDependentId: string;
  // [ETP-4751]
  cancelNominationFlowDialogTriggeringPoint: string; // CANCEL_BUTTON in CANCEL_NOMINATION_FLOW_DIALOG_TRIGGERING_POINT constant
  // etp-4669 add
  isTriggerChangeTypeDialog: boolean;
}

interface IActionType {
  setCompanionRegistrationFlow: any;
  resetCompanionRegistrationFlowData: () => void;
  resetNominationFlowToViewModePageData: () => void;
  setNominationFlow: any;
  setNominationTransformData: any;
  setClonedNominationTempData: any;
  setNominationChangeTypeData: any;
  setClonedNominationChangeTypeData: any;
  setIsAllowNominate: any;
  setCompanionDependentStatusFlow: any;
  setDefaultDependentDetailsCardExpand: any;
}

const NominationState = createContext<{
  companionRegistrationFlow: ICompanionStateType;
  nominationFlow: INominationFlowStateType;
  nominationTransformData: INominationViewModeItem[];
  clonedNominationTempData: INominationViewModeItem[];
  // etp-4669 add: to store can type change list
  nominationChangeTypeData: INominationViewModeItem[];
  clonedNominationChangeTypeData: INominationViewModeItem[];
  isAllowNominate: boolean; // etp-4802 add: true: enable "Continue", false: disable "Continue"
  companionDependentStatusFlow: ICompanionDependentStatusType;
  defaultDependentDetailsCardExpand: number | undefined;
}>({
  companionRegistrationFlow: {
    routeTo: {
      step: 0,
    },
    companionRegistrationData: null,
    isPersonalInputValid: false,
    isPassportInputValid: false,
    isAgreeDisclaimer: false,
    isOpenNominationPolicyConfirmButton: false,
    isOpenCancelRegistrationDialog: false,
    isSelectDependentRelationship: false,
    editPassport: INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
    editDependentStatusData: INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
    ...INIT_EDIT_PASSPORTINFO_CONSENT,
  },
  nominationFlow: {
    nominationFlowRouteTo: {
      step: 0,
    },
    nominationFlowSelectedType: '',
    isNominationTypeValid: false,
    nominationViewModeData: {
      plusOne: {
        list: [],
        maxNumberOfPerson: 0,
        isEnabled: false,
      },
      travelNominees: {
        list: [],
        maxNumberOfPerson: 0,
        isEnabled: false,
      },
      childrenUnder24: {
        list: [],
        maxNumberOfPerson: 0,
      },
    },
    nominationSelectionData: {
      notNominated: [],
      maxNumberOfCompanion: 0,
      sortedDependents: [],
    },
    nominationSubRoutePage: NOMINATION_SUB_ROUTE_PAGE.nominatedAndToBeAddedDependentsInCart,
    isAgreeDisclaimer: false,
    isTriggerSaveNominationToast: false,
    oalEligibilityTickedDependents: [],
    isOALEligibilityEnableContinue: false, // enable "continue" button
    selectedOALEligibilityDependentId: '', // for OAL eligibility tick user
    cancelNominationFlowDialogTriggeringPoint: '',
    isTriggerChangeTypeDialog: false, // for nomination change type dialog
  },
  nominationTransformData: [],
  clonedNominationTempData: [],
  nominationChangeTypeData: [],
  clonedNominationChangeTypeData: [],
  isAllowNominate: false,
  companionDependentStatusFlow: {
    editDependentStatusData: INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
  },
  defaultDependentDetailsCardExpand: undefined,
});

const NominationAction = createContext<IActionType>({
  setCompanionRegistrationFlow: null,
  resetCompanionRegistrationFlowData: () => {},
  resetNominationFlowToViewModePageData: () => {},
  setNominationFlow: null,
  setNominationTransformData: null,
  setClonedNominationTempData: null,
  setNominationChangeTypeData: null,
  setClonedNominationChangeTypeData: null,
  setIsAllowNominate: null,
  setCompanionDependentStatusFlow: null,
  setDefaultDependentDetailsCardExpand: null,
});

const NominationContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const initCompanionRegistrationFlowData = {
    routeTo: {
      step: 0,
    },
    companionRegistrationData: null,
    isPersonalInputValid: false,
    isPassportInputValid: false,
    isAgreeDisclaimer: false,
    isOpenNominationPolicyConfirmButton: false,
    isOpenCancelRegistrationDialog: false,
    isSelectDependentRelationship: false,
    editPassport: INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
    editDependentStatusData: INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
    ...INIT_EDIT_PASSPORTINFO_CONSENT,
  };

  const [companionRegistrationFlow, setCompanionRegistrationFlow] = useState({
    ...initCompanionRegistrationFlowData,
  });
  const [companionDependentStatusFlow, setCompanionDependentStatusFlow] = useState({
    editDependentStatusData: INIT_EDIT_COMPANION_DEPENDENT_STATUS_CONTEXT,
  });

  const initNominationFlowData: INominationFlowStateType = {
    nominationFlowRouteTo: {
      step: NOMINATION_FLOW_PAGE_NO.nominationViewMode,
    },
    nominationFlowSelectedType: '',
    isNominationTypeValid: false,
    nominationViewModeData: {
      plusOne: {
        list: [],
        maxNumberOfPerson: 0,
        isEnabled: false,
      },
      travelNominees: {
        list: [],
        maxNumberOfPerson: 0,
        isEnabled: false,
      },
      childrenUnder24: {
        list: [],
        maxNumberOfPerson: 0,
      },
    },
    nominationSelectionData: { notNominated: [], maxNumberOfCompanion: 0, sortedDependents: [] },
    nominationSubRoutePage: NOMINATION_SUB_ROUTE_PAGE.nominatedAndToBeAddedDependentsInCart,
    isAgreeDisclaimer: false,
    isTriggerSaveNominationToast: false,
    oalEligibilityTickedDependents: [],
    isOALEligibilityEnableContinue: false,
    selectedOALEligibilityDependentId: '',
    cancelNominationFlowDialogTriggeringPoint: '',
    isTriggerChangeTypeDialog: false,
  };

  const [nominationFlow, setNominationFlow] = useState<INominationFlowStateType>({
    ...initNominationFlowData,
  });

  const [nominationTransformData, setNominationTransformData] = useState<INominationViewModeItem[]>([]);
  const [clonedNominationTempData, setClonedNominationTempData] = useState<INominationViewModeItem[]>([]);
  const [nominationChangeTypeData, setNominationChangeTypeData] = useState<INominationViewModeItem[]>([]);
  const [clonedNominationChangeTypeData, setClonedNominationChangeTypeData] = useState<INominationViewModeItem[]>([]);
  const [isAllowNominate, setIsAllowNominate] = useState<boolean>(false);
  const [defaultDependentDetailsCardExpand, setDefaultDependentDetailsCardExpand] = useState<number | undefined>(
    undefined,
  );

  const editTransformData = (list: INominationViewModeItem[]) => {
    const { nominationViewModeData, nominationSelectionData, nominationFlowSelectedType } = nominationFlow;
    const nominatedData = nominationViewModeData?.[nominationFlowSelectedType];

    // filer plusOne and travelNominees's companion data
    const plusOneDataIncludeTravelCompanion = filterCompanionData(
      nominationViewModeData?.[NOMINATION_TYPE.plusOne]?.list,
    );
    const travelNomineesDataIncludeTravelCompanion = filterCompanionData(
      nominationViewModeData?.[NOMINATION_TYPE.travelNominees]?.list,
    );

    // filter selected to change type dependents data
    const changeTypeDependentList = nominationChangeTypeData.filter((item) => item.isSelected);
    const toChangeTypeDependentsIncludesCompanion = filterCompanionData(changeTypeDependentList);

    const maxNumberOfPerson = nominatedData.maxNumberOfPerson;
    const { maxNumberOfCompanion } = nominationSelectionData;

    const [selectedNotNominatedList, notSelectedNotNominatedList] = _.partition(list, (item) => item.isSelected);
    const intersectionOfNotNominated = _.intersectionWith(
      notSelectedNotNominatedList,
      selectedNotNominatedList,
      (notSelected: INominationViewModeItem, selected: INominationViewModeItem) => {
        return (
          selected.dependentId === notSelected.pHubDependentId || selected.pHubDependentId === notSelected.dependentId
        );
      },
    );
    const selectedCompanionInNotNominatedList = filterCompanionData(selectedNotNominatedList);

    const hasPersonQuota = maxNumberOfPerson > selectedNotNominatedList.length + changeTypeDependentList.length;
    const hasCompanionQuota =
      maxNumberOfCompanion >
      selectedCompanionInNotNominatedList.length +
        toChangeTypeDependentsIncludesCompanion.length +
        (nominationFlowSelectedType === NOMINATION_TYPE.plusOne
          ? travelNomineesDataIncludeTravelCompanion.length
          : plusOneDataIncludeTravelCompanion.length);

    const { sortedDependents } = nominationFlow.nominationSelectionData || [];

    list.forEach((item) => {
      if (!item.isLocked) {
        // check step 1: check maxNumberOfPerson of nominationType
        if (hasPersonQuota) {
          if (item.beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion) {
            // check step 2: check maxNumberOfCompanion of notNominee
            if (hasCompanionQuota) {
              // dependent not grey out
              item.isGreyedOut = false;
            } else {
              if (!item.isSelected) {
                item.isGreyedOut = true;
              }
            }
          } else {
            // dependent not grey out
            item.isGreyedOut = false;
          }

          // A dependent the travel companion version cannot be nominated at the same time for the same year.
          if (
            intersectionOfNotNominated.some(
              (intersectionNominee) => intersectionNominee.dependentId === item.dependentId,
            )
          ) {
            item.isGreyedOut = true;
          }
        } else {
          // once not has quota "no select dependent" grey out
          if (!item.isSelected) item.isGreyedOut = true;
        }
      }
    });

    if (nominationChangeTypeData.length > 0) {
      const clonedNominationChangeTypeData = _.cloneDeep(nominationChangeTypeData);
      clonedNominationChangeTypeData.forEach((item: INominationViewModeItem) => {
        if (hasPersonQuota) {
          item.isGreyedOut = false;
        } else {
          // if no enough quota, also need to dim changeType list data
          if (!item.isSelected) {
            item.isGreyedOut = true;
          }
        }
      });

      setNominationChangeTypeData(clonedNominationChangeTypeData);
    }

    const [transformNominatedData, transformNotNominatedData] = _.partition(list, (item) => item.isLocked);

    setNominationTransformData([
      // sort by Backend
      ...transformNominatedData,
      // frontend sort by "sortedDependents(from backend)"
      ...sortByDependents(transformNotNominatedData, [], sortedDependents),
    ]);
  };

  const resetCompanionRegistrationFlowData = () => {
    setCompanionRegistrationFlow({
      ...initCompanionRegistrationFlowData,
    });
  };

  // reset the data to Nomination view mode page
  const resetNominationFlowToViewModePageData = () => {
    setNominationFlow(initNominationFlowData);
  };

  useEffect(() => {
    // initial transformData
    if (nominationFlow.nominationFlowSelectedType) {
      const selectedNominatedData = nominationFlow.nominationViewModeData?.[nominationFlow.nominationFlowSelectedType];
      const nominatedData = _.clone(selectedNominatedData?.list || []).map((item: INominationViewModeItem) => {
        item.isLocked = true;
        item.isSelected = true;
        return item;
      });

      const notNominatedData = _.clone(nominationFlow.nominationSelectionData?.notNominated || []).map(
        (item: INominationViewModeItem) => {
          item.isSelected = false;
          return item;
        },
      );

      editTransformData([...nominatedData, ...notNominatedData]);
    }
  }, [
    nominationFlow.nominationViewModeData,
    nominationFlow.nominationSelectionData,
    nominationFlow.nominationFlowSelectedType,
  ]);

  useEffect(() => {
    // handle step 2-2 sub route change
    if (nominationFlow.nominationFlowRouteTo.step === NOMINATION_FLOW_PAGE_NO.nominationCart) {
      // step 2-2 that means the step for selection of not nominated dependent,show tick icon
      if (nominationFlow.nominationSubRoutePage === NOMINATION_SUB_ROUTE_PAGE.notNominatedDependentsSelection) {
        const tempList = _.clone(nominationTransformData);
        tempList.forEach((item) => {
          item.isGreyedOut = false;
        });
        setNominationTransformData(tempList);

        // to select dependents section, show tick icon
        const tempChangeTypeData = _.cloneDeep(nominationChangeTypeData);
        tempChangeTypeData.forEach((item: INominationViewModeItem) => {
          item.isGreyedOut = false;
        });

        setNominationChangeTypeData(tempChangeTypeData);
      }
    }
  }, [nominationFlow.nominationSubRoutePage]);

  return (
    <NominationState.Provider
      value={{
        companionRegistrationFlow,
        nominationFlow,
        nominationTransformData,
        clonedNominationTempData,
        nominationChangeTypeData,
        clonedNominationChangeTypeData,
        isAllowNominate,
        companionDependentStatusFlow,
        defaultDependentDetailsCardExpand,
      }}
    >
      <NominationAction.Provider
        value={{
          setCompanionRegistrationFlow,
          resetCompanionRegistrationFlowData,
          resetNominationFlowToViewModePageData,
          setNominationFlow,
          setNominationTransformData: (list: INominationViewModeItem[]) => {
            editTransformData(list);
          },
          setClonedNominationTempData,
          setNominationChangeTypeData,
          setClonedNominationChangeTypeData,
          setIsAllowNominate,
          setCompanionDependentStatusFlow,
          setDefaultDependentDetailsCardExpand,
        }}
      >
        {children}
      </NominationAction.Provider>
    </NominationState.Provider>
  );
};

export { NominationContextProvider, NominationState, NominationAction };
