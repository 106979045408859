import React, { useEffect, useContext } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { USER_PROFILE_PATHS } from '../../../constants/paths';
import { BENEFICIARY_TYPE_CODE, INIT_EDIT_COMPANION_PASSPORT_CONTEXT } from '../../../constants/constants';
import { BackIcon, NominatedIcon, AlertIcon } from '../../../assets/images';

import en from '../../../translations/en';

import { IDependent } from '../../../interfaces';
import { isAssoSubsid, isRetireeSpecialProfile, isRetireeWidow } from '../../../helpers';

import { selectApp, setSnackBarData } from '../../../slice/appSlice';
import { selectAuth } from '../../../slice/authSlice';
import { getDependentThunk, selectUser, setIsLoadedDependent } from '../../../slice/userSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { NominationAction, NominationState } from '../../../context';

import {
  CompanionEditPassportContainer,
  DependentDetailsCard,
  ParagraphMasterContainer,
  CompanionDependentStatusContainer,
} from '../../../containers';
import { CancelDialog } from '../../../components';

const DependentDetailsContainer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isDesktop, isLoading } = useAppSelector(selectApp) || {};
  const { ern } = useAppSelector(selectAuth) || {};
  const { dependent, profile } = useAppSelector(selectUser) || [];

  const { companionRegistrationFlow, companionDependentStatusFlow, defaultDependentDetailsCardExpand } =
    useContext(NominationState) || {};
  const { setCompanionRegistrationFlow, setDefaultDependentDetailsCardExpand } = useContext(NominationAction) || {};

  const nomineesLbl = en.userProfile.nomination.nomineesDetail;
  const companionRegistrationLbl = en.userProfile.nomination.companionRegistration;
  const cancelEditCompanionPassportLbl = companionRegistrationLbl.common.editCompanionPassport;

  const {
    isEditMode,
    isOpenCancelEditCompanionPassportDialog,
    initFormData: selectedInitCompanionPassportData,
    beneficiaryTypeCode,
  } = companionRegistrationFlow?.editPassport || {};

  const { isInactivatedMode } = companionDependentStatusFlow?.editDependentStatusData || {};

  const refreshNomineeDetailHandler = async () => {
    await dispatch(setIsLoadedDependent(false));
    await dispatch(getDependentThunk({ userId: ern }));

    // display toast message
    dispatch(
      setSnackBarData({
        message:
          beneficiaryTypeCode === BENEFICIARY_TYPE_CODE.companion
            ? cancelEditCompanionPassportLbl.editSuccessToastMessage
            : en.userProfile.personal.passportInfo.editSuccessPassportInformationMessage,
        isShow: true,
        delay: 700,
        customStyles: {
          // TMP support desktop or mobile view.
          mb: isDesktop ? 9 : 10.5,
        },
      }),
    );

    setCompanionRegistrationFlow({
      ...companionRegistrationFlow,
      editPassport: {
        ...companionRegistrationFlow.editPassport,
        isTriggerEditCompanionPassportToast: false,
        beneficiaryTypeCode: '',
      },
    });
  };

  useEffect(() => {
    dispatch(setIsLoadedDependent(false));
    dispatch(getDependentThunk({ userId: ern }));
    return () => {};
  }, [dispatch]);

  // [ETP-4868] refresh nominee detail page after edit passport success
  useEffect(() => {
    if (companionRegistrationFlow.editPassport.isTriggerEditCompanionPassportToast) {
      refreshNomineeDetailHandler();
    }
  }, [companionRegistrationFlow]);

  const [nominatedList, otherDependentsList] = _.partition(dependent, (item: IDependent) =>
    item.nominationDetails.some((details) => details.isActive),
  );

  // [ETP-6129] Self-service update passport enhancement
  // Set whether the card needs to be expanded. It only needs to be expanded after the editing is completed.
  useEffect(() => {
    setDefaultDependentDetailsCardExpand(undefined);
  }, []);

  const RenderDependentView = ({
    list,
    name,
    isNominatedList,
  }: {
    list: IDependent[];
    name: string;
    isNominatedList: boolean;
  }) => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: isNominatedList ? 0 : 2,
          }}
        >
          {isNominatedList && <NominatedIcon width={20} height={20} fill={theme.color.secondary.dark_grey.option_3} />}
          <Typography
            variant="body_1_medium"
            sx={{
              color: theme.color.secondary.dark_grey.option_3,
              ml: 0.5,
            }}
          >
            {name}
          </Typography>
        </Box>

        {list?.map((item: IDependent, index: number) => {
          return (
            <DependentDetailsCard
              key={index}
              data={item}
              expandIndex={index}
              isDefaultExpand={index === defaultDependentDetailsCardExpand}
              isNominatedList={isNominatedList}
            />
          );
        })}
      </>
    );
  };

  return (
    <Box className="dependent_details_container" sx={{ ...(isDesktop && { width: '488px' }) }}>
      {isEditMode && (
        <Box className="edit_passport_container">
          {selectedInitCompanionPassportData && (
            <CompanionEditPassportContainer
              passportData={selectedInitCompanionPassportData}
              isEditMode={isEditMode}
              beneficiaryTypeCode={beneficiaryTypeCode}
            />
          )}

          <CancelDialog
            open={isOpenCancelEditCompanionPassportDialog}
            showCancelIcon={false}
            handleConfirmAction={() => {
              // [ETP-6129] Self-service update passport enhancement
              setDefaultDependentDetailsCardExpand(undefined);
              // confirm to reset and back to nominee detail page
              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                editPassport: INIT_EDIT_COMPANION_PASSPORT_CONTEXT,
              });
            }}
            handleCloseDialog={() => {
              setCompanionRegistrationFlow({
                ...companionRegistrationFlow,
                editPassport: {
                  ...companionRegistrationFlow.editPassport,
                  isOpenCancelEditCompanionPassportDialog: false,
                },
              });
            }}
            confirmToClose={false}
            IconComponent={<AlertIcon />}
            dialogContent={{
              title: cancelEditCompanionPassportLbl.cancelEditCompanionPassportDialog.title,
              message: cancelEditCompanionPassportLbl.cancelEditCompanionPassportDialog.description,
              yesTitle: en.common.yesConfirm,
              noTitle: en.common.back,
            }}
          />
        </Box>
      )}
      {!isEditMode && !isInactivatedMode && (
        <>
          {!isDesktop && (
            <IconButton
              onClick={() => {
                navigate(USER_PROFILE_PATHS.menu);
                dispatch(setIsLoadedDependent(false));
              }}
              sx={{ py: 1.625, pl: 0 }}
            >
              <BackIcon fill={theme.color.utility.link.option_3} />
            </IconButton>
          )}
          <Box>
            <Typography
              variant="headline_medium"
              sx={{
                color: theme.color.secondary.dark_grey.option_1,
                mb: 3,
                mt: 2,
              }}
            >
              {nomineesLbl.detailPageTitle}
            </Typography>
          </Box>

          {/* special retiree copywriting TBC */}
          {profile && (isAssoSubsid(profile) || isRetireeWidow(profile) || isRetireeSpecialProfile(profile)) && (
            <ParagraphMasterContainer
              content={nomineesLbl.descriptionContent}
              paragraphTypoSxProps={{
                color: theme.color.secondary.dark_grey.option_1,
              }}
              multiParagraphContainerSxProps={{ p: 0 }}
              paragraphSxProps={{
                mt: isDesktop ? 1.5 : 0,
              }}
              paragraphVariant="body_1_regular"
            />
          )}

          {!isLoading && dependent?.length === 0 && (
            <Typography
              variant="body_1_medium"
              sx={{
                color: theme.palette.gray.main,
                ml: 0.5,
                mt: 3,
                textAlign: 'center',
              }}
            >
              {nomineesLbl.empty}
            </Typography>
          )}

          {!isLoading && dependent?.length > 0 && (
            <>
              {nominatedList?.length > 0 && (
                <RenderDependentView list={nominatedList} name={nomineesLbl.nominatedSection} isNominatedList={true} />
              )}

              {otherDependentsList?.length > 0 && (
                <RenderDependentView
                  list={otherDependentsList}
                  name={nomineesLbl.notNominatedSection}
                  isNominatedList={false}
                />
              )}
            </>
          )}
        </>
      )}
      {isInactivatedMode && (
        <Box className="edit_dependent_status_container">
          {selectedInitCompanionPassportData && <CompanionDependentStatusContainer />}
        </Box>
      )}
    </Box>
  );
};

export default DependentDetailsContainer;
