// ==========================================================
// IMPORTANT: [ETP-6125] if need update the content in `src/constants/paths.ts`, please also update code in `server.js
// ==========================================================

const ROOT_PATHS = {
  landing: '/',
};

const LOGIN_PATHS = {
  assoSubsid: '/asso-subsid',
};

const USER_PROFILE_PATHS = {
  root: '/profile',
  menu: '/profile/menu',
  details: '/profile/details',
  basicInformation: '/profile/details/basicInformation',
  contactDetails: '/profile/details/contactDetails',
  editContactDetail: '/profile/details/editContactDetail',
  passport: '/profile/details/passport',
  editPassportDetail: '/profile/details/editPassportDetail',
  delegation: '/profile/details/delegation',
  addDelegatee: '/profile/details/delegation/addDelegatee',
  dependent: '/profile/details/dependent',
  paymentMethod: '/profile/details/paymentMethod',
  paymentHistory: '/profile/details/paymentHistory',
  dependentDetails: '/profile/details/dependentDetails',
  concessionDetails: '/profile/details/concessionDetails',
};

const NOMINATION_PATHS = {
  companionRegistration: '/profile/companionRegistration',
  nominationFlow: '/profile/nomination',
  nextYearNominationFlow: '/profile/nextYearNomination',
};

const BOOKING_PATHS = {
  booking: '/booking',
  locationSelection: '/booking/selection/locationSelection',
  dateSelection: '/booking/selection/dateSelection',
  travelTypeSelection: '/booking/selection/travelTypeSelection',
  seatClass: '/booking/selection/seatClass',
  travelDetails: '/booking/travelDetails',
  confirmation: '/booking/confirmation',
  myBookings: '/booking/myBookings',
};

const INFO_PATHS = {
  info: '/info',
};

const ADMIN_PATHS = {
  adminGuide: '/admin/guide',
  adminEmployee: '/admin/employee',
  adminAssoSubsidEmployee: '/admin/assoSubsidEmployee',
  adminTravelHistory: '/admin/travelHistory',
  adminAssoSubsidTravelHistory: '/admin/assoSubsidTravelHistory',
  adminUploadAssoSubsidEmployeeProfile: '/admin/uploadAssoSubsidEmployeeProfile',
};

const PAYPAL_PATHS = {
  subscription: '/paypal/subscription',
  subscriptionCancelled: '/paypal/subscriptionCancelled',
};

const PREPAID_PATHS = {
  bookTransit: '/prepaid/bookTransit',
  bookTransitBack: '/prepaid/bookTransitBack',
  authTransit: '/cpp/auth-transit',
  authFailureTransit: '/cpp/auth/failure-transit',
};

export {
  ROOT_PATHS,
  LOGIN_PATHS,
  USER_PROFILE_PATHS,
  NOMINATION_PATHS,
  BOOKING_PATHS,
  INFO_PATHS,
  ADMIN_PATHS,
  PAYPAL_PATHS,
  PREPAID_PATHS,
};
